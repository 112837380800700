import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"member-content",class:{ draggable: _vm.showDragIcon }},[(_vm.showDragIcon)?_c(VIcon,[_vm._v(" mdi-drag ")]):_vm._e(),_c('div',{class:_vm.showInvite ? 'item-avatar-invite' : 'item-avatar'},[(_vm.creative.profilePic)?_c('img',{class:_vm.showInvite ? 'item-avatar-invite' : 'item-avatar',attrs:{"src":_vm.creative.profilePic}}):_vm._e(),(!_vm.creative.profilePic)?_c('div',{staticClass:"name-initial d-flex align-center justify-center",staticStyle:{"width":"inherit","height":"inherit","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.memberInitials(_vm.name))+" ")]):_vm._e()]),_c(VListItemContent,{staticClass:"d-flex ml-1 justify-space-between"},[_c('div',{staticClass:"name notranslate"},[_vm._v(_vm._s(_vm.name))]),(_vm.showInvite && _vm.user.accepted)?_c('div',{staticStyle:{"color":"#09BA51"}},[_vm._v(" Invite Accepted ")]):_vm._e(),(_vm.showInvite && !_vm.user.accepted)?_c('div',{staticClass:"inviteStauts",staticStyle:{"color":"#CFA936"}},[_c('div',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'div',_vm.attrs,false),_vm.on),[_vm._v(" Invite sent "+_vm._s(_vm._f("getDate")(_vm.user.invitationSentAt))+" ")])]):_vm._e()]),(_vm.canAccess && _vm.showInvite)?_c(VListItemAction,[(_vm.showInvite)?_c('span',{staticClass:"warning--text font-weight-bold float-left"},[_c(VMenu,{attrs:{"offset-y":"","left":"","content-class":"admin-menu"},scopedSlots:_vm._u([(_vm.user.accepted)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'div',attrs,false),on),[_c('span',[_c('img',{staticClass:"event-icon",attrs:{"src":_vm.clockIconCheck,"width":"16"}})]),_c(VIcon,{staticStyle:{"padding-bottom":"10px","padding-left":"2px"},attrs:{"color":"#09BA51","size":"20"}},[_vm._v(" mdi-menu-down ")])],1)]}}:{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'div',attrs,false),on),[_c('span',[_c('img',{staticClass:"event-icon",attrs:{"src":_vm.clockIcon,"width":"16"}})]),_c(VIcon,{staticStyle:{"padding-bottom":"10px","padding-left":"2px"},attrs:{"color":"#8066FF","size":"20"}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c(VList,[(!_vm.user.accepted)?_c(VListItem,{key:'accept',on:{"click":function($event){return _vm.$emit('acceptItem', _vm.creative.id)}}},[_c(VListItemTitle,[_vm._v(" Accept ")])],1):_vm._e(),_c(VListItem,{key:'decline',on:{"click":function($event){return _vm.$emit('removeItem', _vm.creative.id)}}},[_c(VListItemTitle,[_vm._v("Decline")])],1)],1)],1)],1):_vm._e()]):_vm._e(),(_vm.declined)?_c(VListItemAction,[_c('span',{staticClass:"warning--text font-weight-bold float-left resendoption"},[_c(VMenu,{attrs:{"offset-y":"","left":"","content-class":"admin-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'div',attrs,false),on),[_c('span',[_c('img',{staticClass:"event-icon",attrs:{"src":_vm.clockIcon,"width":"16"}})]),_c(VIcon,{staticStyle:{"padding-bottom":"10px","padding-left":"2px"},attrs:{"color":"#8066FF","size":"20"}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,4217799133)},[_c(VList,[_c(VListItem,{key:'resend',on:{"click":function($event){return _vm.$emit('resendItem')}}},[_c(VListItemTitle,[_vm._v("Resend")])],1)],1)],1)],1)]):_vm._e(),(_vm.declined)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pt-1"},'div',attrs,false),on),[_c('Info')],1)]}}],null,false,3724461357)},[_c('span',[_vm._v(_vm._s(_vm.user.sweepReasons && _vm.user.sweepReasons.name))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }