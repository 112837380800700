<template>
    <div class="payment-container">
      <v-col class="plan">
                Order Payment
            </v-col>
            <h1 class="plan-heading">
                Payment
            </h1>
          <Loader v-if="loading"/>
          <span v-if="!loading">
          <div class="d-flex justify-center">
          <v-col class="card-form"
            :style="`border: ${!loading ? '1px solid #ccc' : 'none'}`"
          >
                <stripe-element-payment
                  ref="paymentRef"
                  :pk="pk"
                  :elements-options="elementsOptions"
                  :confirm-params="confirmParams"
                />
            </v-col>
            </div>
            <div class="d-flex justify-center">
            <div class="continue-btn text-center mt-5 pt-5">
                <v-btn
                  class="register-button btn-white mr-5"
                  type="submit"
                  @click="$emit('closePayment')">Back
                </v-btn>
                <v-btn
                  class="register-button btn-purple"
                  type="submit"
                  @click="submitForm">Pay
                </v-btn>
              </div>
            </div>
            </span>
    </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import Loader from '@/components/common/Loader';
import { mapActions } from 'vuex';

export default {
  components: {
    StripeElementPayment,
    Loader,
  },
  props: {
    paymentData: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      // card details pack
      pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      elementsOptions: {
        appearance: {
          rules: {
            '.Label': {
              fontSize: '16px',
              fontWeight: '500',
            },
            '.Error': {
              color: 'red',
              fontFamily: 'sans-serif',
              fontWeight: '400',
            },
          },
        }, // appearance options
      },
      confirmParams: {
        return_url: `${process.env.VUE_APP_MAIN_URL}/orders`, // success url
      },
    };
  },
  methods: {
    ...mapActions('user', ['paymentMethod']),
    async generatePaymentIntent() {
      const result = await this.paymentMethod(this.paymentData);
      this.elementsOptions.clientSecret = result.paymentIntent.client_secret;
      // console.log('aass', this.$refs.paymentRef);
    },
    submitForm() {
      this.$refs.paymentRef.submit();
    },
  },
  async mounted() {
    this.loading = true;
    await this.generatePaymentIntent();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.payment-container {
  ::v-deep #stripe-payment-element-errors {
    color: red !important;
  }
  ::v-deep .p-FieldLabel {
    font-size: 20px !important;
  }
  .plan {
    color: #a1a1a1;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .plan-heading {
    font-size: 44px;
    color: #333;
  }
  .card-form {
    max-width: 500px;
    border-radius: 5px;
  }
  .continue-btn {
    width: 500px;
    border-top: 1px solid #c7c7c7;
  }
}
</style>
