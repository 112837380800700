<!-- eslint-disable -->
<template>
  <v-container class="pt-6 timeline-container px-0">
  <Loader v-if="loading" />
  <div v-else>
<DeleteTimeLine ref="DeleteSweepBlockModel"/>
    <div class="pb-2 d-flex justify-space-between align-center timeline-title">
      <span class="font-h4 key-info-title">Timeline</span>
      <v-btn text class="text-purple font-body cursor-pointer pr-0 pl-0"
      @click="clickReOrder()"
      v-if="canAccess && sortedTimeLines.length > 1 && !clients"
      >Reorder</v-btn>
    </div>
    <div>
    </div>
     <div class="timeline py-3" v-if="sortedTimeLines.length<1">
         <v-row
          >
            <v-col class="d-flex pb-0 pl-0 contentSection">
            <span cols="4" lg="2" class="font-body startTime">
              {{ locationTime(0) }}
            </span>
              <div>
                <div class="circle">
                  <MapMarkerFilled />
                </div>
                <div style="height: calc(100% - 39px);" class="d-flex justify-center pt-2">
                  <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                </div>
              </div>
              <div class="content">
                <div class="d-flex justify-space-between">
                  <div class="font-h5 location-name">{{ this.sweep.sweepLocations[0].location }}</div>
                </div>
                <div class="d-flex align-center">
                  <span class="text-purple cursor-pointer d-flex align-center">
                          <a class="pr-1"
        :href="`https://www.google.com/maps/place/${this.sweep.sweepLocations[0].location}`"
        target='_blank'
      >
        Map
      </a> <span class="pt-1"
       v-clipboard:copy="`https://www.google.com/maps/place/${this.sweep.sweepLocations[0].location}`"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
      >
        <Copy/>
      </span>

                  </span>
                  <div class="mx-4" style="height: 12px; border-left: 1px solid #DEDEDE;" />
                  <span class="text-purple cursor-pointer d-flex align-center">
                          <a class="pr-1"
        :href="` https://www.google.com/maps/dir//${this.sweep.sweepLocations[0].location}`"
        target='_blank'
      >
        Directions
      </a>
                   <span class="pt-1"
       v-clipboard:copy="` https://www.google.com/maps/dir//${this.sweep.sweepLocations[0].location}`"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
      >
        <Copy/>
      </span>
                  </span>
                </div>
              </div>
              </v-col>
          </v-row>
     </div>
    <div class="timeline py-3" v-if="sortedTimeLines.length">
      <template v-for="(event, index) in sortedTimeLines">
        <template>
          <v-row
            :key="index"
          >
            <v-col class="d-flex pb-0 pl-0 contentSection" v-if="canShow(event,index)">
            <span class="font-body startTime">
              {{ locationTime(index) }}
            </span>
              <div>
                <div class="circle">
                  <MapMarkerFilled />
                </div>
                <div style="height: calc(100% - 39px);" class="d-flex justify-center pt-2">
                  <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                </div>
              </div>
              <div class="content">
                <div class="d-flex justify-space-between">
                  <div class="font-h5 location-name">{{ event.location.location }}</div>
                </div>
                <div class="d-flex align-center">
                  <span class="text-purple cursor-pointer d-flex align-center">
                          <a class="pr-1"
        :href="`https://www.google.com/maps/place/${event.location.location}`"
        target='_blank'
      >
        Map
      </a> <span class="pt-1"
       v-clipboard:copy="`https://www.google.com/maps/place/${event.location.location}`"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
      >
        <Copy/>
      </span>

                  </span>
                  <div class="mx-4" style="height: 12px; border: 1px solid #DEDEDE;" />
                  <span class="text-purple cursor-pointer d-flex align-center">
                          <a class="pr-1"
        :href="` https://www.google.com/maps/dir//${event.location.location}`"
        target='_blank'
      >
        Directions
      </a>
                   <span class="pt-1"
       v-clipboard:copy="` https://www.google.com/maps/dir//${event.location.location}`"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
      >
        <Copy/>
      </span>
                  </span>
                </div>
              </div>
              </v-col>
          </v-row>
          <v-row :key="index">
            <v-col class="d-flex pb-0 pl-0 contentSection">
            <div cols="4" lg="2" class="blockTime">
             <div  v-if="!canShow(event,index)" class="font-body">
               {{ locationTime(index) }}
             </div>
            </div>
              <div>
                <div class="circle">
                  <template v-if="eventImage(event.sweepBlock) !== 'Commute'">
                    <MegaPhone />
                  </template>
                  <template v-if="eventImage(event.sweepBlock) === 'Commute'">
                    <Video />
                  </template>
                </div>
                <div
                  v-if="index + 1 !== sortedTimeLines.length"
                  style="height: calc(100% - 39px);"
                  class="d-flex justify-center pt-2"
                >
                  <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                </div>
              </div>
              <div class="content content-event" :class="event.type === 'event' && 'content-event'">
                <div class="d-flex justify-space-between">
                  <div class="font-h5">
                    {{ `${title(event.sweepBlock)} (${event.durationInMinutes} mins)` }}
                  </div>
                  <div class="pl-3" v-if="canAccess && !clients">
                    <v-menu
                      bottom
                      offset-y
                      offset-x
                      content-class="timeLineActions"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn dark icon height="auto" width="20" v-on="on" class="menu-btn">
                          <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item class="cursor-pointer" @click="()=>blockSelected(event, true)">
                          <v-list-item-title> Edit </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="cursor-pointer" @click="()=>removeTimeLine(event)">
                          <v-list-item-title class="btn-red v-btn--text">
                            Remove
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <div class="font-body" style="white-space: pre-line;">
                  {{ event.description }}
                </div>
                <div
                  v-if="event.timelineEquipment.length || event.timelineShotSettings.length"
                  class="settings mt-4"
                >
                  <template v-if="event.timelineEquipment">
                    <div class="font-body-small">
                      <span class="settings-title">Equipment: </span>
                      {{ equipments(event.timelineEquipment) }}
                    </div>
                  </template>
                  <template v-if="event.timelineShotSettings">
                    <div class="font-body-small pt-1">
                      <span class="settings-title">Shot Settings: </span>
                      {{ shotSettings(event.timelineShotSettings) }}
                    </div>
                  </template>
                  <div></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </template>
    </div>
     <v-row class="sweep-add-row" v-if="canAccess">
               <v-col cols="4" lg="2"  class="font-body">
            </v-col>
            <v-col class="addSweepList" >

          <v-col
              class="addSweepBlock"
              v-if="!clients"
              @click="showList=true">
              + Add Sweep Block
          </v-col>
            <v-row class="listRow" v-if="showList">
          <v-col class="listCol">
          <optgroup class="optionCat" v-for="category in sortedSweepBlocks" :label="category[0].sweepBlockType.name">
              <option class="optionsList"  @click="()=>blockSelected(product)"  v-for="product in category" :value="product.id">{{ product.name }}</option>
            </optgroup>
          </v-col>
          </v-row>
            </v-col>

        </v-row>
    <v-row v-if="isMobile && !loadmore && timeLines.length > 3 ">
     <v-col>
     <v-btn class="loadmore" @click="loadmore=true">

      Load More
     </v-btn>
     </v-col>
    </v-row>
    <v-container
      fluid
      v-if="addSweepBlockModal"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="addSweepBlockModal"
        scrollable
      >
       <AddSweepTimeLine
        :sweepBlockData="sweepBlockData"
        :locations="locations"
        :sweep="sweep"
        @close-modal="closeTimeline" />
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="addReOrder"
    >
    <Modal
        persistent
        content-class="new-user-form"
        :modal="addReOrder"
        scrollable
        width="480px"
        height="396px"
      >
      <ReOrder
      @closemodal="reloadTimeline"
      :timeline="this.sortedTimeLines"
      :sweep="this.sweep"
      />
    </Modal>
    </v-container>
   </div>
  </v-container>

</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import MapMarkerFilled from '@/components/common/svg/MapMarkerFilled';
import MegaPhone from '@/components/common/svg/MegaphoneFilled';
import Video from '@/components/common/svg/VideoFilled';
import Copy from '@/components/common/svg/Copy';
import AddSweepTimeLine from '@/components/sweepBlocks/AddSweepTimeLine';
import DeleteTimeLine from '@/components/sweepBlocks/DeleteTimeLine';
import ReOrder from '@/components/sweeps/ReOrder';
import Modal from '@/components/common/Modal';
import Loader from '@/components/common/Loader';

export default {
  name: 'Timeline',
  components: {
    Loader,
    MapMarkerFilled,
    MegaPhone,
    Video,
    Copy,
    AddSweepTimeLine,
    DeleteTimeLine,
    ReOrder,
    Modal,
  },
  data() {
    return ({
      showList: false,
      addSweepBlockModal: false,
      addReOrder: false,
      sweepBlockData: {},
      loadmore: false,
      removeModal: false,
      timeLines: [],
      loading: false,
    });
  },
  props: {
    canAccess: {
      type: Boolean,
      default: false,
    },
    lastUpdated: {
      default: false,
    },
    sweep: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    this.loading = true;
    await this.getSweepBlocks();
    await this.getTimeLine();
    this.loading = false;
  },
  watch: {
    async lastUpdated() {
      await this.getTimeLine();
    },
  },
  methods: {
    ...mapActions('sweepBlocks', ['getSweepBlocks', 'getEquipmentsList', 'getShotSettings']),
    ...mapActions('sweep', ['getSweepTimeLine', 'removeSweepTimeLineBlock']),
    ...mapActions(['setNotification']),
    clickReOrder() {
      this.addReOrder = true;
    },
    async reloadTimeline() {
      this.addReOrder = false;
      const data = await this.getSweepTimeLine(this.$route.params.sweepId);
      if (data.success) {
        this.timeLines = data.sweep.timeline;
      }
      return this.timeLines;
    },
    onCopy() {
      this.setNotification({
        message: 'Link copied to clipboard.',
        type: 'success',
      }, { root: true });
    },
    async getTimeLine() {
      const data = await this.getSweepTimeLine(this.$route.params.sweepId);
      if (data.success) {
        this.timeLines = data.sweep.timeline;
      }
      return this.timeLines;
    },
    onError(e) {
      this.setNotification({
        message: e.message,
        type: 'error',
      }, { root: true });
    },
    eventImage(sweepBlock) {
      return sweepBlock && sweepBlock.sweepBlockType
        ? _.get(sweepBlock.sweepBlockType, 'name', '')
        : '';
    },
    async closeTimeline() {
      this.addSweepBlockModal = false;
      this.sweepBlockData = {};
      await this.getTimeLine();
    },
    async blockSelected(block, isEdit) {
      this.showList = false;
      if (isEdit) {
        const blockData = Object.assign({}, block);
        this.sweepBlockData = {
          ...blockData,
          name: blockData.sweepBlock.name,
          sweepBlockEquipment: blockData.timelineEquipment,
          sweepBlockShotSettings: blockData.timelineShotSettings,
          locationId: blockData.locationId,
          sweepBlockType: _.get(blockData, 'sweepBlock.sweepBlockType'),
          sweepBlockId: blockData.sweepBlock.id,
          type: _.get(blockData, 'sweepBlock.sweepBlockType.name'),
        };
      } else {
        const blockData = Object.assign({}, block);
        delete blockData.id;
        this.sweepBlockData = {
          locationId: this.locations[0] && this.locations[0].id,
          durationInMinutes: block.durationInMinutes,
          description: '',
          ...blockData,
          blockId: block.id,
        };
      }
      this.addSweepBlockModal = true;
    },
    async removeTimeLine(data) {
      this.removeModal = true;
      const confirmModal = this.$refs.DeleteSweepBlockModel;
      const { confirm } = await confirmModal.open();
      if (!confirm) {
        this.removeModal = false;
      } else {
        await this.removeSweepTimeLineBlock(data.id);
        await this.getTimeLine();
      }
      this.removeModal = false;
    },
    locationTime(index) {
      if (index === 0) {
        return moment(this.sweep.fromTime, ['HH:mm']).format('h:mma');
      }
      const timeLine = this.sortedTimeLines.slice(0, index);
      const mins = timeLine.reduce((a, b) => a + b.durationInMinutes, 0);
      return moment((this.sweep.fromTime), ['HH:mm']).add(mins, 'minutes').format('h:mma');
    },
    canShow(event, index) {
      return event.locationId !== _.get(this.sortedTimeLines[index - 1], 'locationId', false);
    },
    getLocation(location) {
      return location.location ? location.location.split(',')[0] : '';
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('sweepBlocks', ['sweepBlocks']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    },
    products() {
      return ([
        {
          id: 1,
          name: 'category A',
          products: [
            { id: 1, name: 'product A1' },
            { id: 2, name: 'product A2' },
          ],
        },
        {
          id: 2,
          name: 'category B',
          products: [
            { id: 3, name: 'product B1' },
            { id: 4, name: 'product B2' },
          ],
        },
      ]);
    },
    locations() {
      return _.map(this.sweep.sweepLocations, (location, i) => ({
        id: location.id,
        name: this.sweep.sweepLocations.length > 1 ? `(${String.fromCharCode(97 + i)}) ${this.getLocation(location)}` : this.getLocation(location),
      }));
    },
    sortedSweepBlocks() {
      return _.groupBy(this.sweepBlocks, 'sweepBlockTypeId');
    },
    sortedTimeLines() {
      const data = _.sortBy(this.timeLines, 'priority');
      if (!this.loadmore && this.isMobile) {
        return data.slice(0, 3);
      }
      return data;
    },
    title() {
      return (sweepBlock) => _.get(sweepBlock, 'name', '');
    },
    equipments() {
      return (timelineEquipment) => _.compact(
        _.map(timelineEquipment, (tag) => (tag.timelineEquipmentItem ? tag.timelineEquipmentItem.name.trim() : '')),
      ).join(', ');
    },
    shotSettings() {
      return (timelineShotSettings) => _.compact(
        _.map(timelineShotSettings, (tag) => (tag.timelineShotSettingItem ? tag.timelineShotSettingItem.name.trim() : '')),
      ).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-container {
  .location-name {
    word-break: break-word;
  }
  .timeline-title {
    border-bottom: 1px solid $grey3;
    .key-info-title {
      color: $card-title;
    }
  }
  .timeline {
    .font-h5,
    .font-body {
      color: $charcoalBlack;
    }
    .circle {
      display: flex;
      height: fit-content;
      padding: 8px;
      border-radius: 50%;
      background: #ffffff;
      border: 2px solid #8066ff;
      box-shadow: 0px 0px 0px 8px #ffffff;
    }
    .contentSection {
      padding-top: 0px;
      padding-bottom: 12px !important;
      .content {
        margin-left: 16px;
        padding: 0 0 21px 0;
        width: 100%;
        .settings {
          padding: 10px 12px;
          background: #e5e6f5;
          .settings-title {
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #575757;
          }
        }
        .text-purple {
          text-decoration: underline;
        }
      }
      .content-event {
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 10px;
        padding: 14px 20px;
        margin-top: 0px;
      }
    }
  }
}
.timeLineActions {
  .v-list-item {
    border-bottom: 1px solid $border2;
    padding-left: 20px;
    padding-right: 20px;
    .v-list-item__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $charcoalBlack;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.sweep-add-row {
padding-right: 15px;
padding-top: 5px;
padding-left: 1%;
.addSweepBlock {
    border-top: 1px solid #DEDEDE;
    padding-top: 10px;
    padding-right: 10px;
    color: #8066ff;
    cursor: pointer;
    padding-left: 0;
  }
}
.optionsList {
  cursor: pointer;
  padding-left: 10px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-transform: capitalize;
color: #262626;
}
.optionCat {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 20px;
/* identical to box height */

letter-spacing: 0.5px;
text-transform: capitalize;

color: #B5B5B5;
}
.listRow {
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
border-radius: 4px;
margin: 1px;
width: auto;
min-width: 50%;
max-height: 300px;
overflow: hidden;
}
.listCol {
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
border-radius: 4px;
margin: 1px;
width: fit-content;
min-width: 50%;
max-height: 300px;
overflow: scroll;
overflow-x: auto;
}
.addSweepList {
  padding: 0px;
  padding-left: 15px;
}
.loadmore {
  background: #FFFFFF;
border: 2px solid #41E0BA;
border-radius: 30px;
width: 100%;
box-shadow: none;
}
.startTime {
  width: 110px;
  padding-left: 10px;
  padding-top: 7px;
}
.blockTime {
  width: 110px;
  padding-left: 10px;
  padding-top: 7px;
}
@media (max-width: 660px) {
    .blockTime {
      width: 150px;
      padding-left: 20px;
      padding-top: 7px;
    }
    .startTime {
  width: 140px;
  padding-left: 14px;
  padding-top: 7px;
}
.sweep-add-row {
  padding-right: 15px;
padding-top: 5px;
padding-left: 8%;
.addSweepBlock {
    border-top: 1px solid #DEDEDE;
    padding-top: 10px;
    padding-right: 10px;
    color: #8066ff;
    cursor: pointer;
    padding-left: 0;
  }
}
  }
</style>
