<!-- eslint-disable max-len -->
<template>
    <v-container fluid style="height: 100%"
      :style="`background-color: ${!openPay ? '#eee9e9' : 'white'}`">
    <Loader v-if="loader"/>
    <div v-if="!openPay && !loader" class="orderAssets-container mt-5 pl-1">
        <div>
          <img src="@/assets/svg/logo-colored.svg" width="250" class="logo mb-5" />
          <div class="d-flex justify-center">
            <img src="@/assets/brandings/favicon.svg" width="50" class="nav-bs-logo mb-10" />
          </div>
        </div>
            <v-col class="plan">
                PRODUCTION CREDITS
              </v-col>
            <h1 class="plan-heading">
                PICK YOUR BUNDLE
            </h1>
        <v-row class="d-flex justify-center pt-10 businessGroup">
            <v-radio-group row hide-details :value="options">
            <v-card class="plans ma-3"
            v-for="(bundle, index) in bundles"
            :key="index"
            :style="{ 'width' : bundle.code !== null ? '320px' : '300px',
             'height' : bundle.code !== null && bundle.extraCredits === null ? '350px' : 'auto', 'padding-top' : '0px' }"
            :class="{selected: index + 1 === options}"
            @click="selectPlan(index + 1, bundle)"
            >
                <!-- <div class="d-flex text-center" v-if="bundle.code === null">
                <v-col class="pl-0 mt-1 plan-name">
                <span style="color: white; border-bottom: 1px solid white;">
                    {{ bundle.name }}</span><br/>
                    <span style="color: #7cd9bb;">
                    <div class="mt-5"
                      v-if="bundle.credits !== 0">
                      {{ bundle.credits }} {{ bundle.credits > 1 ? 'Credits' : 'Credit' }}
                    </div>
                    <div v-if="bundle.price !== 0">
                      <span>${{ bundlePrice(bundle.price) }}</span>
                    </div>
                    <div style="color: rgb(203, 221, 100)" v-if="bundle.code === 'exp'">
                      <span v-if="bundle.extraCredits !== null">
                        {{ expCredits(bundle) }}
                      </span>
                    <v-col class="mt-2" v-else></v-col>
                    </div>
                    <div class="mt-5">${{ bundle.perCredit }} per credit</div></span>
                </v-col>
              </div> -->
              <v-col
                class="text-center pa-0">
                <v-col style="font-weight: 800;">
                  <v-col class="pl-0 pr-0" style="border-bottom: 2px solid #dedede;">
                    {{ bundle.name }} BUNDLE
                  </v-col>
                </v-col>
                <div class="pa-10 pt-0 pb-0">
                <v-col v-if="bundle.code !== null"
                  class="exp-bundle cross-bundle">
                  <span style="font-size: 25px;">${{ bundlePrice(bundle.oldPrice) }}</span>
                  <div>(${{ bundle.oldPricePerCredit }} per credit)</div>
                </v-col>
                <v-col
                  :class="{'pt-0': bundle.code === null}"
                  v-if="bundle.credits !== 0" class="exp-bundle">
                  <span style="font-size: 20px;">{{ bundle.credits }}</span>
                  <div
                    style="font-size: 12px;color: #8066ff;">PRODUCTION CREDITS</div>
                </v-col>
                <v-col v-else>
                  <div style="font-size: 30px;">${{ bundle.perCredit }}</div>
                  <div style="font-size: 12px;color: #8066ff;">PER PRODUCTION CREDIT</div>
                </v-col>
                <v-col style="font-size: 18px;font-weight: 600;" class="pt-3" v-if="bundle.credits === 0">Pay as you go</v-col>
                <v-col>
                  <div v-if="bundle.code !== null">eXp Price</div>
                  <div v-if="bundle.credits !== 0" style="font-size: 25px;font-weight: 800;">${{ bundlePrice(bundle.price) }}</div>
                  <div
                    v-if="bundle.code !== null || (bundle.name !== 'BLOCKBUSTER' && bundle.name !== 'FEATURE' && bundle.credits !== 0)"
                    style="color:orange">
                    (${{ bundle.perCredit }} per credit)
                  </div>
                </v-col>
                </div>
              <div
              v-if="bundle.code === null">
                <div v-if="bundle.name === 'BLOCKBUSTER'" class="extra-off"
                  style="color: #8066ff;background-color: #cdc4f8">
                  <div>(${{ bundle.perCredit }} per credit)</div>
                  <div>Best Value</div>
                </div>
                <div v-if="bundle.name === 'FEATURE'" class="extra-off"
                  style="color: #008158;background-color: #86EFCE;">
                  <div>(${{ bundle.perCredit }} per credit)</div>
                  <div>Most Popular</div>
                </div>
              </div>
              <div class="exp-extra" v-if="bundle.extraCredits !== null">
              <div style="color: #8066ff;font-weight: 600;">+{{ bundle.extraCredits }} bonus credits</div>
              <div style="color: #8066ff;font-weight: 500;">({{ bundle.credits + bundle.extraCredits }} Total)</div>
            </div>
          </v-col>
            </v-card>
            </v-radio-group>
        </v-row>
        <!-- <div class="justify-center pa-5 pb-0 mt-8">
        <v-btn class="btn-purple" href="https://www.blendedsense.com/asset-menu">
          View Production Menu
        </v-btn>
        </div> -->
        <v-row class="d-flex justify-center mt-5 production-menu">
          <v-col
              xs="12"
              sm="12"
              md="12"
              lg="5" xl="4" xxl="4" class="ma-5 text-left">
            <div class="main-heading pb-3">Book a Sweep</div>
            <v-col class="pa-5 production-menus" style="background-color: white;">
              <v-row style="border-bottom: 3px solid #dedede">
                <v-col>ITEM</v-col>
                <v-col class="text-right">CREDITS</v-col>
              </v-row>
              <v-row>
                <v-col class="main-heading pb-1"><i>Photographer</i></v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">2 hours</v-col>
                <v-col cols="2" class="production-items text-right">20</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Additional hour(s)</v-col>
                <v-col cols="2" class="production-items text-right">10</v-col>
              </v-row>
              <v-row>
                <v-col class="main-heading pb-1"><i>Videographer</i></v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">2 hours</v-col>
                <v-col cols="2" class="production-items text-right">26</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Additional hour(s)</v-col>
                <v-col cols="2" class="production-items text-right">13</v-col>
              </v-row>
              <v-row>
                <v-col class="main-heading pb-1"><i>Field Producer</i></v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">2 hours</v-col>
                <v-col cols="2" class="production-items text-right">10</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Additional hour(s)</v-col>
                <v-col cols="2" class="production-items text-right">5</v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col
              xs="12"
              sm="12"
              md="12"
              lg="5" xl="4" xxl="4" class="ma-5 text-left">
            <div class="main-heading pb-3">Order Assets on Demand</div>
            <v-col class="pa-5 production-menus" style="background-color: white;">
              <v-row style="border-bottom: 3px solid #dedede">
                <v-col>ITEM</v-col>
                <v-col class="text-right">CREDITS</v-col>
              </v-row>
              <v-row>
                <v-col class="main-heading pb-1"><i>Photo*</i></v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">25 Edited Photos</v-col>
                <v-col cols="2" class="production-items text-right">5</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">100 Edited Photos</v-col>
                <v-col cols="2" class="production-items text-right">15</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items sub-text"><i>*Must be images capture on a sweep</i></v-col>
              </v-row>
              <v-row>
                <v-col class="main-heading pb-1"><i>Video Content**</i></v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Micro (up to 30 secs)</v-col>
                <v-col cols="2" class="production-items text-right">3</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Short (up to 90 secs)</v-col>
                <v-col cols="2" class="production-items text-right">6</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items">Long (up to 5 minutes)</v-col>
                <v-col cols="2" class="production-items text-right">12</v-col>
              </v-row>
              <v-row>
                <v-col class="production-items sub-text"><i>**Can be footage from a sweep or footage sent in by you.</i></v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
        <div class="d-flex justify-center description">
          <!-- <Whitelogo height="160" style="width: 20px;"/> -->
        <img src="@/assets/svg/logo-short.svg" class="bottom-logo" />
          <div class="text-center text-uppercase bottom-text">
            <div class="text-left">Credits Can Be Gifted<br/>
            Credits Roll Over 12 Months<br/>
            Credits Are Non Refundable</div>
          </div>
            <!-- <v-col class="content text-center">
            <h3 class="content-title">What could you get with your production credits?</h3><br/>
            <p class="content-data">Checkout our production credits menu by clicking on the link below. You'll allocate your credits towards services on your call with your dedicated content producer.</p>
            <a class="menu-button" href="https://www.blendedsense.com/asset-menu">View Menu</a>
            </v-col> -->
        </div>
        <div class="d-flex justify-center">
            <v-col class="continue-btn text-center mt-5">
                <v-btn
                        class="btn-purple"
                        type="submit"
                        :disabled="credits === '' || credits.credits === 0"
                        @click="submitForm"
                        >Continue</v-btn
                      >
            </v-col>
        </div>
        <v-container
          fluid
          v-if="openCredits"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="openCredits"
            width="480px"
            height="899px"
          >
          <CreditsModel
            :credits="credits"
            @closemodal="openCredits = false"
          />
          </Modal>
        </v-container>
    </div>
    <v-container v-if="openPay">
        <OrderPay
          :payment="credits"
          @close="closePay"
        />
    </v-container>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import OrderPay from '@/components/dashboard/screens/client/Orderpay';
import CreditsModel from '@/components/dashboard/screens/client/CreditsModel';
import Loader from '@/components/common/Loader';

export default {
  components: {
    Modal,
    CreditsModel,
    OrderPay,
    Loader,
  },
  data() {
    return {
      bundles: '',
      openPay: false,
      openCredits: false,
      credits: '',
      loader: false,
      options: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  methods: {
    ...mapActions('user', ['setBundles']),
    // expCredits(val) {
    //   if (val.code === 'exp') {
    //     return `+${val.extraCredits} Credits`;
    //   }
    //   return '';
    // },
    // changeOption(val) {
    //   console.log('afdsa', val);
    // },
    async closePay() {
      this.loader = true;
      const bundle = await this.setBundles(this.userDetails.currentBusinessId);
      this.bundles = bundle.bundles;
      const { id } = this.credits;
      this.credits = this.bundles.find((x) => x.id === id);
      this.loader = false;
      this.openPay = false;
    },
    bundlePrice(val) {
      // eslint-disable-next-line
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    selectPlan(index, bundle) {
      this.options = index;
      if (bundle.id === 1) {
        this.openCredits = true;
      }
      if (bundle.id !== 1 && bundle.code === null) {
        this.credits = this.bundles.find((x) => x.id === 1);
        this.credits.credits = 0;
        this.credits.price = 0;
      }
      this.credits = bundle;
    },
    submitForm() {
      if (this.credits !== '' && this.credits.credits !== 0) {
        this.openPay = true;
      }
    },
  },
  async mounted() {
    this.loader = true;
    const bundle = await this.setBundles(this.userDetails.currentBusinessId);
    this.bundles = bundle.bundles;
    this.loader = false;
  },
};
</script>

<style lang="scss" scoped>
.orderAssets-container {
    text-align: center;
    font-family: sans-serif;
    .nav-bs-logo {
        display: none;
    }
    .heading-box {
      border: 5px solid black;
      border-left: none;
      border-right: none;
      width: 500px;
    }
    .selected {
      outline: 6px solid $primary1;
      border-radius: 4px;
    }
.exp-bundle {
  border-bottom: 1px solid #dedede;
}
.cross-bundle {
    color: rgba(rgb(56, 55, 55), 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(rgb(56, 55, 55), 10);
      height: 12px;
      position: absolute;
      bottom: 30px;
      left: 0;
      transform: rotate(15deg);
    }
  }
.exp-extra {
  background-color: #cdc4f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.extra-off {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.plan {
    color: $primary1;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
}
.plan-heading {
    font-size: 44px;
    letter-spacing: -1px;
    color: #333;
}
.plans {
    background-color: white;
    padding: 0px !important;
    cursor: pointer;
    // min-width: 250px;
    border-radius: 0.5rem;
    margin: 5px;
    border-radius: 10px;
}
.plan-name {
    font-size: 20px;
    font-weight: 600;
}
.plan-details {
    min-width: 250px;
}
.tick-mark {
    height: 20px;
    width:25px;
}
.content {
    background-color: #ecf6f8;
    max-width: 900px;
    border: 1px solid #c7c7c7;
    .content-title {
        font-size: 26px;
        font-weight: 900;
    }
    .content-data {
        font-size: 18px;
        line-height: 26px;
    }
    .menu-button {
        width: 50%;
        font-weight: 600;
        color: black;
        font-size: 17px;
        border-bottom: 2px solid #85e1ff;
    }
}
.continue-btn {
    .button {
      padding: 20px !important;
  background: #fff !important;
  border-radius: 30px;
  font-size: 14px !important;
  font-weight: 600 !important;
    }
}
  .description {
    font-size: 16px;
    font-weight: 600;
  }
  .bottom-text {
      margin-top: 40px;
    }
  .production-menu {
    .sub-text {
      color: #d8d8d8;
      font-weight: 700;
    }
    .main-heading {
      font-size: 21px;
      line-height: 22px;
      font-weight: 700;
    }
    .production-menus {
      box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.8);
      height: 350px;
    }
    .production-items {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
.bottom-logo {
    filter: invert(52%) sepia(61%) saturate(4899%)
     hue-rotate(227deg) brightness(98%) contrast(107%);
  }
@media (max-width: 600px) {
  .orderAssets-container {
    max-width: 350px;
    padding: 20px;
    margin-left: 15px;
    .plan-heading {
      font-size: 30px;
    }
    .heading-box {
      padding: 20px;
    }
    .nav-bs-logo {
        display: block;
    }
    .logo {
        display: none;
    }
    .bottom-text {
      font-size: 15px;
      margin-top: 20px;
    }
    .businessGroup {
      padding-left: 20px !important;
      padding-right: 20px;
    }
    .bottom-logo {
      height: 100px;
    }
  }
}
</style>
