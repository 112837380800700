<template>
    <div class="member-list px-4 h-100" ref="team-list">
      <v-row class="h-100 ma-0">
        <Loader v-if="fetching" />
        <ag-grid-vue
          v-else
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :rowData="sortedItems"
          rowSelection="multiple"
          :getRowNodeId="getRowNodeId"
          @grid-ready="onGridReady"
          @cell-clicked="onCellClicked"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :modules="modules"
          @selection-changed="onSelectionChanged"
          :suppressRowClickSelection=true
          :tooltipShowDelay="1"
          @model-updated="onGridUpdated"
          :pagination="true"
          :paginationPageSize="20"
        >
        </ag-grid-vue>
      </v-row>
    <div
      :ref="'details-popup'"
      v-show="showDetails"
      class="details-block"
    >
      <user-details-card
        :user="selectedMember"
        @closePopup="showDetails = false"
        :showTags="showTags(selectedMember)"
      >
      </user-details-card>
    </div>
    <view-profile-modal
    :profilePic="profileAvatar(selectedMember.smallImage || selectedMember.profilePic)"
    :imageBackground="checkProfile(selectedMember.profilePic)"
    :name="`${selectedMember.name || ''}`"
    :skillset="selectedMember.skillset"
    :viewProfileModal="viewProfileModal"
    :selectedMember="selectedMember"
    @setViewProfileModal="viewProfileModal=false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { AgGridVue } from 'ag-grid-vue';
import { generateUrl } from '@/helpers';
import EventBus from '@/helpers/event-bus';
import ViewProfileModal from '@/components/common/ViewProfileModal';
import Loader from '@/components/common/Loader';
import UserDetailsCard from '../ProjectTeam/UserDetailsCard';
import BtnCellRenderer from '../ProjectTeam/BtnCellRenderer';

export default {
  components: {
    AgGridVue,
    UserDetailsCard,
    ViewProfileModal,
    Loader,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    skillsetId: {
      type: Number,
      default: null,
    },
    sweepTeamIds: {
      type: Array,
      default: () => [],
    },
    sweepId: {
      type: [String, Number],
      required: true,
    },
    availability: {
      type: Boolean,
      default: true,
    },
    distance: {
      type: Boolean,
      default: true,
    },
    skillsetName: {
      type: String,
    },
    skillsets: {
      default: [],
    },
  },
  computed: {
    ...mapGetters('project', ['creativeTeam']),
    ...mapGetters('user', ['creativeList']),
    memberInitials() {
      return (memberName) => {
        let name = memberName;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    items() {
      let { users } = this;
      if (this.availability) {
        users = _.filter(users, ['dayAvailability', true]);
      }
      if (this.distance) {
        users = _.filter(users, ['distanceAvailability', true]);
      }
      if (this.skillsetId) {
        users = _.filter(users, (user) => _.includes(_.map(_.get(user.creativeProfile, 'skillsets', []), 'name'), this.skillsetName));
      }
      return _.map(users, (user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        newTag: user.newTag,
        status: user.status,
        availability: user.availability,
        number: _.get(user, 'number'),
        bio: _.get(user, 'bio'),
        userPronouns: _.get(user, 'userPronouns', []),
        portfolio: this.portfolioUrl(_.get(user.creativeProfile, 'portfolio', '')),
        skillset: this.skillsetNames(_.get(user.creativeProfile, 'skillsets', [])),
        interests: _.get(user.creativeProfile, 'interests', []).map((x) => x.name).join(', '),
        camera: _.get(user.creativeProfile, 'cameraEquipments', []).map((x) => x.name).join(', '),
        lens: _.get(user.creativeProfile, 'lensEquipments', []).map((x) => x.name).join(', '),
        accessories: _.get(user.creativeProfile, 'accessories', []).map((x) => x.name).join(', '),
        address: user.address && `${user.address.city}, ${user.address.state}`,
        copyInterests: _.get(user.creativeProfile, 'writerInterests', []).map((x) => x.name).join(', '),
        styles: _.get(user.creativeProfile, 'styles', []).map((x) => x.name).join(', '),
        subjects: _.get(user.creativeProfile, 'subjects', []).map((x) => x.name).join(', '),
        // eslint-disable-next-line global-require
        profilePic: user.profilePic,
        smallImage: user.smallImage,
        thumbnail: user.thumbnail,
        // eslint-disable-next-line global-require
        portfolioIcon: require('@/assets/svg/theme/open-in-new.svg'),
        projectsCount: user.activeProjectsCount,
        tier: _.sortBy(_.map(_.filter(user.payTiers, ['skillSetId', this.skillsetId]), 'payTier')),
        defaultTier: _.get(user.payTiers, '[0].payTier', ''),
        distance: user.distance,
      }));
    },
    sortedItems() {
      return _.sortBy(this.items, [(item) => item.name.toLowerCase()], ['asc']);
    },
    profilePic() {
      return (data) => {
        let pic = data.thumbnail || data.smallImage || data.profilePic;
        pic = (pic === 'null' ? null : pic);
        return pic;
      };
    },
  },
  methods: {
    ...mapActions('user', ['getCreativesList']),
    profileAvatar(avatar) {
      // eslint-disable-next-line global-require
      return avatar || require('@/assets/svg/users.svg');
    },
    checkProfile(pic) {
      if (_.includes(pic, '.svg')) {
        return true;
      }
      return false;
    },
    onCellClicked(params) {
      if (params.colDef.field === 'name') {
        this.viewProfileModal = true;
        this.selectedMember = params.data;
      }
    },
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      const updateData = (data) => {
        this.users = data;
      };
      this.getUsers()
        .then((users) => {
          updateData(users);
        });
      this.gridApi.sizeColumnsToFit();
      this.resetColumnVisibility();
    },
    onGridUpdated(params) {
      this.gridApi = params.api;
      if (this.fetching) {
        this.gridApi.showLoadingOverlay();
      }
      if (this.gridApi.getDisplayedRowCount()) {
        this.gridApi.hideOverlay();
      } else if (!this.fetching) {
        this.gridApi.showNoRowsOverlay();
      }
      this.gridApi.sizeColumnsToFit();
    },
    onSelectionChanged() {
      this.selected = this.gridApi.getSelectedRows();
    },
    showPopup(e, item) {
      this.showDetails = true;
      this.selectedMember = item;
    },
    resolveUsers(creativesList) {
      const creativesNotInProject = _.filter(creativesList,
        (creative) => !_.includes(this.sweepTeamIds, creative.id));
      this.creativesNotInProject = creativesNotInProject;
      return creativesNotInProject;
    },
    async getUsers() {
      const creativesNotInProject = this.resolveUsers(this.creativeList);
      return creativesNotInProject;
    },
    showTags(user) {
      if (user.skillset) {
        const skills = user.skillset.split(',');
        if (skills.length > 1) {
          return true;
        }
        return skills[0] !== 'Graphic Designer';
      }
      return false;
    },
    portfolioUrl(link) {
      return generateUrl(link);
    },
    hideAllPreferences() {
      const columns = ['interests', 'lens', 'camera', 'accessories', 'styles', 'subjects', 'copyInterests', 'address'];
      _.each(columns, (column) => {
        this.columnApi.setColumnVisible(column, false);
      });
    },
    async resetColumnVisibility() {
      this.hideAllPreferences();
      // eslint-disable-next-line
      if (this.skillsetId != 4) {
        const selectedSkill = _.get(_.find(this.skillsets, ['id', this.skillsetId]), 'text');
        switch (selectedSkill.toLowerCase()) {
          case 'copywriter':
            _.each(['styles', 'subjects', 'copyInterests', 'address'], (column) => {
              this.columnApi.setColumnVisible(column, true);
            });
            break;
          case 'photographer':
          case 'videographer':
            _.each(['interests', 'camera', 'lens', 'accessories', 'address'], (column) => {
              this.columnApi.setColumnVisible(column, true);
            });
            break;
          case 'talent':
            _.each(['tier', 'address'], (column) => {
              this.columnApi.setColumnVisible(column, true);
            });
            break;
          default:
            break;
        }
      }
      this.gridApi.sizeColumnsToFit();
    },
    columnDefaultDefs() {
      const nameBlock = (data) => '<div class="name-cell" style="cursor:pointer;">'
      + `<div class="item-avatar" style="background-color: #C0C0C0; width: 33px; height: 32px; border-radius: 50%;"> ${this.profilePic(data) ? `<img width=32 height=32 src=${this.profilePic(data)} />` : `<div class="name-initial d-flex align-center justify-center" style="width: inherit; height: inherit; font-size: 16px;"> ${this.memberInitials(data.name)} </div>`} </div> `
      + '<div class="item-content">'
      + `<div class="name">${data.name}`
      + '</div>'
      + `<div class="skillset">${data.skillset}</div>`
      + '</div></div>';

      const newTagBlock = (data) => '<div class="name-cell">'
      + `${data.newTag === true && data.status === '1' ? '<div class="v-icon mdi mdi-new-box new-tag"></div>' : '<div class="pl-8"></div>'}`
      + '</div>';

      return [
        {
          headerName: '',
          field: 'id',
          checkboxSelection: true,
          cellRenderer(params) {
            return newTagBlock(params.data);
          },
          width: 80,
          resizable: false,
          suppressSizeToFit: true,
          cellClass: 'checkBoxColumn',
          autoHeight: true,
          suppressMovable: true,
          lockPosition: true,
        },
        {
          headerName: 'Creative',
          field: 'name',
          filter: true,
          cellRenderer(params) {
            return nameBlock(params.data);
          },
          width: 210,
          resizable: false,
          suppressSizeToFit: true,
          cellClass: 'name notranslate',
          autoHeight: true,
          suppressMovable: true,
          lockPosition: true,
        },
        {
          headerName: '',
          field: 'portfolio',
          cellClass: 'action pt-1 pl-1 d-flex align-start portfolio-icon',
          cellRendererFramework: BtnCellRenderer,
          cellRendererParams: {
            icon: 'mdi-open-in-new',
            color: 'primary',
            clicked(params) {
              window.open(params.value);
              return false;
            },
          },
          width: 40,
          lockPosition: true,
          suppressSizeToFit: true,
          resizable: false,
          tooltipField: 'portfolio',
        },
        {
          headerName: 'Distance',
          field: 'distance',
          headerClass: 'left-align',
          lockPosition: true,
          resizable: true,
          sortable: true,
          width: 80,
        },
        {
          headerName: '#Projects',
          field: 'projectsCount',
          headerClass: 'left-align',
          lockPosition: true,
          resizable: true,
          width: 120,
        },
        {
          headerName: 'Type',
          field: 'tier',
          headerClass: 'left-align',
          cellClass: 'text-capitalize',
          lockPosition: true,
          resizable: true,
          hide: true,
          width: 120,
        },
        {
          headerName: 'Interest',
          headerClass: 'left-align',
          field: 'interests',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'interests',
        },
        {
          headerName: 'Camera List',
          headerClass: 'left-align',
          field: 'camera',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'camera',
        },
        {
          headerName: 'Lens List',
          headerClass: 'left-align',
          field: 'lens',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'lens',
        },
        {
          headerName: 'Accessories',
          headerClass: 'left-align',
          field: 'accessories',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'accessories',
        },
        {
          headerName: 'Location',
          headerClass: 'left-align',
          field: 'address',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'address',
        },
        {
          headerName: 'Writer Interest',
          headerClass: 'left-align',
          field: 'copyInterests',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'copyInterests',
        },
        {
          headerName: 'Style',
          headerClass: 'left-align',
          field: 'styles',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'styles',
        },
        {
          headerName: 'Subjects',
          headerClass: 'left-align',
          field: 'subjects',
          filter: true,
          hide: true,
          resizable: true,
          tooltipField: 'subjects',
        },
      ];
    },
  },
  data() {
    return ({
      fetching: false,
      creativesNotInProject: [],
      users: [],
      selected: [],
      selectedMember: {},
      showDetails: false,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      overlayLoadingTemplate: null,
      modules: [],
      rowData: null,
      viewProfileModal: false,
    });
  },
  async mounted() {
    this.fetching = true;
    await this.getCreativesList(this.sweepId);
    this.users = this.resolveUsers(this.creativeList);
    this.fetching = false;
    if (this.users.length === 0) {
      this.gridOptions.api.showNoRowsOverlay();
    }
    EventBus.$on('deselect-member', (nodeId) => {
      const rowNode = this.gridApi.getRowNode(nodeId);
      rowNode.setSelected(false);
    });
  },
  async beforeMount() {
    this.gridOptions = {
      components: {
        BtnCellRenderer,
      },
    };
    this.columnDefs = this.columnDefaultDefs();
    this.getRowNodeId = (data) => data.id;
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No users to show</span>';
    this.getRowHeight = (params) => (
      params.api.getSizesForCurrentTheme().rowHeight
        * Math.floor(params.data.name.length / 50)
    );
  },
  watch: {
    search(newVal) {
      this.gridOptions.api.setQuickFilter(newVal);
    },
    selected(newValue) {
      // if (oldValue.length !== newValue.length) {
      this.$emit('selected-members', newValue);
      // }
    },
    skillsetId() {
      this.resetColumnVisibility();
    },
    sweepTeamIds(newValue, oldValue) {
      if (oldValue.length !== newValue.length) {
        this.selected = [];
        this.users = this.resolveUsers(this.creativeList);
      }
    },
    creativeList(newVal, oldVal) {
      if (oldVal.length !== newVal.length) {
        this.users = this.resolveUsers(newVal);
      }
    },
  },
  beforeDestroy() {
    EventBus.$on('deselect-member');
  },
};
</script>

<style lang="scss">
.checkBoxColumn {
  .ag-selection-checkbox .ag-checkbox .ag-checkbox-input-wrapper input{
    cursor: pointer;
  }
}
  .new-tag {
    color: #f29f33;
    padding-right: 10px;
  }
  .ag-theme-alpine {
    .ag-row-odd  {
      background: none;
    }
    .ag-row-hover  {
      background-color: $neutral9;
    }
    .ag-row-selected {
      background: $neutral9;
    }
  }
  .ag-cell {
    &.name {
      justify-content: left;
      white-space: normal !important
    }
    &.portfolio {
      display: flex;
      justify-content: flex-end;
      padding-right: 35px;
    }
    &.portfolio-icon {
      .v-icon {
          margin-left: 10px;
          color: $primary1;
          font-size: 16px;
      }
        }
    .name-cell {
      text-align: left;
      display: flex;
      align-items: center;
      .item-avatar {
        img {
          border-radius: 50%;
        }
      }
      .item-content{
        margin-left: 10px;
        .name {
          margin-bottom: 5px;
        }
        .skillset {
          line-height: 1.2;
          font-size: 12px;
        }
      }
    }
  }
  .member-list {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 4px !important;
  }
  // .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after
  //  {
  //   border-radius: 3px;
  //   color: white;
  //   background: $lightGreen;
  //   border: 1px solid $lightGreen;
  // }
</style>
