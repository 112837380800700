<template >
  <div class="details-container">
    <v-btn
      icon
      width="30"
      height="30"
      color="primaryGray1"
      class="close-icon"
      @click="$emit('closePopup')"
    >
      <v-icon size="30">mdi-close</v-icon>
    </v-btn>
    <v-card
      class="detail-card"
      flat
    >
          <v-img
        class="white--text align-end profile-pic"
        height="250px"
        :src="getOptimizedS3ImageURL(user.profilePic,
          { width: 250, height: 250 } )"
      />
      <v-card-text class="text--primary pa-3 pb-2">
        <div class="name text-capitalize">
          {{ user.name }}
        </div>
        <div class="text mt-1">{{user.skillset}}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="py-1 px-3" v-if="showTags">
        <template v-if="user.interests && user.interests.length">
          <div class="text"> Interest: </div>
          <v-chip color="#e5e7fa" label v-for="(name, i) in user.interests" :key="`interest-${i}`">
            {{ name }}
          </v-chip>
        </template>
        <template v-if="user.camera && user.camera.length">
          <div class="text"> Camera: </div>
          <v-chip color="#e5e7fa" label v-for="(name, i) in user.camera" :key="`camera-${i}`">
            {{ name }}
          </v-chip>
        </template>
        <template v-if="user.lens && user.lens.length">
          <div class="text"> Lens: </div>
          <v-chip color="#e5e7fa" label v-for="(name, i) in user.lens" :key="`lens-${i}`">
            {{ name }}
          </v-chip>
        </template>
        <template v-if="user.accessories && user.accessories.length">
          <div class="text"> Accessories: </div>
          <v-chip v-for="(name, i) in user.accessories"
            color="#e5e7fa" label :key="`accessories-${i}`"
          >
            {{ name }}
          </v-chip>
        </template>
        <template v-if="user.copyInterests && user.copyInterests.length">
          <div class="text"> Copy Interest: </div>
          <v-chip v-for="(name, i) in user.copyInterests"
            color="#e5e7fa" label :key="`copyInterests-${i}`"
          >
            {{ name }}
          </v-chip>
        </template>
        <template v-if="user.styles && user.styles.length">
          <div class="text"> Style: </div>
          <v-chip color="#e5e7fa" label v-for="(name, i) in user.styles" :key="`style-${i}`"
          >
            {{ name }}
          </v-chip>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
};
</script>

<style scoped lang="scss">
  .details-container {
      position: relative;
      .close-icon {
        position: absolute;
        right: 15px;
        top: 8px;
        z-index: 10;
      }
    }
    .v-card {
      border-radius: 3px !important;
    }
    .detail-card {
      background-color: $neutral6;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-top: -20px;
      max-height: 400px;
      overflow:auto;
      .text {
        color: $neutral1 !important;
        font-size: 14px;
        letter-spacing: -0.11px;
        margin-bottom: 6px;
        margin-top: 8px;
      }
      .v-list-item {
        min-height: 0;
      }
      .v-image {
        border-radius: 0;
        object-fit: contain;
      }
      .profile-pic {
        border-radius: 3px;
      }
      .name {
        letter-spacing: -0.12px;
        line-height: normal;
      }
      ::v-deep .v-card__subtitle {
        font-size: 14px;
        letter-spacing: -0.11px;
        line-height: normal;
      }
      .text.message-text {
        color: $secondary1 !important;
      }
      .v-chip {
        font-family: $fontFamily1;
        margin-right: 10px;
        margin-bottom: 5px;
    }
    ::v-deep .v-chip__content {
      color: $primary2;
    }
  }

</style>
