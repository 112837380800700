<!-- eslint-disable max-len -->
<template>
<svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6450_62916)">
<path d="M0.75 4.5H17.25C17.4489 4.5 17.6397 4.42098 17.7803 4.28033C17.921 4.13968 18 3.94891 18 3.75C18 3.55109 17.921 3.36032 17.7803 3.21967C17.6397 3.07902 17.4489 3 17.25 3H0.75C0.551088 3 0.360322 3.07902 0.21967 3.21967C0.0790176 3.36032 0 3.55109 0 3.75C0 3.94891 0.0790176 4.13968 0.21967 4.28033C0.360322 4.42098 0.551088 4.5 0.75 4.5Z" :fill="color"/>
<path d="M0.75 8.25H11.25C11.4489 8.25 11.6397 8.17098 11.7803 8.03033C11.921 7.88968 12 7.69891 12 7.5C12 7.30109 11.921 7.11032 11.7803 6.96967C11.6397 6.82902 11.4489 6.75 11.25 6.75H0.75C0.551088 6.75 0.360322 6.82902 0.21967 6.96967C0.0790176 7.11032 0 7.30109 0 7.5C0 7.69891 0.0790176 7.88968 0.21967 8.03033C0.360322 8.17098 0.551088 8.25 0.75 8.25Z" :fill="color"/>
<path d="M11.25 14.25H0.75C0.551088 14.25 0.360322 14.329 0.21967 14.4697C0.0790176 14.6103 0 14.8011 0 15C0 15.1989 0.0790176 15.3897 0.21967 15.5303C0.360322 15.671 0.551088 15.75 0.75 15.75H11.25C11.4489 15.75 11.6397 15.671 11.7803 15.5303C11.921 15.3897 12 15.1989 12 15C12 14.8011 11.921 14.6103 11.7803 14.4697C11.6397 14.329 11.4489 14.25 11.25 14.25Z" :fill="color"/>
<path d="M17.25 10.5H0.75C0.551088 10.5 0.360322 10.579 0.21967 10.7197C0.0790176 10.8603 0 11.0511 0 11.25C0 11.4489 0.0790176 11.6397 0.21967 11.7803C0.360322 11.921 0.551088 12 0.75 12H17.25C17.4489 12 17.6397 11.921 17.7803 11.7803C17.921 11.6397 18 11.4489 18 11.25C18 11.0511 17.921 10.8603 17.7803 10.7197C17.6397 10.579 17.4489 10.5 17.25 10.5Z" :fill="color"/>
</g>
<defs>
<clipPath id="clip0_6450_62916">
<rect :width="width" :height="height" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'AlignLeft',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
  },
};
</script>
