<template>
  <div>
  <Loader v-if="loading" />
  <v-form class="add-sweepBlock-form" @submit="submitForm" v-else>
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title"> {{sweepBlockData.name}} </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="scrollable-div">
      <v-row cols="12" >
      <v-col cols="7" lg="8">
      <label class="font-label">Location</label>
            <v-select
              solo
              flat
              class="type-selection mb-n3 notranslate"
              placeholder="Select..."
              :items="locations"
              item-text="name"
              item-value="id"
              v-model="sweepBlock.locationId"
              :menu-props="{ 'content-class': 'notranslate' }"
              append-icon="mdi-chevron-down"
              :disabled="locations.length<2"
            ></v-select>
      </v-col>
      <v-col cols="5" lg="4">
          <label class="font-label">Duration</label>
          <div class="d-flex">
            <v-col cols="4" lg="4" class="pa-0 duration">
              <BaseInput
                name="durationInMinutes"
                solo
                dense
                flat
                v-model.trim="sweepBlock.durationInMinutes"
                :status="errors('durationInMinutes').length ? 'error' : 'normal'"
                :error-messages="errors('durationInMinutes')"
                @input="$v.sweepBlock.durationInMinutes.$touch()"
                @blur="$v.sweepBlock.durationInMinutes.$touch()"
              />
            </v-col>
            <span class="font-body-small pl-2">minutes</span>
          </div>
          </v-col>
      </v-row>
        <div>
          <label class="font-label">Description</label>
          <base-text-area-field
            name="name"
            solo
            dense
            flat
            v-model.trim="sweepBlock.description"
            :rows="3"
            :status="errors('name').length ? 'error' : 'normal'"
            :error-messages="errors('name')"
            @input="$v.sweepBlock.name.$touch()"
            @blur="$v.sweepBlock.name.$touch()"
          />
        </div>
        <div class="capture-settings" v-if="sweepBlock.type === 'Content Capture'">
          <div class="font-h4">Capture Settings</div>
          <v-row
            class="flex-column ma-0 mb-5"
            :class="{
              'errorState v-input--has-state error--text': shotErr,
            }"
          >
            <label class="font-label">Shot Settings</label>
            <v-select
              :class="{
                'errorState v-input--has-state error--text': shotErr,
              }"
              solo
              flat
              class="type-selection mb-n3 notranslate"
              placeholder="Select..."
              :items="shotSettingsData"
              item-text="name"
              item-value="id"
              v-model="sweepBlock.sweepBlockShotSettings"
              :menu-props="{ 'content-class': 'notranslate' }"
              append-icon="mdi-chevron-down"
              hide-details
              @input="checkForShot"
              @blur="checkForShot"
              multiple
              chips
              deletable-chips
            ></v-select>
            <span
              :class="{
                'errorState v-input--has-state error--text': shotErr,
              }"
              v-if="shotErr"
              >Please provide shot settings</span
            >
          </v-row>
          <v-row class="ma-0" v-for="(equip, i) in sweepBlock.sweepBlockEquipment" :key="equip">
            <v-col xs="8" sm="8" md="8" lg="8" xl="8" xxl="8" class="py-0 pl-0">
              <v-row
                class="flex-column ma-0"
                :class="{
                  'errorState v-input--has-state error--text': equipmentErr && !equip.id,
                }"
              >
                <label class="font-label" v-if="i === 0">Equipment</label>
                <v-select
                  :class="{
                    'errorState v-input--has-state error--text': equipmentErr && !equip.id,
                  }"
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="selectedEquipments(equip.id)"
                  item-text="name"
                  item-value="id"
                  v-model="equip.id"
                  :menu-props="{ 'content-class': 'notranslate' }"
                  append-icon="mdi-chevron-down"
                  hide-details
                  @input="checkForEquip"
                  @blur="checkForEquip"
                ></v-select>
              </v-row>
            </v-col>
            <v-col cols="4" class="py-0 pr-0">
              <v-row class="flex-column ma-0">
                <label class="font-label" v-if="i === 0">Quantity</label>
                <v-row class="ma-0 flex-row">
                  <v-col
                    :cols="sweepBlock.sweepBlockEquipment.length > 1 ? 10 : 12"
                    class="py-0 pl-0"
                  >
                    <v-select
                      solo
                      flat
                      class="type-selection mb-n3 notranslate"
                      placeholder="Select..."
                      :items="quantities"
                      v-model="equip.quantity"
                      :menu-props="{ 'content-class': 'notranslate' }"
                      append-icon="mdi-chevron-down"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-end pr-0"
                    v-if="sweepBlock.sweepBlockEquipment.length > 1"
                  >
                    <v-icon class="cursor-pointer" color="darken-1" @click="removeEquipment(i)"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <span
              :class="{
                'errorState v-input--has-state error--text': equipmentErr,
              }"
              v-if="equipmentErr && !equip.id"
              >Please provide equipment</span
            >
          </v-row>
          <v-btn text color="info" class="pl-0 mt-2" @click="addEquipment()" v-if="canAddEquipment"
            >+ Add Equipment
          </v-btn>
        </div>
      </div>
      <v-card-actions>
        <v-btn text class="py-0" color="primaryGray1" @click="$emit('close-modal')">
          Cancel
        </v-btn>
        <v-btn
        class="py-0 submit btn-purple"
        :loading="saving"
        @click="submitForm">
          Save
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  minValue,
  maxLength,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import Loader from '@/components/common/Loader';

export default {
  name: 'AddSweepTimeLine',
  components: {
    BaseInput,
    BaseTextAreaField,
    Loader,
  },
  props: {
    sweepBlockData: {
      type: Object,
      default: () => {},
    },
    sweep: {
      type: Object,
      default: () => {},
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      location: ['Content Capture', 'Meeting', 'Commute'],
      quantities: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      sweepBlock: {
        type: 'Content Capture',
        name: '',
        durationInMinutes: '',
        sweepBlockShotSettings: '',
        sweepBlockEquipment: [{ quantity: 1 }],
      },
      shotSettingsData: [],
      equipmentsData: [],
      shotErr: false,
      equipmentErr: false,
      loading: false,
      saving: false,
    };
  },
  methods: {
    ...mapActions('sweep', ['addSweepTimeline', 'updateSweepTimeline']),
    ...mapActions('sweepBlocks', ['getSweepBlocks', 'getEquipmentsList', 'getShotSettings']),
    async getType(val) {
      if (val === 'Content Capture' && this.sweepBlock.sweepBlockEquipment.length === 0) {
        this.sweepBlock.sweepBlockEquipment.push({ quantity: 1 });
      }
    },
    async checkForShot() {
      const { sweepBlockShotSettings, type } = this.sweepBlock;
      let err = false;
      if (type === 'Content Capture') {
        if (!sweepBlockShotSettings) {
          err = true;
        } else {
          err = false;
        }
      }
      this.shotErr = err;
    },
    async checkForEquip() {
      const { sweepBlockEquipment, type } = this.sweepBlock;
      let err = false;
      if (type === 'Content Capture') {
        if (sweepBlockEquipment.length === 0) {
          err = true;
        } else {
          err = false;
          _.map(sweepBlockEquipment, (item) => {
            if (!item.id) {
              err = true;
            }
          });
        }
      }
      this.equipmentErr = err;
    },
    async addEquipment() {
      this.sweepBlock.sweepBlockEquipment.push({ quantity: 1 });
    },
    async removeEquipment(index) {
      this.sweepBlock.sweepBlockEquipment.splice(index, 1);
    },
    async submitForm(e) {
      e.preventDefault();
      await this.checkForShot();
      await this.checkForEquip();
      await this.saveSweepBlock();
    },
    async saveSweepBlock() {
      const { shotErr, equipmentErr } = this;
      this.$v.$touch();
      if (!this.$v.$invalid && !shotErr && !equipmentErr) {
        const api = this.sweepBlock.id ? 'updateSweepTimeline' : 'addSweepTimeline';
        const equipments = this.sweepBlock.sweepBlockEquipment.filter((item) => item.id);
        const data = {
          id: this.sweepBlock.id,
          type: this.sweepBlock.type,
          title: this.sweepBlock.name,
          duration: this.sweepBlock.durationInMinutes,
          description: this.sweepBlock.description,
          equipments: [],
          settings: [],
          locationId: this.sweepBlock.locationId,
          sweepBlockId: this.sweepBlock.blockId,
          sweepId: this.sweep.id,
        };
        this.saving = true;
        if (this.sweepBlock.type === 'Content Capture') {
          data.equipments = equipments;
          data.settings = this.sweepBlock.sweepBlockShotSettings;
        }
        const result = await this[api](data);
        if (result.success) {
          this.$emit('close-modal');
        }
        this.saving = false;
      }
    },
  },
  computed: {
    ...mapGetters('sweepBlocks', ['equipmentsList', 'shotSettingsList']),
    canAddEquipment() {
      const { sweepBlock, equipmentsData } = this;
      return sweepBlock.sweepBlockEquipment.length < equipmentsData.length;
    },
    selectedEquipments() {
      return (val) => {
        const { sweepBlockEquipment } = this.sweepBlock;
        const selectedIds = _.map(sweepBlockEquipment, 'id');
        const arr = [];
        _.forEach(this.equipmentsData, (item) => {
          if (!selectedIds.includes(item.id)) {
            arr.push(item);
          }
          if (val === item.id) {
            arr.push(item);
          }
        });
        return arr;
      };
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.sweepBlock[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.sweepBlock.name.required) {
              errors.push('Please provide title');
            }
            if (!this.$v.sweepBlock.name.maxLength) {
              errors.push('Title must be less than 160 characters length');
            }
            break;
          case 'durationInMinutes':
            if (!this.$v.sweepBlock.durationInMinutes.required) {
              errors.push('Please select duration');
            }
            if (!this.$v.sweepBlock.durationInMinutes.numeric) {
              errors.push('Duration must be numeric');
            }
            if (!this.$v.sweepBlock.durationInMinutes.minValue) {
              errors.push('Duration must be greater than 0');
            }
            if (!this.$v.sweepBlock.durationInMinutes.maxLength) {
              errors.push('Duration length should be max 3 digits');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  validations: {
    sweepBlock: {
      name: {
        required,
        maxLength: maxLength(160),
      },
      durationInMinutes: {
        required,
        numeric,
        minValue: minValue(1),
        maxLength: maxLength(3),
      },
    },
  },
  async mounted() {
    this.loading = true;
    await this.getEquipmentsList();
    await this.getShotSettings();
    const { shotSettingsList, equipmentsList, sweepBlockData } = this;
    this.shotSettingsData = [...shotSettingsList];
    this.equipmentsData = [...equipmentsList];
    const equipments = [];
    const shotSettings = [];
    await sweepBlockData.sweepBlockEquipment.forEach((data) => {
      let index = '';
      equipmentsList.forEach((data1, i) => {
        if (data1.id === _.get(data.sweepBlockEquipmentItem, 'id')) {
          index = i;
        }
      });
      equipmentsList.splice(index, 1);
      const obj = {
        ...data.sweepBlockEquipmentItem,
        ...data.timelineEquipmentItem,
        quantity: data.quantity,
      };
      equipments.push(obj);
      equipmentsList.push(obj);
    });
    await sweepBlockData.sweepBlockShotSettings.forEach((data) => {
      shotSettings.push(data.sweepBlockShotSettingItemId);
      if (data.sweepBlockShotSettingItem) {
        shotSettingsList.push(data.sweepBlockShotSettingItem);
      }
      if (data.timelineShotSettingItem) {
        shotSettingsList.push(data.timelineShotSettingItem);
      }
    });
    const dataObj = {
      ...sweepBlockData,
      sweepBlockEquipment: equipments.length ? equipments : [{ quantity: 1 }],
      sweepBlockShotSettings: shotSettings,
      type: sweepBlockData.sweepBlockType.name,
    };
    this.shotSettingsData = [...shotSettingsList];
    this.equipmentsData = [...equipmentsList];
    this.sweepBlock = dataObj;
    this.loading = false;
  },
};
</script>

<style lang="scss">
.v-list-item__content {
  max-width: 500px;
}
</style>
<style lang="scss" scoped>

.add-sweepBlock-form {
  ::v-deep .v-messages__message {
    line-height: 14px !important;
  }
  .error--text {
    font-size: 12px;
    margin-top: 7px;
  }
  ::v-deep .errorState .v-input .v-input__control .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  .scrollable-div {
    padding: 20px 20px 0px 20px;
    overflow: auto;
    overflow-y: hidden;
    .base-input {
      margin-top: 6px;
    }
    .capture-settings {
      .font-h4 {
        color: #929292 !important;
        line-height: 26px !important;
        padding-bottom: 8px;
        border-bottom: 1px solid #dedede;
        margin-bottom: 15px;
        margin-top: 14px;
      }
    }
  }
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 21px 13px 21px;
  }
  ::v-deep .v-input__slot {
    min-height: 42px;
  }
  ::v-deep .type-selection {
    margin-top: 6px;
    .v-input__slot {
      border: solid 1px #e6e8ed;
      .v-select__selection {
        line-height: 21px;
      }
    }
  }
}
.v-form {
  .v-card__actions {
    padding: 0;
    justify-content: flex-end;
    padding: 14px 27px 18px;
    .v-btn {
      border-radius: 6px;
      font-weight: 400;
      color: $secondary3;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      line-height: 24px;
      ::v-deep .v-btn__content {
        font-weight: 500;
        line-height: 19px;
        font-size: 16px;
      }
    }
    .submit {
      width: 112px;
      margin-left: 12px !important;
    }
  }
  .duration {
      padding-left: 10px;
  }
  .font-body-small {
      padding-top: 17px;
  }
}
</style>
