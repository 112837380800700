<!-- eslint-disable -->
<template>
   <v-container class="pt-6 timeline-container px-0">
      <Loader v-if="loading"/>
      <div v-else>
      <div class="pb-2 d-flex justify-space-between align-center timeline-title">
         <span class="font-h4 key-info-title">Conversation</span>
         <v-menu offset-y left content-class="admin-menu">
            <template v-slot:activator="{ on, attrs }">
              <div
                color="info"
                dark
                v-bind="attrs"
                v-on="on"
                style="color:#8066FF"
              >
                <span>
                  {{ activityFilter }}
                </span>
                <v-icon color="#8066FF" style="padding-left: 2px;" size="20">
                  mdi-menu-down
                </v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                @click="activityFilter='All Activity'"
                :key="'All Activity'"
              >
                <v-list-item-title>
                  All Activity
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="activityFilter='Notes & Comments'"
                :key="'Notes & Comments'"
              >
                <v-list-item-title>
                  Notes & Comments
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="'System Events'"
                @click="activityFilter='System Events'"
              >
                <v-list-item-title>System Events</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </div>
      <div class="d-flex align-center timeline-title" v-if="canAccess">
         <div class="mb-4 mt-4 mr-2">
          <AvatarRound
            textStyle="font-size: 18px;"
            :size="60"
            :avatar="getMemberProfile(userDetails)"
            :name="memberInitials(userDetails)"
          />
         </div>
         <div class="text-area">
            <v-text-field
               :placeholder="seenPlacehold(1)"
               outlined
               rows="1"
               type="text"
               @input="checkForTitle"
               :readonly="items.length ? true : false || audiomessage"
               v-model.trim="message"
               >
               <template
                  v-slot:prepend-inner
                  style="border: 1px solid red;position: relative;"
                  v-if="device && audiomessage">
                <div v-if="recordOption" class="mt-5" style="color: rgb(255 1 0 / 65%);">Recording... {{ count }}
                </div>
                <div v-if="!recordOption" class="mt-1">
                  <audio v-if="!hiddenAudio" controlsList="nodownload" :src="mainaudio.src" type="audio/mp3" id="myaudio" controls></audio>
                  <v-btn
                        icon
                        x-small
                        color="black"
                        class="remove-color-icon"
                        v-if="!hiddenAudio"
                        @click="removeAudio(1)"
                        >
                        <v-icon size="10">mdi-close</v-icon>
                  </v-btn>
                  <span v-if="hiddenAudio"></span>
                </div>
                <audio id="audio" class="audio" ref="audio" hidden controls>
                </audio>
               </template>
               <template v-slot:append>
                <button
                  class="mt-4 mr-2"
                  v-if="!recordOption"
                  @click="recordAudio(1)" type="button" id="button_record"><img src="@/assets/audiomic.png"/>
                </button>
                <button
                  class="mt-1"
                  style="border-radius: 50px;background-color: rgba(255, 0, 0, 0.208);width: 50px;height: 50px;"
                  v-if="recordOption"
                  @click="recordAudiostop(1)" type="button" id="button_record">
                  <img src="@/assets/Rectangle179.png" height="15"/>
                </button>
              </template>
              </v-text-field>
         </div>
         <span class="ml-1 mt-4" v-if="getReplyAccess">
            <v-icon
               size="40"
               class="sendMessage"
               v-if="!sent_loading"
               @click="sendMessage">
               send
            </v-icon>
        </span>
         <v-progress-circular
               indeterminate
               color="primary"
               v-if="sent_loading"
               :value="20"></v-progress-circular>
      </div>
      <div>
      </div>
      <div class="timeline">
      <template v-for="(event, index) in moreConversation">
         <template>
            <v-row
               :key="index"
               >
               <v-col class="d-flex pb-0 pl-0 contentSection">
                  <div>
                     <div v-if="event.user !== null">
                      <AvatarRound
                        textStyle="font-size: 10px;"
                        :size="30"
                        :avatar="getMemberProfile(event.user)"
                        :name="memberInitials(event.user)"
                      />
                     </div>
                      <div
                      class="mr-3"
                      v-if="event.activity === '2'"
                      style="border: 6px solid #c4c4c4;border-radius: 100px;background-color: #c4c4c4;margin-bottom: 2px;margin-left: 9px;margin-top: 4px;">
                      </div>
                      <div v-if="index !== moreConversation.length-1 && event.activity === '2'" style="height: 100%; padding-bottom: 4px;margin-right: 2px;" class="d-flex justify-center">
                        <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                      </div>
                      <div v-if="index !== moreConversation.length-1 && event.activity === '1'" style="height: 100%; padding-bottom: 18px" class="d-flex justify-center">
                        <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                      </div>
                     </div>
                     <div v-if="!editDiv && !replyDiv" class="content mt-0">
                      <div v-if="event.audioFile" class="ml-3" style="width: 60%;">
                        <AudioConversation
                          @play="id => activeMusicId=id"
                          :activeMusicId="activeMusicId"
                          :messageid="event.id"
                          :key="event.audioFile"
                          :reply="event.audioFile"
                        />
                      </div>
                        <div class="d-flex justify-space-between">
                          <div
                          class="font-h5 location-name">
                          {{event.message}}<span class="date-time" v-if="event.edited"> (edited)</span>
                        </div>
                        </div>
                        <div class="d-flex pt-2">
                        <div class="date-time">
                        {{moment(event.createdAt).format('MM/DD/YYYY - hh:mma')}}
                        </div>
                        <div
                        v-if="canAccess && event.user !== null"
                        @click="reply(event.id, event.user.id)" class="reply">
                        <Redo/> Reply
                        </div>
                        <span v-if="event.user !== null && canAccess">
                        <div
                        v-if="currectUserId === event.user.id && !event.audioFile"
                        @click="edit(event.id, event.user.id)" class="reply">
                        Edit
                        </div>
                        </span>
                        </div>
                        <div v-for="(reply, index2) in event.Replies">
                          <div class="mb-2">
                            <v-row
                              class="font-h5 location-name ml-1 mt-1">
                          <div>
                            <AvatarRound
                              textStyle="font-size: 10px;"
                              :size="30"
                              :avatar="getMemberProfile(reply.user)"
                              :name="memberInitials(reply.user)"
                            />
                            <div
                              :key="index2" v-if="index2 !== event.Replies.length-1" style="height: 100%; padding-bottom: 18px" class="d-flex justify-center">
                              <div style="background: #EAEAEA; height: 100%; width: 2px;" />
                            </div>
                          </div>
                        <v-col v-if="!replyeditDiv" class="mt-0 pt-0">
                          <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                            <AudioConversation
                              @play="id => activeMusicId=id"
                              :activeMusicId="activeMusicId"
                              :messageid="reply.id"
                              :key="reply.audioFile"
                              :reply="reply.audioFile"
                            />
                      </div>
                          {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
                          <div class="d-flex pt-2">
                            <div class="date-time pl-0 ml-0 pt-0">
                              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
                            </div>
                            <div
                              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
                              @click="edit2(reply.id, reply.userId)" class="reply">
                                Edit
                            </div>
                          </div>
                        </v-col>
            <v-col v-if="replyeditDiv && reply.id !== editMessageId" class="mt-0 pt-0 pb-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                            <AudioConversation
                              @play="id => activeMusicId=id"
                              :activeMusicId="activeMusicId"
                              :messageid="reply.id"
                              :key="reply.audioFile"
                              :reply="reply.audioFile"
                            />
                      </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
          <v-col style="width: 760px;" class="pb-0 pt-2" v-if="replyeditDiv && reply.id === editMessageId">
            <div class="text-area pt-0">
            <v-textarea
               label="Leave Message..."
               outlined
               rows="1"
               @change="editdata"
               :value="reply.message"
               @input="editdata"
               >
            </v-textarea>
            </div>
          <v-col class="buttons pb-0 pt-0">
            <div class="d-flex justify-end">
               <button
                style="min-height: 30px;font-size: 14px;"
                class="cancel cursor-pointer mr-5"
                @click="replyeditDiv = false">Cancel
              </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader"
                :disabled="!updatemessage.length"
                @click="saveEditMessage"
                >Save</v-btn>
                </div>
                </v-col>
          </v-col>
          </v-row>
          </div>
                        </div>
                     </div>
                    <div v-if="(event.id !== editMessageId && editDiv) || (event.id !== replyMessageId && replyDiv)" class="content mt-0">
                      <div v-if="event.audioFile" class="ml-3" style="width: 60%;">
                        <AudioConversation
                          @play="id => activeMusicId=id"
                          :activeMusicId="activeMusicId"
                          :messageid="event.id"
                          :key="event.audioFile"
                          :reply="event.audioFile"
                        />
                      </div>
                        <div class="d-flex justify-space-between">
                          <div
                          class="font-h5 location-name">
                          {{event.message}}<span class="date-time" v-if="event.edited"> (edited)</span>
                        </div>
                        </div>
                        <div class="d-flex pt-2">
                        <div class="date-time">
                        {{moment(event.createdAt).format('MM/DD/YYYY - hh:mma')}}
                        </div>
                        <div
                        v-if="canAccess && event.user !== null"
                        @click="reply(event.id, event.user.id)" class="reply">
                        <Redo/> Reply
                        </div>
                        <span v-if="event.user !== null && canAccess">
                        <div
                        v-if="currectUserId === event.user.id && !event.audioFile"
                        @click="edit(event.id, event.user.id)" class="reply">
                        Edit
                        </div>
                        </span>
                        </div>
                        <div v-for="(reply, index2) in event.Replies">
          <div class="mb-2">
            <v-row
            class="font-h5 location-name ml-1 mt-1">
            <div>
            <AvatarRound
              textStyle="font-size: 10px;"
              :size="30"
              :avatar="getMemberProfile(reply.user)"
              :name="memberInitials(reply.user)"
            />
            <div
            :key="index2" v-if="index2 !== event.Replies.length-1" style="height: 100%; padding-bottom: 18px" class="d-flex justify-center">
              <div style="background: #EAEAEA; height: 100%; width: 2px;" />
            </div>
            </div>
            <v-col v-if="!replyeditDiv" class="mt-0 pt-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                <AudioConversation
                  @play="id => activeMusicId=id"
                  :activeMusicId="activeMusicId"
                  :messageid="reply.id"
                  :key="reply.audioFile"
                  :reply="reply.audioFile"
                />
                      </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
            <v-col v-if="replyeditDiv && reply.id !== editMessageId" class="mt-0 pt-0 pb-0">
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
          <v-col style="width: 760px;" class="pb-0 pt-2" v-if="replyeditDiv && reply.id === editMessageId">
            <div class="text-area pt-0">
            <v-textarea
               label="Leave Message..."
               outlined
               rows="1"
               @change="editdata"
               :value="reply.message"
               @input="editdata"
               ></v-textarea>
            </div>
          <v-col class="buttons pb-0 pt-0">
            <div class="d-flex justify-end">
               <button
                style="min-height: 30px;font-size: 14px;"
                class="cancel cursor-pointer mr-5"
                @click="replyeditDiv = false">Cancel
              </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader"
                :disabled="!updatemessage.length"
                @click="saveEditMessage"
                >Save</v-btn>
                </div>
                </v-col>
          </v-col>
          </v-row>
          </div>
        </div>
                     </div>
                     <div
          class="edit-container ml-4"
          fluid
          v-if="editDiv && event.id === editMessageId && !replyDiv"
        >
        <div class="text-area" style="width: 102%;">
            <v-textarea
               label="Leave Message..."
               outlined
               class="edit-input"
               rows="1"
               @change="editdata"
               :value="event.message"
               @input="editdata"
               ></v-textarea>
          </div>
          <v-col class="buttons pb-0 pt-0 ml-5">
            <div class="d-flex justify-end">
               <button
                style="min-height: 30px;"
                class="cancel cursor-pointer mr-5"
                @click="editDiv = false">Cancel
              </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader"
                :disabled="!updatemessage.length"
                @click="saveEditMessage"
                >Save</v-btn>
                </div>
                </v-col>
                <div v-for="(reply, index2) in event.Replies">
          <div class="mb-2">
            <v-row
            class="font-h5 location-name ml-4">
            <div>
            <AvatarRound
              textStyle="font-size: 10px;"
              :size="30"
              :avatar="getMemberProfile(reply.user)"
              :name="memberInitials(reply.user)"
            />
            <div
            :key="index2" v-if="index2 !== event.Replies.length-1" style="height: 100%; padding-bottom: 18px" class="d-flex justify-center">
              <div style="background: #EAEAEA; height: 100%; width: 2px;" />
            </div>
            </div>
            <v-col v-if="!replyeditDiv" class="mt-0 pt-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                <AudioConversation
                  @play="id => activeMusicId=id"
                  :activeMusicId="activeMusicId"
                  :key="reply.audioFile"
                  :messageid="reply.id"
                  :reply="reply.audioFile"
                />
              </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
            <v-col v-if="replyeditDiv && reply.id !== editMessageId" class="mt-0 pt-0 pb-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                <AudioConversation
                  @play="id => activeMusicId=id"
                  :activeMusicId="activeMusicId"
                  :key="reply.audioFile"
                  :messageid="reply.id"
                  :reply="reply.audioFile"
                />
              </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
          <v-col style="width: 760px;" class="pb-0 pt-2" v-if="replyeditDiv && reply.id === editMessageId">
            <div class="text-area pt-0">
            <v-textarea
               label="Leave Message..."
               outlined
               rows="1"
               @change="editdata"
               :value="reply.message"
               @input="editdata"
               >
            </v-textarea>
            </div>
          <v-col class="buttons pb-0 pt-0">
            <div class="d-flex justify-end">
               <button
                style="min-height: 30px;font-size: 14px;"
                class="cancel cursor-pointer mr-5"
                @click="replyeditDiv = false">Cancel
               </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader"
                :disabled="!updatemessage.length"
                @click="saveEditMessage"
                >Save</v-btn>
                </div>
                </v-col>
          </v-col>
          </v-row>
          </div>
        </div>
         </div>
         <div
          class="edit-container"
          fluid
          v-if="replyDiv && event.id === replyMessageId && !editDiv"
        >
        <div v-if="event.audioFile" class="ml-7" style="width: 60%;">
          <AudioConversation
            @play="id => activeMusicId=id"
            :activeMusicId="activeMusicId"
            :messageid="event.id"
            :key="event.audioFile"
            :reply="event.audioFile"
          />
                      </div>
          <div class="d-flex justify-space-between">
            <div
            style="width: 700px;"
            class="font-h5 location-name ml-4">
            {{event.message}}<span class="date-time" v-if="event.edited"> (edited)</span>
          </div>
          </div>
          <v-col class="date-time pt-0 ml-1">
            {{moment(event.createdAt).format('MM/DD/YYYY')}}
          </v-col>
          <div v-for="(reply, index2) in event.Replies">
          <div class="mb-2">
            <v-row
            class="font-h5 location-name ml-5">
            <div>
            <AvatarRound
              textStyle="font-size: 10px;"
              :size="30"
              :avatar="getMemberProfile(reply.user)"
              :name="memberInitials(reply.user)"
            />
            <div
            :key="index2" v-if="index2 !== event.Replies.length-1" style="height: 100%; padding-bottom: 18px" class="d-flex justify-center">
              <div style="background: #EAEAEA; height: 100%; width: 2px;" />
            </div>
            </div>
            <v-col v-if="!replyeditDiv" class="mt-0 pt-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                <AudioConversation
                  @play="id => activeMusicId=id"
                  :activeMusicId="activeMusicId"
                  :key="reply.audioFile"
                  :messageid="reply.id"
                  :reply="reply.audioFile"
                />
              </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
            <v-col v-if="replyeditDiv && reply.id !== editMessageId" class="mt-0 pt-0 pb-0">
              <div v-if="reply.audioFile" class="ml-3" style="width: 70%;">
                <AudioConversation
                  @play="id => activeMusicId=id"
                  :activeMusicId="activeMusicId"
                  :key="reply.audioFile"
                  :messageid="reply.id"
                  :reply="reply.audioFile"
                />
              </div>
              {{reply.message}}<span class="date-time" v-if="reply.edited"> (edited)</span>
            <div class="d-flex pt-2">
            <div class="date-time pl-0 ml-0 pt-0">
              {{moment(reply.createdAt).format('MM/DD/YYYY - hh:mma')}}
            </div>
            <div
              v-if="currectUserId === reply.userId && !reply.audioFile && canAccess"
              @click="edit2(reply.id, reply.userId)" class="reply">
              Edit
            </div>
            </div>
          </v-col>
          <v-col style="width: 760px;" class="pb-0 pt-2" v-if="replyeditDiv && reply.id === editMessageId">
            <div class="text-area pt-0">
            <v-textarea
               label="Leave Message..."
               outlined
               rows="1"
               @change="editdata"
               :value="reply.message"
               @input="editdata"
               >
            </v-textarea>
            </div>
          <v-col class="buttons pb-0 pt-0">
            <div class="d-flex justify-end">
               <button
                style="min-height: 30px;font-size: 14px;"
                class="cancel cursor-pointer mr-5"
                @click="replyeditDiv = false">Cancel
               </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader"
                :disabled="!updatemessage.length"
                @click="saveEditMessage"
                >Save</v-btn>
                </div>
                </v-col>
          </v-col>
          </v-row>
          </div>
        </div>
        <v-row style="width: 105%;">
        <AvatarRound
            textStyle="font-size: 18px;"
            :size="50"
            :avatar="getMemberProfile(userDetails)"
            :name="memberInitials(userDetails)"
          />
          <v-col class="pt-0 text-area pb-0">
            <v-text-field
               :placeholder="seenPlacehold(2)"
               outlined
               class="edit-input"
               type="text"
               @input="checkForTitle2"
               :readonly="items.length ? true : false || recordOption2"
               v-model.trim="replymessage"
               rows="1"
               >
               <template
                  v-slot:prepend-inner
                  style="border: 1px solid red;position: relative;"
                  v-if="device && replyAudiomessage">
                <div v-if="recordOption2" class="mt-5" style="color: rgb(255 1 0 / 65%);">Recording... {{ count }}
                </div>
                <div v-if="!recordOption2" class="mt-1">
                  <audio v-if="!hiddenAudio2" controlsList="nodownload" :src="mainaudio.src" type="audio/mp3" id="myaudio" controls></audio>
                  <v-btn
                        icon
                        x-small
                        color="black"
                        class="remove-color-icon"
                        v-if="!hiddenAudio2"
                        @click="removeAudio(2)"
                        >
                        <v-icon size="10">mdi-close</v-icon>
                  </v-btn>
                </div>
                <audio id="audio" class="audio" ref="audio" hidden controls>
                </audio>
               </template>
               <template v-slot:append>
                <button
                  class="mt-4 mr-2"
                  v-if="!recordOption2"
                  @click="recordAudio(2)" type="button" id="button_record"><img src="@/assets/audiomic.png"/>
                </button>
                <button
                  class="mt-1"
                  style="border-radius: 50px;background-color: rgba(255, 0, 0, 0.208);width: 50px;height: 50px;"
                  v-if="recordOption2"
                  @click="recordAudiostop(2)" type="button" id="button_record">
                  <img src="@/assets/Rectangle179.png" height="15"/>
                </button>
              </template>
              </v-text-field>
          </v-col>
          </v-row>
          <v-col class="buttons pb-0 pt-0 ml-2">
            <div class="d-flex justify-end">
              <!-- replyDiv = false, hiddenAudio2 = true, this.recorder.stop(); -->
               <button
                style="min-height: 30px;"
                class="cancel cursor-pointer mr-5"
                @click="cancelReply">Cancel
              </button>
                <v-btn
                style="min-height: 30px;"
                class="submit ml-2 btn-purple"
                type="submit"
                :loading="loader2"
                :disabled="!getReplyAccess2"
                @click="saveReplyMessage"
                >Save</v-btn>
                </div>
                </v-col>
         </div>
               </v-col>
            </v-row>
         </template>
      </template>
      <div v-if="conversations.length > 5"
        class="mt-2"
              @click="showMore = !showMore">
              <button v-if="!showMore" class="button cursor-pointer">
                Load More<v-icon class="locationIcon" style="font-size: 30px;color: #262626;" dense>mdi-menu-down</v-icon>
              </button>
              <button v-if="showMore" class="button cursor-pointer">
                Load Less<v-icon class="locationIcon" style="font-size: 30px;color: #262626;" dense>mdi-menu-up</v-icon>
              </button>
            </div>
      </div>
      </div>
   </v-container>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Loader from '@/components/common/Loader';
import Redo from '@/components/common/svg/Redo';
import AvatarRound from '@/components/common/Avatar';
import AudioConversation from '@/components/sweeps/AudioConversation';

export default {
  name: 'Conversation',
  components: {
    Loader,
    Redo,
    AvatarRound,
    AudioConversation,
  },
  el: '#clock',
  data() {
    return ({
      activeMusicId: null,
      hiddenAudio: false,
      hiddenAudio2: false,
      getTime: 0,
      countTime: 0,
      count: '',
      items: '',
      titleerr: false,
      titleerr2: false,
      audiomessage: false,
      replyAudiomessage: false,
      recorder: null,
      mainaudio: '',
      replymainaudio: '',
      device: '',
      newFile: [],
      blob: '',
      downloadFile: '',
      recordOption: false,
      recordOption2: false,
      loadmore: false,
      loading: false,
      loader: false,
      loader2: false,
      activityFilter: 'All Activity',
      showMore: false,
      replyDiv: false,
      replymessage: '',
      editDiv: false,
      replyeditDiv: false,
      editUserId: '',
      replyMessageId: '',
      editMessageId: '',
      updatemessage: '',
      message: '',
      sent_loading: false,
    });
  },
  props: {
    canAccess: {
      type: Boolean,
      default: false,
    },
    conversation: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions('sweep', ['addConversation', 'getConversation', 'updateConversation']),
    // ...mapActions(['setNotification']),
    moment,
    async checkForTitle() {
      if (this.mainaudio.src) {
        this.titleerr = false;
      }
      if (this.message) {
        this.titleerr = false;
      }
    },
    async checkForTitle2() {
      if (this.mainaudio.src) {
        this.titleerr2 = false;
      }
      if (this.message) {
        this.titleerr2 = false;
      }
    },
    seenPlacehold(val) {
      if (val === 1) {
        if (this.mainaudio.src || this.recordOption) {
          return '';
        }
        return 'leave message...';
      }
      if (val === 2) {
        if (this.mainaudio.src || this.recordOption2) {
          return '';
        }
        return 'leave message...';
      }
      return 'leave message...';
    },
    removeAudio(val) {
      if (val === 1) {
        this.audiomessage = false;
        this.hiddenAudio = true;
        this.mainaudio = [];
        this.newFile = [];
        this.items = [];
      }
      if (val === 2) {
        this.hiddenAudio2 = true;
        this.items = [];
        this.mainaudio = [];
        this.newFile = [];
      }
    },
    secondsToHms(seconds) {
      if (seconds === '') return '';
      if (Number.isNaN(seconds)) return '';
      const d = Number(seconds);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const formattedDuration = `${h > 0 ? h : ''}${h > 0 ? ':' : ''}`
        + `${m}:`
        // eslint-disable-next-line prefer-template
        + `${s > 9 ? s : '0' + s}`;
      return formattedDuration;
    },
    recordAudio(val) {
      if (val === 1) {
        this.message = '';
        this.audiomessage = true;
        this.hiddenAudio = false;
        this.recordOption = true;
      }
      if (val === 2) {
        this.replymessage = '';
        this.replyAudiomessage = true;
        this.hiddenAudio2 = false;
        this.recordOption2 = true;
      }
      this.device = navigator.mediaDevices.getUserMedia({ audio: true });
      this.items = [];
      this.count = '0:00';
      this.device.then((stream) => {
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = (e) => {
          this.items.push(e.data);
          if (this.recorder.state === 'inactive') {
            this.blob = new Blob(this.items, {
              type: 'audio/mpeg',
            });
            this.mainaudio = document.getElementById('audio');
            const url = URL.createObjectURL(this.blob);
            this.mainaudio.controls = true;
            this.mainaudio.src = url;
            this.replymainaudio = this.mainaudio.src;
            this.mainaudio.href = url;
            // eslint-disable-next-line
            this.downloadFile = new Date().toISOString() + '.wav';
            this.newFile = new File([this.blob], this.downloadFile, { type: 'audio/mp3' });
            this.mainaudio.setAttribute('filename', this.downloadFile);
          }
        };
        this.recorder.start();
        this.countTime = 1;
        this.getTime = setInterval(() => {
          // eslint-disable-next-line
          this.count = this.secondsToHms(this.countTime++);
        }, 1000);
      });
    },
    recordAudiostop(val) {
      if (val === 1) {
        this.recordOption = false;
      }
      if (val === 2) {
        this.recordOption2 = false;
      }
      window.clearTimeout(this.getTime);
      this.recorder.stop();
    },
    cancelReply() {
      this.recordOption2 = false;
      this.hiddenAudio2 = true;
      this.mainaudio = '';
      this.items = [];
      this.newFile = [];
      this.replyDiv = false;
      window.clearTimeout(this.getTime);
    },
    edit(val2, val3) {
      this.editMessageId = val2;
      this.editUserId = val3;
      this.editDiv = true;
      this.replyDiv = false;
    },
    edit2(val2, val3) {
      this.editMessageId = val2;
      this.editUserId = val3;
      this.replyeditDiv = true;
    },
    reply(val2, val3) {
      this.editUserId = val3;
      this.replyMessageId = val2;
      this.replyDiv = true;
      this.editDiv = false;
      this.replymessage = '';
    },
    editdata(val) {
      this.updatemessage = val;
    },
    async saveEditMessage() {
      this.loader = true;
      const data = await this.updateConversation({
        messageId: this.editMessageId,
        message: this.updatemessage,
      });
      this.conversation.conversation = data.conversation;
      this.loader = false;
      this.updatemessage = '';
      this.editDiv = false;
      this.replyeditDiv = false;
    },
    async saveReplyMessage() {
      this.loader2 = true;
      const formData = new FormData();
      formData.set('audioFile', this.newFile);
      formData.set('sweepId', this.$route.params.sweepId);
      formData.set('messageId', this.replyMessageId);
      formData.set('message', this.replymessage);
      // const formData = {
      //   reply: 'yes',
      //   messageId: this.replyMessageId,
      //   sweepId: this.$route.params.sweepId,
      //   message: this.replymessage,
      //   audioFile: this.newFile,
      // };
      const data = await this.addConversation(formData);
      if (data.success) {
        this.conversation.conversation = data.conversation;
      }
      this.replyDiv = true;
      this.items = [];
      this.mainaudio = [];
      this.newFile = [];
      this.hiddenAudio2 = true;
      this.mainaudio.src = '';
      this.replymessage = '';
      this.loader2 = false;
    },
    getMemberProfile(member) {
      const pic = _.get(member, 'profilePic', null);
      return pic;
    },
    async sendMessage() {
      this.sent_loading = true;
      const formData = new FormData();
      formData.set('audioFile', this.newFile);
      formData.set('sweepId', this.$route.params.sweepId);
      formData.set('message', this.message);
      // const formData = {
      //   sweepId: this.$route.params.sweepId,
      //   message: this.message,
      //   audioFile: this.newFile,
      // };
      const data = await this.addConversation(formData);
      if (data.success) {
        this.conversation.conversation = data.conversation;
      }
      this.items = [];
      this.mainaudio = [];
      this.newFile = [];
      this.audiomessage = false;
      this.hiddenAudio = true;
      this.message = '';
      this.sent_loading = false;
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('sweepBlocks', ['sweepBlocks']),
    getReplyAccess2() {
      // if (this.mainaudio.src && this.replymessage.length > 0) {
      //   return false;
      // }
      if (this.titleerr2) {
        return false;
      }
      if (this.audiomessage && this.mainaudio.src) {
        return false;
      }
      if (this.mainaudio.src || this.replymessage.length > 0) {
        return true;
      }
      return false;
    },
    getReplyAccess() {
      // if (this.mainaudio.src && this.message.length) {
      //   return false;
      // }
      if (this.titleerr) {
        return false;
      }
      if (this.mainaudio.src || this.message.length > 0) {
        return true;
      }
      return false;
    },
    currectUserId() {
      return _.get(this.userDetails, 'id');
    },
    conversations() {
      if (this.activityFilter === 'All Activity') {
        return this.conversation.conversation;
      }
      if (this.activityFilter === 'Notes & Comments') {
        return this.conversation.conversation.filter((x) => x.activity === '1');
      }
      if (this.activityFilter === 'System Events') {
        return this.conversation.conversation.filter((x) => x.activity === '2');
      }
      return this.conversation.conversation;
    },
    moreConversation() {
      if (this.showMore) {
        return this.conversations;
      }
      return this.conversations.slice(0, 5);
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
  },
  async mounted() {
    this.loading = true;
    await this.getConversation(this.$route.params.sweepId);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.timeline-container {
  ::v-deep.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 0px !important;
  }
  ::v-deep.v-text-field--enclosed .v-input__append-inner {
    margin-top: 0px !important;
  }
}
.remove-color-icon {
  display:inline;
  position:absolute;
  margin-left: -10px;
  width: 18px;
  height: 18px;
  z-index: 2;
  background-color: #f4f4f4;
  box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
}
// &:hover {
//   .remove-color-icon {
//     display: block;
//   }
// }
.audiobutton {
  background-color: rgba(255, 0, 0, 0.208);
}
.edit-container {
  width: 800px;
  .submit {
    font-size: 14px;
  }
}
.button {
        height: 40px;
        width: 800px;
        font-family: 'Poppins';
        background: #ffffff;
        border: 2px solid #41E0BA;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #262626;
    }
.timeline-container {
  .date-time {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #ADADAD;
padding-right: 10px;
  }
  .reply {
    border-left: 2px solid #DCDCDC;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
padding: 0 10px;
color: $primary1;
cursor: pointer;
  }
  .sendMessage {
    padding-bottom: 15px;
    color: $primary1;
    cursor: pointer;
  }
  .text-area {
    padding-top: 10px;
    width: 100%;
    ::v-deep.v-text-field__details {
      display: none !important;
    }
  }
  .item-avatar {
  margin-right: 10px;
  background-color: #c0c0c0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.item-avatar2 {
  margin-right: 10px;
  background-color: #c0c0c0;
  border-radius: 50%;
}
.person-avator {
  background-color: #c0c0c0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
  .location-name {
    white-space: pre-line;
    word-break: break-word;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #000000;
  }
  .timeline-title {
    border-bottom: 1px solid $grey3;
    .key-info-title {
      color: $card-title;
    }
    .select-option {
      max-width: 150px;
      ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot {
        border-color: white !important;
      }
}
  }
  .timeline {
    padding: 15px;
    .font-h5,
    .font-body {
      color: $charcoalBlack;
    }
    .circle {
    width: 10px;
height: 10px;
background-color: #C4C4C4;
border-radius: 50%;
  }
    .contentSection {
      padding-top: 0px;
      padding-bottom: 12px !important;
      .content {
        margin-left: 16px;
        padding: 0 0 21px 0;
        width: 100%;
        .settings {
          padding: 10px 12px;
          background: #e5e6f5;
          .settings-title {
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #575757;
          }
        }
        .text-purple {
          text-decoration: underline;
        }
      }
      .content-event {
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 10px;
        padding: 14px 20px;
        margin-top: 0px;
      }
    }
  }
}
.timeLineActions {
  .v-list-item {
    border-bottom: 1px solid $border2;
    padding-left: 20px;
    padding-right: 20px;
    .v-list-item__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $charcoalBlack;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.sweep-add-row {
padding-right: 15px;
padding-top: 5px;
padding-left: 1%;
.addSweepBlock {
    border-top: 1px solid #DEDEDE;
    padding-top: 10px;
    padding-right: 10px;
    color: $primary1;
    cursor: pointer;
    padding-left: 0;
  }
}
.optionsList {
  cursor: pointer;
  padding-left: 10px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-transform: capitalize;
color: #262626;
}
.optionCat {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 20px;
/* identical to box height */

letter-spacing: 0.5px;
text-transform: capitalize;

color: #B5B5B5;
}
.listRow {
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
border-radius: 4px;
margin: 1px;
width: auto;
min-width: 50%;
max-height: 300px;
overflow: hidden;
}
.listCol {
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
border-radius: 4px;
margin: 1px;
width: fit-content;
min-width: 50%;
max-height: 300px;
overflow: scroll;
overflow-x: auto;
}
.addSweepList {
  padding: 0px;
  padding-left: 15px;
}
.loadmore {
  background: #FFFFFF;
border: 2px solid #41E0BA;
border-radius: 30px;
width: 100%;
box-shadow: none;
}
.startTime {
  width: 110px;
  padding-left: 10px;
  padding-top: 7px;
}
.blockTime {
  width: 110px;
  padding-left: 10px;
  padding-top: 7px;
}
@media (max-width: 660px) {
    .blockTime {
      width: 150px;
      padding-left: 20px;
      padding-top: 7px;
    }
    .startTime {
  width: 140px;
  padding-left: 14px;
  padding-top: 7px;
}

  }
</style>
