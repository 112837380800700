<!-- eslint-disable max-len -->
<template>
  <div class="sweep-cancel-form">
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title">Cancel Sweep</span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('closeModal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="scollable-div">
        <v-row class="ma-0">
          <p class="font-body">
            You will be able to update the status of this sweep in the future.
          </p>
          <v-col cols="12" class="pa-0 mt-2">
            <label class="text-left font-label">Reason for canceling</label>
            <v-select
              solo
              flat
              class="type-selection mb-n3 notranslate"
              name="reason"
              :items="cancelReasons"
              item-text="reason"
              item-value="id"
              @change="setReason"
              :menu-props="{ 'content-class': 'notranslate' }"
              placeholder="Select..."
              append-icon="keyboard_arrow_down"
              :status="errors('reason').length ? 'error': 'normal'"
              :error-messages="errors('reason')"
              @input="$v.reason.$touch()"
              @blur="$v.reason.$touch()"
            ></v-select>
            <BaseTextAreaField
            hide-details
            v-if="otherField"
            placeholder="Why was this sweep canceled?"
              :rows="1"
              name="otherReason"
              v-model.trim="otherReason"
              class="otherReason"
              dense
              solo
              @blur="checkForOther"
              @input="checkForOther"
              :class="{
                'errorState v-input--has-state error--text': otherErr,
              }"
            />
            <p
            class="mb-0"
              :class="{
                'errorState v-input--has-state error--text': otherErr,
              }"
              v-if="otherErr">Please provide reason</p>
          </v-col>
          <div class="d-flex mt-2">
            <v-checkbox v-model="notifyUsers" class="notify" />
            <span
            class="font-body-small pt-1">
            Notify all participants of the cancelled sweep. </span>
          </div>
        </v-row>
        <span>
            <v-col
            v-if="getAlert(sweep.date, sweep.fromTime)"
            style="color: red;border: 1px solid black;border-radius: 3px;">
            Note: Are your sure, you want to cancel the sweep, if cancelled 6 credits will be debited within 48 hours of sweep.
            </v-col>
      </span>
      </div>
      <v-card-actions class="justify-end pa-5">
        <v-btn text class="py-0 cancel" color="primaryGray1" @click="$emit('closeModal')">
          Cancel
        </v-btn>
         <v-btn
          class="py-0 submit btn-purple"
          type="submit"
          @click="submitForm"
          :loading="loading"
          :disabled="$v.$dirty && $v.$invalid"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  name: 'CancelForm',
  props: {
    sweep: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseTextAreaField,
  },
  data() {
    return {
      loading: false,
      reason: '',
      otherReason: '',
      otherErr: false,
      otherField: false,
      notifyUsers: true,
    };
  },
  computed: {
    ...mapGetters('sweep', ['cancelReasons']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.reason.$dirty) return errors;
        switch (field) {
          case 'reason':
            if (!this.$v.reason.required) { errors.push('Please provide reason'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    ...mapActions('sweep', ['cancelSweep']),
    getAlert(date, time) {
      const Time = moment(time, 'HH:mm').format('HH:mm');
      const Datee = moment(date).format('D MMM, YYYY');
      const sweepDate = `${Datee} ${Time}`;
      const now = moment().format('D MMM, YYYY HH:mm');
      const dateOneObj = new Date(sweepDate);
      const dateTwoObj = new Date(now);
      const milliseconds = Math.abs(dateTwoObj - dateOneObj);
      const hours = milliseconds / 36e5;
      const today = moment().format('YYYY-MM-DD');
      if (date < today) {
        return true;
      }
      if (hours < 48) {
        return true;
      }
      return false;
    },
    async checkForOther() {
      if (!this.otherReason) {
        this.otherErr = true;
      } else {
        this.otherErr = false;
      }
    },
    setReason(val) {
      this.reason = val;
      const item = _.find(this.cancelReasons, (i) => i.id === val);
      if (item.reason.toLowerCase() === 'other') {
        this.otherField = true;
      } else {
        this.otherField = false;
      }
    },
    async submitForm() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid && !this.otherErr) {
        let data = {
          sweepId: this.sweep.id,
          cancelReasonId: this.reason,
          notifyUsers: this.notifyUsers,
        };
        if (this.otherReason) {
          data = { ...data, description: this.otherReason };
        }
        const result = await this.cancelSweep(data);
        if (result.success) {
          this.$emit('cancelled');
          setTimeout(() => {
            this.$emit('closeModal');
          }, 1000);
        }
      }
      this.loading = false;
    },
  },
  validations: {
    reason: { required },
  },
};
</script>

<style lang="scss" scoped>
.sweep-cancel-form {
  ::v-deep .otherReason .v-textarea .v-input__slot{
    box-shadow: none !important;
  }
  ::v-deep .otherReason.errorState {
    .v-textarea .v-input__slot {
      border: solid 1px $colorRed1 !important;
    }
  }
  ::v-deep .normalState .v-input__slot {
    border: 1px solid $border2 !important;
  }
  ::v-deep .nullState .v-input__slot {
    border: 1px solid $border2 !important;
  }
  .title-header {
    border-bottom: 1px solid $border2;
    padding: 16px 21px 21px;
  }
  .scollable-div {
    padding: 20px;
    ::v-deep .type-selection {
      margin-top: 6px;
      .v-input__slot {
        border: solid 1px $border2;
        min-height: 44px;
        box-shadow: none;
      }
    }
    ::v-deep .type-selection.error--text {
      .v-input__slot {
        border: solid 1px $colorRed1;
      }
      .v-messages__message {
        line-height: 10px !important;
      }
    }
  }
  .v-card__actions {
    .cancel ::v-deep .v-btn__content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .submit {
      min-width: 124px !important;
      margin-left: 12px !important;
    }
  }
}
</style>
