<template>
  <div>
  <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols=10>
          <span class="text modal-header-title"> Match Creatives </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="closeMatching">mdi-close</v-icon>
        </v-col>
      </v-row>
    <v-row class="ma-0 d-flex align-items-stretch sweep-team-matching-container">
      <v-col cols="9" class="white py-0">
        <v-row class="team-header">
          <v-col class="d-flex align-center pl-5" cols="4">
            <div class="text title">{{`${selectedSkill}s`}}</div>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <div class="mr-4 d-flex align-center">
            <v-checkbox
              v-model="availability"
              class="mr-4"
              label="Availability"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="distance"
              label="Distance"
              hide-details
            ></v-checkbox>
            </div>
            <v-text-field
              class="search-input mr-4"
              small
              type="text"
              solo
              dense
              flat
              outlined
              hide-details
              prepend-inner-icon="search"
              placeholder="Search..."
              v-model="search"
              :append-icon="search.length ? 'close' : ''"
              @click:append="search=''"
            >
            </v-text-field>
            <v-select
              :items="skillsets"
              label="Skill Set"
              solo
              dense
              outlined
              flat
              item-value="id"
              item-text="text"
              :hide-details="true"
              v-model="skillsetId"
              :menu-props="{'content-class' : 'skillset-dropdown'}"
              @change="changeSkillset"

            >
            <!-- <template v-slot:selection="{ item }">
              <v-chip small>
                <span>{{ item.name }}</span>
              </v-chip>
            </template> -->
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="creative-matching pa-0">
            <AddSweepTeam
              :search="search"
              @remove-filters="removeFilters()"
              @selected-members="updateSelectedMembers"
              :skillsetId="selectedSkillset"
              :skillsetName="selectedSkill"
              :sweepTeamIds="sweepTeamUsers"
              :sweepId="this.sweepId"
              :availability="availability"
              :distance="distance"
              :skillsets="skillsets"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=3 class="pa-0 pl-3">
        <div class="white invite-column pa-4">
          <div class="py-0 invite-header text-center"> Invite to project </div>
          <div class="selected-members-container pt-3">
            Order by priority
            <div class="selected-member-list mt-2 pt-1">
              <v-list subheader class="pa-0">
                <!-- <v-subheader>Order by priority</v-subheader> -->
                <draggable v-model="selected" group="people" @start="drag=true" @end="drag=false">
                  <v-list-item
                    v-for="creative in selected"
                    :key="creative.id"
                    class="px-2 my-2 invite-member white"
                  >
                    <v-icon>
                      mdi-drag
                    </v-icon>
                    <!-- eslint-disable-next-line max-len -->
                    <v-list-item-avatar size="27" class="mx-1">
                      <img
        v-if="creative.profilePic"
        :src="creative.profilePic"
      />
      <div
        v-if="!creative.profilePic"
        class="name-initial d-flex align-center justify-center"
        style="width: inherit; height: inherit; font-size: 16px; background: #c0c0c0"
      >
        {{ memberInitials(creative.name) }}
      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="notranslate" v-text="creative.name">
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        x-small
                        icon
                        color="primaryGray1"
                        @click="removeMember(creative.id)"
                      >
                        <v-icon >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
              <v-divider class="my-3"> </v-divider>
              <div class="drag-caption"> Select creatives here </div>
            </div>
            <div class="help-container pt-4">
              <div class="help-title py-1"> How it works? </div>
              <div class="help-text">
                Invitations will be sent out in the order of priority you selected.
                If creative 1 declines their invitation, creative 2 will automatically be invited.
              </div>
            </div>

        <v-row class="actions w-100">
              <v-col cols=12 class="d-flex justify-space-between">
                <v-btn
                text
                    class="mr-2"
                    color="primaryGray1"
                    @click="closeMatching"
                  >
                    Cancel
                </v-btn>
                <v-btn
                    class="btn-purple"
                    @click="inviteMembers(selectedSkill, selected)"
                    :disabled="selected.length == 0"
                  >
                    Invite
                  </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="removeSelected"
      max-width="600"
    >
      <v-card class="modal-card pa-4" flat>
        <div class="black--text pa-4">
          Changing Creative type will discard selections.
          Would you like to continue?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primaryGray1"
            @click="cancelSkillsetChange"
            class="py-0"
          >
            Cancel
          </v-btn>
          <v-btn text color="info"
            type="submit"
            class="pa-0 ml-2"
            @click="clearSelected"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTierSelectionForm"
      max-width="600"
    >
      <v-card class="tier-selection-card pa-4" flat>
        <div class="black--text pt-3 pb-5 font-family-2 font-weight-bold heading">
          Add Talent Type
        </div>

        <div v-for="(creative, index) in selected" :key="creative.id"
          class="d-flex align-end justify-center">
          <div class="user-details">
            <div class="d-flex black--text" v-if="index===0"> Talent </div>
            <v-list subheader class="pa-0 user-details-list-item">
              <v-list-item
                class="px-2 my-2 invite-member white"
              ><v-badge dot overlap bordered bottom :color="getStatusColor(creative.availability)">
                <v-list-item-avatar size="27" class="mx-1 ml-0">
                  <v-img
                    :alt="`${creative.name} avatar`"
                    :src="creative.profilePic"
                  ></v-img>
                </v-list-item-avatar>
                </v-badge>
                <v-list-item-content>
                  <v-list-item-title class="notranslate" v-text="creative.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    x-small
                    icon
                    color="fade"
                    @click="removeMember(creative.id)"
                  >
                    <v-icon >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div class="tier-details d-flex align-center my-2">
            <div class="px-4"> Type: </div>
              <v-select
                :items="creative.tier"
                :value="creative.tier[0]"
                outlined
                dense
                class="talent-type-select text-capitalize"
                :menu-props="{'content-class' : 'talent-type-dropdown'}"
                @change="updateTier($event, creative.id)"
                hide-details=""
              >
              </v-select>
          </div>
        </div>
        <v-card-actions class="mt-4 tier-actions">
          <v-spacer></v-spacer>
          <v-btn
          text
            @click="cancelTierSelection"
            class="pa-3 mr-3"
            color="primaryGray1"
          >
            Cancel
          </v-btn>
          <v-btn color="btn-purple"
            type="submit"
            class="pa-3 ml-2"
            @click="inviteTalentMembers(selectedSkill, selected)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import _ from 'lodash';
import EventBus from '@/helpers/event-bus';
import AddSweepTeam from '@/components/projects/sweep/AddSweepTeam';
import store from '@/store';

export default {
  components: {
    AddSweepTeam,
    draggable,
  },
  props: {
    sweepId: {
      type: [String, Number],
      required: true,
    },
    skillsets: {
      type: Array,
      required: true,
    },
    selectedSkillId: {
      type: Number,
      default: null,
    },
    sweepTeam: {
      type: Array,
      default: () => [],
    },
    sweepUserIds: {
      type: Array,
      default: () => [],
    },
    currentSkillName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    memberInitials() {
      return (memberName) => {
        let name = memberName;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    sweepTeamIds() {
      return _.map(_.filter(this.sweepTeam, ['deletedAt', null]), 'userId');
    },
    name(creative) {
      return `${creative.firstName} ${creative.lastName}`;
    },
  },
  data() {
    return {
      search: '',
      selected: [],
      selectedSkillset: this.selectedSkillId,
      skillsetId: this.selectedSkillId,
      availability: true,
      distance: true,
      removeSelected: false,
      selectedSkill: this.currentSkillName,
      sweepTeamUsers: this.sweepUserIds,
      sweepSkills: [
        { type: '1', text: 'Photographer', value: 'Photographer' },
        { type: '2', text: 'Videographer', value: 'Videographer' },
        { type: '3', text: 'Talent', value: 'Talent' },
        { type: '4', text: 'Field Producer', value: 'Field Producer' },
      ],
      showTierSelectionForm: false,
    };
  },
  methods: {
    ...mapActions('sweep', ['getSweep', 'assignCreatives']),
    updateSelectedMembers(selected) {
      this.selected = selected;
    },
    getStatusColor(availability) {
      if (availability === '1') {
        return 'green !important';
      }
      return 'red !important';
    },

    removeFilters() {
      this.search = '';
      this.skillsetIds = [];
    },

    removeMember(nodeId) {
      if (this.selected.length === 1) {
        this.showTierSelectionForm = false;
      }
      EventBus.$emit('deselect-member', nodeId);
    },

    cancelSkillsetChange() {
      this.skillsetId = this.selectedSkillset;
      this.removeSelected = false;
    },

    clearSelected() {
      this.selected = [];
      this.selectedSkillset = this.skillsetId;
      this.removeSelected = false;
    },
    changeSkillset() {
      if (this.selected.length) {
        this.removeSelected = true;
      } else {
        this.selectedSkillset = this.skillsetId;
      }
    },
    updateTier(val, id) {
      const creativeIndex = _.findIndex(this.selected, ['id', id]);
      this.selected[creativeIndex].defaultTier = val;
    },
    async inviteMembers(skill, selected) {
      const { type } = _.find(this.sweepSkills, ['value', skill]);
      const creatives = _.map(selected, (user) => ({ type, id: user.id }));
      const payload = { sweepId: this.sweepId, creatives };
      const result = await this.assignCreatives(payload);
      if (result.success) {
        this.sweepTeamUsers = _.concat(this.sweepTeamUsers, _.map(selected, 'id'));
      }
    },
    closeMatching() {
      const newTeamAdded = this.sweepTeamUsers.length > this.sweepUserIds.length;
      this.$emit('onCancelMatching', newTeamAdded);
    },
    cancelTierSelection() {
      this.showTierSelectionForm = false;
    },
    async inviteTalentMembers(skill, selected) {
      const { type } = _.find(this.sweepSkills, ['value', skill]);
      const creatives = _.map(selected,
        (user) => ({ type, id: user.id, tier: user.defaultTier }));
      const payload = { sweepId: this.sweepId, creatives };
      const result = await this.assignCreatives(payload);
      if (result.success) {
        this.sweepTeamUsers = _.concat(this.sweepTeamUsers, _.map(selected, 'id'));
      }
      this.showTierSelectionForm = false;
    },
  },
  watch: {
    skillsetId(val) {
      this.selectedSkill = _.get(_.find(this.sweepSkills, ['type', val]), 'text', '');
    },
  },
  beforeDestroy() {
    store.commit('user/SET_CREATIVE_LIST', []);
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-badge--dot .v-badge__badge::after {
    border-width: 3px;
}
::v-deep .v-badge--dot .v-badge__badge {
    height: 12px;
    width: 12px;
    inset: calc(100% - 18px) auto auto calc(100% - 13px) !important;
    border-radius: 11px;
}
.sweep-team-matching-container {
  font-family: $fontFamily1;
  overflow: hidden !important;
  // .team-header {
  //   border-bottom: 1px solid $neutral5;
  // }
  .title {
    font-family: $fontFamily1 !important;
    font-size: 22px !important;
    font-weight: bold;
  }
}
.creative-matching {
  height: calc(100vh - 200px);
}
.invite-column {
  height: 100%;
  position: relative;
  .invite-header {
    font-size: 20px;
    font-weight: bold;
  }
  .selected-members-container {
    font-size: 16px;
    .v-list-item__title {
      font-size: 16px;
    }
    .drag-caption {
      color: $neutral7;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
    .help-container {
      font-size: 14px;
      .help-title {
        color: black;
      }
      .help-text {
        color: $neutral7;
        font-style: italic;
      }
    }
  }
  .invite-member {
    border: 1px solid $neutral10 !important;
    cursor: move;
  }
  .selected-member-list {
    max-height: calc(100vh - 550px);
    overflow-y: auto;
    background: $neutral9;
    border: 1px dashed $neutral8;
    padding: 10px;
    .v-list {
      background: $neutral9;
    }
  }
  .actions {
    position: absolute;
    bottom: 0px;
    ::v-deep .v-btn__content {
      font-size: 16px !important;
    }
  }
}
.tier-selection-card{
  .heading {
    font-size: 16px;
  }
}
.user-details-list-item {
  font-family: $fontFamily1;
  width: 250px;
  .invite-member {
    min-height: 40px;
    max-height: 40px;
    border: 1px solid $neutral10 !important;
  }
}
.talent-type-select {
  font-family: $fontFamily1;
  font-size: 14px;
  border-radius: 0;
  max-width: 110px;
}
  .tier-actions {
    ::v-deep .v-btn__content {
      font-size: 16px;
    }
  }
  .title-header {
    border-bottom: 1px solid #D1D1D1;
    padding: 15px;
  }
</style>
