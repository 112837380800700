<template>
  <v-form class="edit-key-info" @submit="submitForm">
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title">Sweep Key Info</span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" class="cancel" @click="$emit('closeModal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="scrollable-div">
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0">
            <label class="text-label"> Treatment Video URL </label>
            <div>
              <BaseInput
                keyInfo="keyInfo-link"
                class="text-input mb-2"
                v-model.trim="info.videoUrl"
                solo
                flat
                placeholder
                name="videoUrl"
                :status="errors('videoUrl').length ? 'error' : 'normal'"
                :error-messages="errors('videoUrl')"
                @input="$v.info.videoUrl.$touch()"
                @blur="$v.info.videoUrl.$touch()"
              />
            </div>
          </v-col>
          <!-- <v-col cols="12" class="pa-0">
            <label class="text-label"> Treatment Document URL (Milanote) </label>
            <div>
              <BaseInput
                keyInfo="keyInfo-link"
                class="text-input mb-2"
                v-model.trim="info.documentUrl"
                solo
                flat
                placeholder
                name="documentUrl"
                :status="errors('documentUrl').length ? 'error' : 'normal'"
                :error-messages="errors('documentUrl')"
                @input="$v.info.documentUrl.$touch()"
                @blur="$v.info.documentUrl.$touch()"
              />
            </div>
          </v-col> -->
          <v-col cols="12" class="pa-0">
            <label class="text-label"> Sweep Purpose </label>
            <BaseTextAreaField
              :rows="5"
              name="notes"
              class="sweepPurpose"
              dense
              solo
              v-model.trim="info.notes"
            />
          </v-col>
          <template v-if="resourceLinks.length">
            <v-col cols="12" class="pa-0 mb-2" v-for="(link, i) in resourceLinks" :key="i">
              <label class="text-label" v-if="i == 0">Resource Links</label>
              <v-row class="align-center ma-auto">
                <v-col class="pa-0">
                  <BaseInput
                    :class="{
                      'errorState v-input--has-state error--text': checkErr(link),
                    }"
                    keyInfo="keyInfo-link"
                    class="text-input resourcelink"
                    solo
                    flat
                    placeholder
                    :value="link.url"
                    name="resourceLinks"
                    @input="(e) => checkForUrl(e, i)"
                    @blur="checkForUrl"
                    hide-details
                  />
                </v-col>
                <v-icon
                  v-if="resourceLinks.length > 1"
                  class="cursor-pointer ml-4"
                  color="darken-1"
                  @click="removeLink(i)"
                  >mdi-close</v-icon
                >
              </v-row>
              <template>
                <div class="error--text" v-if="checkErr(link)" :key="link.valid">
                  Please provide valid url
                </div>
              </template>
            </v-col>
          </template>
          <div class="a-link addLink cursor-pointer" @click="pushLink()">+ Add link</div>
        </v-row>
      </div>
      <v-card-actions class="justify-end pa-4">
        <v-btn text class="py-0 cancel" color="primaryGray1" @click="$emit('closeModal')">
          Cancel
        </v-btn>
        <v-btn
          class="py-0 submit btn-purple"
          type="submit"
          :loading="loading"
          :disabled="$v.$dirty && $v.$invalid || resourceErr"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import { url, generateUrl } from '@/helpers';

export default {
  name: 'EditKeyInfo',
  props: {
    keyInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseInput,
    BaseTextAreaField,
  },
  data() {
    return {
      loading: false,
      info: {
        videoUrl: '',
        documentUrl: '',
        notes: '',
      },
      resourceLinks: [{ url: '' }],
      resourceErr: false,
    };
  },
  computed: {
    checkErr() {
      return (link) => !_.isEmpty(link.url) && _.has(link, 'valid') && !link.valid;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.info[field].$dirty) return errors;
        switch (field) {
          case 'videoUrl':
          case 'documentUrl':
            if (!this.$v.info[field].url) {
              errors.push('Please provide valid url');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    ...mapActions('sweep', ['editSweepKeyInfo']),
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid && !this.resourceErr) {
        const { resourceLinks, keyInfo } = this;
        const links = [];
        _.map(resourceLinks, (item) => {
          if (!_.isEmpty(item.url)) {
            links.push(item.url);
          }
        });
        const data = {
          ...this.info,
          resourcesLinks: links,
          sweepId: keyInfo.id,
          sweepPurpose: this.info.notes,
        };
        const result = await this.editSweepKeyInfo(data);
        if (result.success) {
          this.$emit('closeModal');
          this.$emit('successKeyInfo');
        }
      }
      this.loading = false;
    },
    async checkForUrl(e, index) {
      this.resourceLinks[index].url = e;
      const arr = [...this.resourceLinks];
      if (arr.length > 0) {
        let valid = false;
        _.map(arr, (obj, i) => {
          const objectData = obj;
          if (obj.url && url(obj.url)) {
            valid = true;
            this.resourceErr = false;
          } else if (obj.url && !url(obj.url)) {
            valid = false;
            this.resourceErr = true;
          }
          objectData.valid = valid;
          arr[i] = { ...objectData };
          this.resourceLinks = arr;
        });
      }
    },
    removeLink(index) {
      this.resourceLinks.splice(index, 1);
    },
    url(link) {
      return generateUrl(link);
    },
    pushLink() {
      this.resourceLinks.push({ url: '' });
    },
  },
  validations: {
    info: {
      videoUrl: { url },
      documentUrl: { url },
    },
  },
  async mounted() {
    this.info = { ...this.keyInfo };
    const arr = [];
    if (this.keyInfo.sweepResourceLinks.length > 0) {
      await _.map(this.keyInfo.sweepResourceLinks, (link) => {
        const obj = { url: link };
        arr.push(obj);
      });
    }
    this.resourceLinks = this.keyInfo.sweepResourceLinks.length > 0 ? arr : [{ url: '' }];
  },
};
</script>

<style lang="scss" scoped>
.edit-key-info {
  .text-label {
    color: $charcoalBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  ::v-deep .sweepPurpose textarea {
    padding-left: 12px;
  }
  ::v-deep .errorState .v-input .v-input__control .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 44px !important;
  }
  ::v-deep .resourcelink .v-text-field.v-text-field--solo {
    max-height: 44px !important;
  }
  ::v-deep .prepend-icon-link {
    width: 35px;
    height: 42px;
    padding: 10px;
    background: #f5f5f5;
    border-right: 1px solid $border2;
    border-radius: 3px 0px 0px 3px;
    display: flex;
    align-items: center;
  }
  ::v-deep .v-input__slot {
    padding-left: 0px !important;
  }
  ::v-deep .sweepPurpose .v-textarea .v-input__slot {
    box-shadow: none !important;
  }
  ::v-deep .normalState .v-input__slot {
    border: 1px solid $border2 !important;
  }
  ::v-deep .nullState .v-input__slot {
    border: 1px solid $border2 !important;
  }
  .title-header {
    border-bottom: 1px solid $border2;
    padding: 16px 21px 21px;
  }
  .scrollable-div {
    padding: 20px;
    // overflow: auto;
    // max-height: 493px;
  }
  .v-card__actions {
    .cancel ::v-deep .v-btn__content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .submit {
      min-width: 124px !important;
      margin-left: 12px !important;
    }
  }
}
</style>
