<!-- eslint-disable max-len -->
<template>
  <div class="pay-container mt-5">
    <div>
        <img src="@/assets/svg/logo-colored.svg" width="250" class="logo mb-5" />
        <div class="d-flex justify-center">
          <img src="@/assets/brandings/favicon.svg" width="50" class="nav-bs-logo" />
        </div>
        </div>
        <span v-if="!paymentMode">
            <v-col class="plan">
                Order confirmation
            </v-col>
            <h1 class="plan-heading pb-5">
              Order Summary
            </h1>
            <v-form class="d-flex justify-center">
                <v-col class="form pa-2" lg="5" xl="4" xxl="4">
                    <!-- <v-col class="text-left form-data pr-0">
                      {{ payment }}
                         <h3 class="headings pb-1" style="border-bottom: 1px solid #ccc">
                            Billing Frequency
                        </h3>
                        <v-radio-group row hide-details v-model="payplan" class="businessGroup">
                            <v-col class="no-wrap pl-0">
                            <div class="d-flex no-wrap pb-2">
                              <v-radio color="info" class="mr-0" label="Monthly" :value="1"></v-radio>
                            </div>
                            <div class="d-flex no-wrap pb-2">
                              <v-radio color="info" class="mr-0" label="Quarterly" :value="10"></v-radio>
                               <span class="ml-2 percent">(Save 10%)</span>
                            </div>
                            <div class="d-flex no-wrap">
                              <v-radio color="info" class="mr-0" label="Yearly" :value="20"></v-radio>
                                <span class="ml-2 percent"> (Save 20%)</span>
                            </div>
                            </v-col>
                        </v-radio-group>
                        <v-col class="pl-0">
                            <h3 style="border-bottom: 1px solid #ccc">Content Details</h3>
                            <v-col class="mt-3 details-form pa-4">
                                <v-col class="pl-0 input-field" v-for="name in userDetails.userBusinesses" :key="name">
                                    <h4 class="headings">Business Name</h4>
                                    <BaseInput
                                        solo
                                        flat
                                        name="First Name"
                                        v-model="name.name"
                                    />
                                </v-col>
                                <v-col class="pl-0 input-field">
                                    <h4 class="headings">Full Name</h4>
                                    <div class="d-flex">
                                    <BaseInput
                                        solo
                                        flat
                                        name="First Name"
                                        class="pr-3"
                                        v-model="userDetails.firstName"
                                    />
                                    <BaseInput
                                        solo
                                        flat
                                        name="First Name"
                                        v-model="userDetails.lastName"
                                    />
                                    </div>
                                </v-col>
                                <v-col class="pl-0 input-field">
                                    <h4 class="headings">Email Address</h4>
                                    <BaseInput
                                        solo
                                        flat
                                        name="First Name"
                                        v-model="userDetails.email"
                                    />
                                </v-col>
                                <v-col class="pl-0 input-field" cols="12" xs="12"
                                  sm="12"
                                  md="6"
                                  lg="6"
                                  xl="6"
                                  xxl="6">
                                    <h4 class="headings">Phone Number</h4>
                                    <BaseInput
                                        solo
                                        flat
                                        name="First Name"
                                        v-mask="'###-###-####'"
                                        v-model.trim="userDetails.number"
                                    />
                                </v-col>
                            </v-col>
                        </v-col>
                    </v-col> -->
                    <v-col class="calculation">
                        <!-- <h3 class="headings pb-1" style="border-bottom: 1px solid #ccc">
                            Order Summary
                        </h3> -->
                        <span class="text-left">
                          <!-- {{ payment }} -->
                        <v-row class="details pb-2 pt-2 pl-0 ma-0" style="border-bottom: 1px solid #ccc">
                            <v-col class="pl-0">{{ payment.name }}
                            </v-col>
                            <v-col cols="2" class="text-right">
                              {{ expCredits(payment) }}
                            </v-col>
                            <v-col cols="1" class="text-right pr-0">x</v-col>
                            <v-col cols="2" class="d-flex pl-0 pr-0 justify-end">
                              ${{ getaffective === 'Discount per Credits' ? getAmount : payment.perCredit }}
                            </v-col>
                        </v-row>
                        <div class="details pb-4 pt-4" style="border-bottom: 1px solid #ccc">
                            <v-row class="ma-0">
                                <v-col class="pa-0">Subtotal</v-col>
                                <v-col class="pa-0 d-flex justify-end">${{ payment.price.toLocaleString() }}</v-col>
                            </v-row>
                            <!-- <v-row class="ma-0" v-if="!couponDetails">
                                <v-col class="pa-0">Discount</v-col>
                                <v-col class="pa-0 d-flex justify-end" v-if="promocode" cols="3">
                                  - ${{ payment.price - getAmount }}
                                </v-col>
                                <v-col v-if="payplan > 1" cols="3" sm="3" md="2" class="pa-0 pl-3 mr-3 sub-heading">{{ payplan }}%</v-col>
                                <v-col v-if="payplan > 1" class="pa-0 d-flex justify-end" cols="3">(${{ discount(payplan) }})</v-col>
                            </v-row> -->
                        </div>
                        <v-row class="details pt-4 mr-0">
                            <v-col><h3 class="headings">Total Cost</h3>
                            <!-- <div class="sub-heading">Billed {{ planName(payplan) }}</div> -->
                            </v-col>
                            <v-col class="d-flex justify-end pr-0">
                              ${{ couponDetails ? payment.price.toLocaleString() : couponAmount.toLocaleString() }}
                            </v-col>
                        </v-row>
                        </span>
                    </v-col>
                </v-col>
            </v-form>
            <div class="d-flex justify-center mt-5" v-if="!paymentData">
          <v-col :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'" lg="5" xl="4">
              <v-row class="details-form text-left pa-5 mb-5 pb-2">
                <v-col class="pl-0 input-field">
                  <h4 class="headings">HAVE A PROMO CODE?</h4>
                  <v-row
                    :style="{ 'border' : couponDetails && !errormessage.length ? `1px solid #8066ff` : `1px solid ${errorColor}`, 'border-radius' : '5px' }"
                    class="ml-0"
                  >
                  <!-- :class="{
                      'errorState v-input--has-state error--text':
                        promocodeErr && !/^[A-Z]+$/.test(promocode)
                      }" -->
                  <v-text-field
                    v-if="couponDetails"
                    class="type-selection mt-1 pl-4"
                    outlined
                    placeholder="Enter promo code"
                    dense
                    v-model="promocode"
                  ></v-text-field>
                  <!-- @change="changeVal"
                    @input="changeVal" -->
                  <!-- <div :class="{
                          'errorState v-input--has-state error--text': promocodeErr,
                        }" v-if="!/^[A-Z]+$/.test(promocode) && promocodeErr">Allow only capital letters for coupon</div> -->
                      <!-- <BaseInput
                        solo
                        flat
                        v-if="couponDetails"
                        class="mt-1"
                        name="First Name"
                        placeholder="Apply PROMO CODE"
                        type="tel"
                        v-model="promocode"
                      /> -->
                      <!-- v-on:keypress="validInput($event)"
                        @change="changeVal"
                        @input="changeVal" -->
                      <v-col xs="8" sm="8" md="8" lg="10" xl="10" v-if="!couponDetails" class="d-flex no-wrap pa-4 pl-0 pt-0 pb-0">
                        <v-col cols="1" class="pt-2 pl-0">
                        <img
                          src="@/assets/svg/coupon_apply.svg"
                          class="download-icon cursor-pointer"
                          width="25"
                          height="25"
                        /></v-col>
                        <v-col>
                        {{ promocode.length ? `Coupon Applied for ${getAmount} ${getaffective === 'Add Extra Credits' ? 'extra credits' : 'per credit'}` : 'Wrong Promocode' }}
                        </v-col>
                      </v-col>
                <v-col cols="2" sm="2" md="2" lg="3" class="d-flex justify-end" v-if="couponDetails">
                  <!-- <v-btn class="btn-purple" @click="submitCoupon" :disabled="!/^[A-Z]+$/.test(promocode) && (promocodeErr || !promocode.length)">
                    Apply
                  </v-btn> -->
                  <div
                    class="cursor-pointer"
                    style="color: #8066ff;font-size: 15px;font-weight: 600;"
                    @click="submitCoupon">
                    Apply
                  </div>
                </v-col>
                <v-col cols="2" class="d-flex justify-end pb-0" v-if="!couponDetails">
                  <!-- <v-btn @click="removeCoupon"> -->
                    <div
                      class="cursor-pointer"
                      @click="removeCoupon"
                      style="color: red;font-size: 15px;font-weight: 600;"
                      src="@/assets/svg/theme/remove.svg"
                    >
                     Remove
                    </div>
                    <!-- <img
                      @click="removeCoupon"
                      src="@/assets/svg/theme/remove.svg"
                      class="download-icon cursor-pointer"
                      width="25"
                      height="25"
                    /> -->
                  <!-- </v-btn> -->
                </v-col>
                  </v-row>
                  <span style="color: red;" v-if="errormessage.length">{{ errormessage }}</span>
                </v-col>
              </v-row>
              <!-- <v-row class="details-form text-left mt-2"
                v-for="code in promocodes" :key="code"
              >
                <v-col class="pa-5">{{ code.name }} </v-col>
                <v-col class="text-right">
                <button class="btn-copy"
                  @click="copyCoupon(code.code)">
                  {{ code.code === promocode ? 'copied' : code.code }}
                </button>
                </v-col>
              </v-row> -->
          </v-col>
        </div>
            </span>
            <div class="d-flex justify-center" v-if="paymentMode">
            <v-col>
              <Payment
                :paymentData="paymentData"
                @closePayment="closePayment"
              />
              <!-- :paySuccess="paySuccess" -->
            </v-col>
            </div>
            <div v-if="!paymentMode" class="d-flex justify-center">
            <v-col class="continue-btn text-center" lg="5" xl="4" xxl="4">
                <v-btn
                        class="register-button btn-white mr-5"
                        type="submit"
                        @click="$emit('close')"
                        >Back</v-btn
                      >
                      <v-btn
                        class="register-button btn-purple"
                        type="submit"
                        @click="submitForm"
                        >Continue</v-btn
                      >
            </v-col>
        </div>
  </div>
</template>

<script>
// import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
// import BaseInput from '@/components/common/BaseInput';
import Payment from '@/components/dashboard/screens/client/Payment';

export default {
  components: {
    // BaseInput,
    Payment,
  },
  directives: { mask },
  props: {
    payment: {
      type: Object,
    },
  },
  data() {
    return {
      paymentMode: false,
      paymentData: '',
      couponAmount: 0,
      getAmount: 0,
      getaffective: '',
      errormessage: '',
      promocode: '',
      detailswrong: true,
      promocodeErr: false,
      couponDetails: true,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    errorColor() {
      if (this.errormessage.length) {
        return 'red';
      }
      return 'white';
    },
  },
  methods: {
    ...mapActions('user', ['applyCoupons']),
    // validInput(val) {
    //   // eslint-disable-next-line
    //   let char = String.fromCharCode(val.keyCode);
    //   if (/^[A-Za-z]+$/.test(char)) return true;
    //   return val.preventDefault();
    // },
    // changeVal() {
    //   let err = false;
    //   if (this.promocode.length === 0) {
    //     err = true;
    //   } else {
    //     err = false;
    //     if (!/^[A-Z]+$/.test(this.promocode)) {
    //       err = true;
    //     }
    //   }
    //   this.promocodeErr = err;
    // },
    // async copyCoupon(val) {
    //   this.promocode = val;
    //   const payload = {
    //     couponCode: this.promocode,
    //     price: this.payment.price,
    //   };
    //   const result = await this.applyCoupons(payload);
    //   console.log('rrr', result.data);
    //   console.log('aaaa', payload);
    //   console.log('promo', this.promocode);
    //   this.couponDetails = false;
    //   // const dis = this.payment.price * 25 / 100;
    //   // this.getAmount = this.payment.price - result.data;
    //   this.getAmount = result.data;
    //   console.log('afdsafsaf', result.data.error);
    //   if (result.data.error) {
    //     this.getAmount = this.payment.price;
    //   }
    // },
    closePayment() {
      this.$emit('close');
    },
    async submitCoupon() {
      const payload = {
        couponCode: this.promocode,
        price: this.payment.price,
        bundle: this.payment.name,
        // credits: this.payment.credits,
        // perCredit: this.payment.perCredit,
      };
      const result = await this.applyCoupons(payload);
      this.couponDetails = false;
      // const dis = this.payment.price * 25 / 100;
      // this.getAmount = this.payment.price - result.data;
      this.getAmount = result.discount;
      this.getaffective = result.affective;
      this.errormessage = '';
      if (this.getaffective === 'Discount per Credits') {
        this.couponAmount = this.getAmount * this.payment.credits;
      } else {
        this.couponAmount = this.payment.price;
      }
      if (result.data.error) {
        this.couponDetails = true;
        this.errormessage = result.data.error;
        this.detailswrong = false;
        this.getAmount = this.payment.perCredit;
        this.couponAmount = this.payment.price;
      }
    },
    removeCoupon() {
      this.couponDetails = true;
      this.detailswrong = true;
      if (this.getaffective === 'Add Extra Credits') {
        this.getAmount = '';
      }
      if (this.getaffective === 'Discount per Credits') {
        this.getAmount = this.payment.perCredit;
      }
      this.couponAmount = this.payment.price;
      this.promocode = '';
      this.errormessage = '';
    },
    expCredits(val) {
      if (val.extraCredits !== null && val.code !== null) {
        if (this.getaffective === 'Add Extra Credits') {
          return val.credits + val.extraCredits + this.getAmount;
        }
        return val.credits + val.extraCredits;
      }
      if (this.getaffective === 'Add Extra Credits') {
        return val.credits + this.getAmount;
      }
      return val.credits;
    },
    async submitForm() {
      if (!this.couponDetails) {
        this.payment.price = this.couponAmount;
      }
      const paymode = {
        businessId: this.userDetails.currentBusinessId,
        email: this.userDetails.email,
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastName,
        price: this.payment.price,
        bundleId: this.payment.id,
        credits: this.expCredits(this.payment),
        applyCouponCode: !this.couponDetails,
        couponCode: this.couponDetails ? '' : this.promocode,
      };
      // console.log('submit', paymode);
      // const result = await this.paymentMethod(paymode);
      // console.log(result.paymentIntent);
      this.paymentData = paymode;
      this.paymentMode = true;
      this.couponDetails = false;
      // this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-container {
  text-align: center;
  font-family: sans-serif;
  .nav-bs-logo {
    display: none;
  }
  ::v-deep #stripe-element-errors {
    color: red !important;
  }
  .btn-copy {
    padding: 5px !important;
    border: 3px dashed #8066ff;
    border-radius: 10px;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  ::v-deep .theme--light.v-label {
    color: black;
  }
  // ::v-deep .StripeElement {
  //   width: 100%;
  //   box-shadow: none;
  //   border: 1px solid #ccc;
  // }
  #custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
  .sub-heading {
    color: #969696;
    font-size: 16px;
    font-weight: 400;
  }
  .headings {
        font-size: 18px;
        line-height: 22px;
    }
    .details {
        font-family: Poppins;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
    }
  .plan {
    color: #a1a1a1;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .plan-heading {
    font-size: 44px;
    color: #333;
  }
  .form {
    border-radius: 5px;
    border: 1px solid #ccc;
    .form-data {
      min-width: 500px;
    }
  }
  .percent {
    color: #8066ff;
  }
  .border {
    min-width: 450px !important;
  }
  .details-form {
    border: 1px solid #ccc;
    border-radius: 3px;
    ::v-deep .v-input input {
      font-family: $fontFamily1;
      font-size: 15px !important;
      font-weight: 600;
      line-height: 18px;
    }
    ::v-deep .v-input__slot {
      height: 30px;
      padding-left: 0px !important;
    }
    // ::v-deep .input-field {
    //   height: 50px;
    // }
    ::v-deep .v-text-field--outlined fieldset {
      border: none !important;
    }
    ::v-deep .v-input__control {
      min-height: 40px !important;
    }
    ::v-deep .v-text-field__details {
      display: none !important;
    }
  }
  .continue-btn {
    border-top: 1px solid #c7c7c7;
  }
  @media (max-width: 600px) {
    .form {
      max-width: 350px;
      .form-data {
        min-width: 200px;
      }
    }
    .continue-btn {
      max-width: 350px;
    }
    .details-form {
      margin: 0px;
    }
    .calculation {
      background-color: #f3f3f3;
    }
    .input-field {
      padding: 0px !important;
    }
    .plan-heading {
      font-size: 31px;
    }
    .nav-bs-logo {
      display: block;
    }
    .logo {
      display: none;
    }
  }
}
</style>
