<!-- eslint-disable max-len -->
<template>
<svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6353_59061)">
<path d="M0 15.3333V10.6667C0.00176457 9.0759 0.634472 7.5508 1.75931 6.42597C2.88414 5.30113 4.40924 4.66842 6 4.66666H9.22V3.60932C9.22006 3.34566 9.29829 3.08793 9.4448 2.86871C9.59131 2.6495 9.79953 2.47865 10.0431 2.37775C10.2867 2.27686 10.5548 2.25046 10.8134 2.30188C11.072 2.3533 11.3095 2.48025 11.496 2.66666L15.416 6.58599C15.7909 6.96105 16.0016 7.46966 16.0016 7.99999C16.0016 8.53032 15.7909 9.03894 15.416 9.41399L11.496 13.3333C11.3095 13.5197 11.072 13.6467 10.8134 13.6981C10.5548 13.7495 10.2867 13.7231 10.0431 13.6222C9.79953 13.5213 9.59131 13.3505 9.4448 13.1313C9.29829 12.9121 9.22006 12.6543 9.22 12.3907V11.3333H5.33333C4.27279 11.3344 3.25599 11.7562 2.50608 12.5061C1.75616 13.256 1.33439 14.2728 1.33333 15.3333C1.33333 15.5101 1.2631 15.6797 1.13807 15.8047C1.01305 15.9298 0.843478 16 0.666667 16C0.489856 16 0.320286 15.9298 0.195262 15.8047C0.0702379 15.6797 0 15.5101 0 15.3333ZM10.5533 5.33332C10.5533 5.51014 10.4831 5.6797 10.3581 5.80473C10.233 5.92975 10.0635 5.99999 9.88667 5.99999H6C4.76276 6.0014 3.57659 6.49352 2.70173 7.36839C1.82686 8.24325 1.33474 9.42941 1.33333 10.6667V11.8093C1.83305 11.2406 2.44833 10.7851 3.1381 10.473C3.82788 10.161 4.57627 9.99976 5.33333 9.99999H9.88667C10.0635 9.99999 10.233 10.0702 10.3581 10.1953C10.4831 10.3203 10.5533 10.4898 10.5533 10.6667V12.3907L14.4727 8.47132C14.5976 8.34631 14.6679 8.17677 14.6679 7.99999C14.6679 7.82322 14.5976 7.65368 14.4727 7.52866L10.5533 3.60932V5.33332Z" :fill="color"/>
</g>
<defs>
<clipPath id="clip0_6353_59061">
<rect :width="width" :height="height" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
