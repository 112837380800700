import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-container"},[_c(VBtn,{staticClass:"close-icon",attrs:{"icon":"","width":"30","height":"30","color":"primaryGray1"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-close")])],1),_c(VCard,{staticClass:"detail-card",attrs:{"flat":""}},[_c(VImg,{staticClass:"white--text align-end profile-pic",attrs:{"height":"250px","src":_vm.getOptimizedS3ImageURL(_vm.user.profilePic,
        { width: 250, height: 250 } )}}),_c(VCardText,{staticClass:"text--primary pa-3 pb-2"},[_c('div',{staticClass:"name text-capitalize"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('div',{staticClass:"text mt-1"},[_vm._v(_vm._s(_vm.user.skillset))])]),_c(VDivider),(_vm.showTags)?_c(VCardText,{staticClass:"py-1 px-3"},[(_vm.user.interests && _vm.user.interests.length)?[_c('div',{staticClass:"text"},[_vm._v(" Interest: ")]),_vm._l((_vm.user.interests),function(name,i){return _c(VChip,{key:("interest-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e(),(_vm.user.camera && _vm.user.camera.length)?[_c('div',{staticClass:"text"},[_vm._v(" Camera: ")]),_vm._l((_vm.user.camera),function(name,i){return _c(VChip,{key:("camera-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e(),(_vm.user.lens && _vm.user.lens.length)?[_c('div',{staticClass:"text"},[_vm._v(" Lens: ")]),_vm._l((_vm.user.lens),function(name,i){return _c(VChip,{key:("lens-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e(),(_vm.user.accessories && _vm.user.accessories.length)?[_c('div',{staticClass:"text"},[_vm._v(" Accessories: ")]),_vm._l((_vm.user.accessories),function(name,i){return _c(VChip,{key:("accessories-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e(),(_vm.user.copyInterests && _vm.user.copyInterests.length)?[_c('div',{staticClass:"text"},[_vm._v(" Copy Interest: ")]),_vm._l((_vm.user.copyInterests),function(name,i){return _c(VChip,{key:("copyInterests-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e(),(_vm.user.styles && _vm.user.styles.length)?[_c('div',{staticClass:"text"},[_vm._v(" Style: ")]),_vm._l((_vm.user.styles),function(name,i){return _c(VChip,{key:("style-" + i),attrs:{"color":"#e5e7fa","label":""}},[_vm._v(" "+_vm._s(name)+" ")])})]:_vm._e()],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }