<!-- eslint-disable -->
<template>
    <v-container class="pt-6 overview px-0">
        <div class="font-h4 key-info-title pb-2 d-flex justify-space-between align-center">
          <span>Key Info</span>
          <div class="cursor-pointer editLink" @click="openEdit" v-if="canAccess"><EditSvg /></div>
        </div>
        <div class="content py-4">
            <span v-if="keyInfo.videoUrl">
            <div class="videoContainer d-flex"
                v-if="keyInfo.videoUrl.substr(keyInfo.videoUrl.length - 4) === '.mp4' && keyInfo.videoUrl || VideoUrl(keyInfo.videoUrl).substr(VideoUrl(keyInfo.videoUrl).length - 9) === 'undefined'">
              <div class="mr-4"><VideoIcon :color="keyInfo.videoUrl ? '#262626' : '#929292'"/></div>
              <div class="videoDiv" >
                <video-player
                :options="videoOptions" class="video-player" />
              </div>
            </div>
            <div v-else-if="keyInfo.videoUrl.substr(keyInfo.videoUrl.length - 4) !== '.mp4' && keyInfo.videoUrl && keyInfo.videoUrl.slice(8, 16) === 'youtu.be'">
              <div class="wrapper">
            <div class="mr-4"><VideoIcon :color="keyInfo.videoUrl ? '#262626' : '#929292'"/></div>
                <iframe scrolling="no" class="treatment-video"
                  frameborder="0" allowfullscreen height="790" width="1280" :src="VideoUrl(keyInfo.videoUrl)" ></iframe>
              </div>
            </div>
            <div v-else-if="keyInfo.videoUrl.slice(8, 20) === 'app.frame.io'">
              <div class="wrapper">
            <div class="mr-4"><VideoIcon :color="keyInfo.videoUrl ? '#262626' : '#929292'"/></div>
                <iframe scrolling="no" class="treatment-video-frameio"
                  frameborder="0" allowfullscreen width="1280" :src="VideoUrl(keyInfo.videoUrl)" ></iframe>
              </div>
            </div>
            <div class="d-flex" v-else-if="keyInfo.videoUrl && keyInfo.videoUrl.substr(keyInfo.videoUrl.length - 4) !== '.mp4'">
            <div class="mr-4"><VideoIcon :color="keyInfo.videoUrl ? '#262626' : '#929292'"/></div>
          <div class="fb-container ml-5" v-if="keyInfo.videoUrl.slice(8, 20) === 'www.facebook'">
            <iframe
              class="treatment-video3"
              :src="VideoUrl(keyInfo.videoUrl)"
              height="1100"
              width="700"
              frameborder="0"
              allowfullscreen></iframe>
          </div>
            <div id="fb-root" v-else>
                <iframe scrolling="no"
                  class="treatment-video2"
                  max-width="500px"
                  frameborder="0" allowfullscreen height="480" width="760" :src="VideoUrl(keyInfo.videoUrl)" ></iframe>
                  </div>
            </div>
            </span>
              <div v-else class="videoContainer d-flex">
                <div class="mr-4"><VideoIcon :color="keyInfo.videoUrl ? '#262626' : '#929292'"/></div>
                <div
                  class="zeroState d-flex align-center justify-center font-body-large">
                  <span> Treatment Video </span>
                </div>
              </div>
            <!-- <div class="d-flex pt-4">
              <div class="mr-4"> <Link /> </div>
              <template v-if="keyInfo.documentUrl">
                <span class="a-link cursor-pointer">
                  <a
                  :href="generateUrl(keyInfo.documentUrl)"
                  target="blank"
                  class="pr-2 overflow-wrap-anywhere">{{ keyInfo.documentUrl }} <Redo /> </a>
                </span>
              </template>
              <template v-else> - </template>
            </div> -->
            <div class="d-flex pt-4">
              <div class="mr-4"> <AlignLeft /> </div>
              <div v-if="keyInfo.notes" class="notes">
                <span style="white-space: pre-wrap;">
                  {{ keyInfo.notes }}
                </span>
              </div>
              <div v-else> - </div>
            </div>
            <div class="addLink d-flex pt-4">
              <div class="mr-4"> <Link /> </div>
              <div>
                <template v-if="keyInfo.sweepResourceLinks.length > 0">
                  <div
                  class="a-link cursor-pointer"
                  v-for="link in keyInfo.sweepResourceLinks"
                  :key="link">
                  <a
                    :href="generateUrl(link)"
                    target="blank"
                    class="pr-2 overflow-wrap-anywhere">{{ link }} </a>
                  </div>
                </template>
                <div v-else>-</div>
                <div
                class="a-link cursor-pointer"
                @click="openForm = true" v-if="canAccess"> + Add resource link</div></div>
            </div>
        </div>
        <v-container v-if="openForm">
          <Modal
            persistent
            content-class="new-user-form"
            :modal="openForm"
            scrollable
          >
            <EditKeyInfo
            @closeModal="openForm = false"
            :keyInfo="keyInfo"
            @successKeyInfo="$emit('successKeyInfo')"/>
          </Modal>
        </v-container>
    </v-container>
</template>

<script>
import VideoIcon from '@/components/common/svg/VideoPlay';
import AlignLeft from '@/components/common/svg/AlignLeft';
import Link from '@/components/common/svg/Link';
// import Redo from '@/components/common/svg/Redo';
import VideoPlayer from '@/components/common/VideoPlayer';
import EditSvg from '@/components/common/svg/EditSvg';
import Modal from '@/components/common/Modal';
import EditKeyInfo from '@/components/sweeps/EditKeyInfo';
import { generateUrl } from '@/helpers';

export default {
  name: 'Overview',
  components: {
    VideoIcon,
    AlignLeft,
    Link,
    // Redo,
    VideoPlayer,
    EditSvg,
    Modal,
    EditKeyInfo,
  },
  data() {
    return {
      openForm: false,
    };
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.keyInfo.videoUrl,
            type: 'video/mp4',
          },
        ],
      };
    },
  },
  methods: {
    generateUrl,
    VideoUrl(val) {
      if (val.slice(8, 20) === 'app.frame.io') {
        return val;
      }
      if (val.slice(8, 20) === 'www.facebook') {
        const url = val.split(/(vi\/|v%3D|v=|\/v\/|facebook\.com\/|\/videos\/)/);
        const link = url[4];
        const topOfQueue = `//www.facebook.com/video/embed?video_id=${link}`;
        return topOfQueue;
      }
      if (val.slice(8, 21) === 'www.instagram') {
        const url = val.split(/(vi\/|v%3D|v=|\/v\/|instagram\.com\/|\/embed\/)/);
        const YId = url[2];
        // eslint-disable-next-line
        let yid;
        if (YId.slice(0, 1) === 'p') {
          yid = YId.slice(2, 13);
        } else {
          yid = YId.slice(5, 16);
        }
        const topOfQueue = `//instagram.com/p/${yid}/embed`;
        return topOfQueue;
      }
      if (val.slice(8, 16) === 'youtu.be') {
        const url = val.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        const YId = url[2];
        const topOfQueue = `https://www.youtube.com/embed/${YId}?&controls=0&disablekb=1&loop=1&modestbranding=1&playsinline=1&color=white&playlist=${YId}`;
        return topOfQueue;
      }
      if (val.slice(8, 16) === 'www.loom') {
        const url = val.split(/(vi\/|v%3D|v=|\/v\/|loom\.com\/|\/embed\/)/);
        const YId = url[2].slice(6);
        const topOfQueue = `//loom.com/embed/${YId}`;
        return topOfQueue;
      }
      return `${val}undefined`;
    },
    openEdit() {
      this.openForm = true;
    },
  },
  props: {
    keyInfo: {
      type: Object,
      default: () => {},
    },
    canAccess: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height:500px;
  position:relative;
  overflow:hidden;
}
#fb-root {
    overflow: hidden;
    display: block;
}
.treatment-video {
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:5%;
  width:100%;
  height:56.25vw;
}
.treatment-video-frameio {
  position:absolute;
  top:4%;
  transform:translateY(-11%);
  left:5%;
  width:100%;
  height:50.25vw;
}
.treatment-video2 {
  overflow: hidden;
  margin-top: -60px !important;
}
.fb-container {
  overflow: hidden;
  display: block;
  height: 700px;
}
.treatment-video3 {
  overflow: hidden;
  margin-top: -160px !important;
}
.overview {
   .key-info-title {
        color: $card-title;
        border-bottom: 1px solid $grey3;
    }
    .notes {
      width: -webkit-fill-available;
      white-space: pre-wrap;
      max-height: 144px;
      overflow: auto;
    }
    .videoContainer {
      .videoDiv {
        background: #262626;
        height: 422px;
        width: 100%;
        .video-player {
          margin: 0 auto;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          .vjs-tech {
            top: auto;
            height: inherit;
          }
        }
      }
      .wrapper {
        height:500px;
        position:relative;
        overflow:hidden;
      }
      .zeroState {
        min-height: 245px;
        width: 100%;
        background: #F9F9F9;
        border: 3px dashed #C8C8C8;
        border-radius: 8px;
        color: $card-title;
      }
    }
}

</style>
