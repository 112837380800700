<!-- eslint-disable -->
<template>
  <v-container class="main-container sweepDetails-container">
    <Loader v-if="loading" style="height:70vh;"/>
    <template v-if="!creative && !loading">
      <DeleteSweep ref="DeleteSweepModal"/>
      <span class="a-link cursor-pointer" @click="$router.go(-1)">Back ></span>
      <h1 class="mt-10 font-h1">{{ sweep.title }}</h1>
      <v-row class="ma-0" :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }">
        <v-col xxl="8" xl="8" lg="8" md="8" sm="8" xs="12" class="px-0 pt-5">
          <div class="sweepDetails">
            <div class="d-flex justify-space-between align-center mb-4">
              <div class="font-label">sweep details</div>
              <v-menu content-class="actions" v-if="canAccess || clientAccess">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="btn-purple btn-primary-small align-center" v-bind="attrs" v-on="on"
                    >Actions
                    <span class="ml-1">
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 0L5 6L0 0H10Z" fill="white" />
                      </svg>
                    </span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="cursor-pointer" @click="openEditForm()">
                    <v-list-item-title>Edit Sweep</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item class="cursor-pointer"
                  @click="openResheduleForm()"
                  v-if="!clients"
                  :class="isSweepCanceled && 'pb-4'">
                    <v-list-item-title>Reschedule Sweep</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item
                  class="cursor-pointer cancel-sweep pb-4"
                  v-if="!isSweepCanceled"
                  @click="openCancelForm()">
                    <v-list-item-title>Cancel Sweep</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="cursor-pointer closeOut"
                  @click="openCloseout(sweep)"
                  v-if="enableCloseout && !clients">
                    <v-list-item-title>Closeout Sweep</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                  class="cursor-pointer delete"
                  @click="openDeleteForm()">
                    <v-list-item-title>Delete Sweep</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="mb-3 d-flex align-center">
              <div class="mr-4" style="width: 18px; height: 18px;">
                <Calender width="18" height="18" />
              </div>
              <!-- sweep canceled -->
              <span class="font-body cancel-div d-flex" v-if="isSweepCanceled">
                <span class="cancel-text">{{
                  `${moment(sweep.date).format('dddd, MMM DD, YYYY')}
                        ${sweep.timezone ? timeZone : ''}`
                }}</span>
                <span class="d-flex align-center text pl-4">
                  <span class="pr-2"> Canceled </span>
                  <v-tooltip
                    bottom
                    content-class="affiliatedOrg-tooltip"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on"
                      style="width: 16px; height: 16px; cursor: pointer;">
                        <Info color="#FF371B" />
                      </div>
                    </template>
                    <span>{{cancelledReason}}</span>
                  </v-tooltip>
                </span>
              </span>
              <span class="font-body" v-else>{{
                `
                        ${moment(sweep.date).format('dddd, MMM DD, YYYY')}
                        ${sweep.timezone ? timeZone : ''} `
              }}</span>
            </div>
            <div class="mb-3 d-flex align-center">
              <div class="mr-4" style="width: 18px; height: 18px;">
                <Clock width="18" height="18" />
              </div>
              <!-- During sweep -->
              <span class="font-body">
                <span class="text-live" v-if="isSweepLive && !isSweepCanceled">LIVE </span>
                {{
                  `${moment(sweep.fromTime, ['HH:mm']).format('h:mma')} -
                    ${moment(sweep.toTime, ['HH:mm']).format('h:mma')}`
                }}</span
              >
              <span class="font-label duration pl-4">
                {{ duration }}
              </span>
            </div>
            <div class="mb-1 d-flex">
              <div class="mr-4" style="width: 18px; height: 18px; padding-top: 3px;">
                <MapMarker width="18" height="18" />
              </div>
              <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                -
              </div>
              <div v-else-if="sweep.sweepLocations">
                <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                  <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                    Location #{{ i + 1 }}
                  </div>
                  <div class="font-body">
                    {{
                      `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                        location.zipcode ? `, ${location.zipcode}` : ''
                      }`
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="sweep.orderId" class="d-flex no-wrap mt-2">
          <Group width="18" height="18" />
          <div
            v-if="!clients && !editor"
            @click="gotoOrder(sweep.orderId)"
            class="ml-3 order-button">Order #{{ sweep.orderId }}</div>
            <div
            v-else-if="clients"
            @click="gotoOrder2(sweep.orderId)"
            class="ml-3 order-button">Order #{{ sweep.orderId }}</div>
            <div
            v-else
            class="ml-3">Order #{{ sweep.orderId }}</div>
            </div>
            <v-row v-if="sweep.orderId" class="ma-0 mt-3">
          <Broom width="18" height="18" />
          <span class="ml-3" v-for="(order, index) in sweepDeliverables" :key="order">
            <div :class="index !== 0 ? 'ml-4' : ''">
            {{ `${index === 0 ? `${order.deliverable.category.name} :` : ''}
            ${order.deliverable.name}
             (${order.deliverable.creditCost} credits)
             ${Object.keys(sweepDeliverables).length - 1 !== index ? ',' : ''}` }}
            </div>
          </span>
        </v-row>
            <!-- <div class="d-flex add-location" v-if="canAccess">
              <div style="width: 18px; height: 18px;" class="mr-4" />
              <div class="a-link cursor-pointer">+ Add Location</div>
            </div> -->
          </div>
          <div class="sweep-tabs mt-12">
            <v-tabs v-model="tab" class="tabs-padding pa-0" >
              <v-tab href="#overview">Overview</v-tab>
              <v-tab href="#timeline">
              Timeline
              </v-tab>
              <v-tab href="#conversation">
              Conversation
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab"
            class="font-family-2 analytics-height">
              <v-tab-item value="overview" :key="'overview'"
              :transition="false"
              :reverse-transition="false">
                <Overview :keyInfo="keyInfo" :canAccess="canAccess3 && !isSweepCanceled" @successKeyInfo="fetchSweep()"/>
              </v-tab-item>
              <v-tab-item value="timeline" :key="'timeline'"
              :transition="false"
              :reverse-transition="false">
                <Timeline
                :canAccess="(canAccess || clients) && !isSweepCanceled"
                :timeLines="getTimeLine"
                :sweep="timeLine"
                :lastUpdated="lastUpdated"
                />
              </v-tab-item>
              <v-tab-item value="conversation" :key="'conversation'"
              :transition="false"
              :reverse-transition="false">
                <Conversation
                  :canAccess="canAccess2 && !isSweepCanceled"
                  :conversation="Conversation"
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
        <v-col
          xxl="4"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pr-0 pt-5"
          :style="
            `padding-left: ${
              $vuetify.breakpoint.xsOnly ? '0px' : $vuetify.breakpoint.smOnly ? '30px' : '50px'
            }; display: ${
              $vuetify.breakpoint.xsOnly ? 'none' : $vuetify.breakpoint.smOnly ? 'none' : '50px'
            };`
          "
        >
          <div class="businessDetails">
            <div class="d-flex businessName">
              <div
                class="mr-4"
                style="width: 42px; height: 42px;"
                :style="
                  `border: ${sweep.business.bannerImage && '1px solid #E9E9E9;'}
                'border-radius:' ${sweep.business.bannerImage && '6px;'}`
                "
              >
                <img
                  :src="getOptimizedS3ImageURL(getBusinessProfile, { width: 250, height: 250 })"
                  style="height: 100%; object-fit: cover; width: 42px; height: 42px;"
                />
              </div>
              <span>
                <div class="font-h5 breakWord cursor-pointer" @click="gotoBusiness(sweep)">
                  {{ businessInfo.name }}
                </div>
                <div class="font-body-small">
                  {{ (businessInfo.typeOfService && businessInfo.typeOfService.name) || '-' }}
                </div>
              </span>
            </div>
            <div class="mb-4 font-body-small">
              <div class="label">Website</div>
              <div>{{ businessInfo.website || '-' }}</div>
            </div>
            <div class="mb-4 font-body-small">
              <div class="label">Goal</div>
              <div>{{ (businessInfo.goal && businessInfo.goal.name) || '-' }}</div>
            </div>
            <div class="font-body-small">
              <div class="label">Strategy</div>
              <div>{{ businessInfo.strategy || '-' }}</div>
            </div>
          </div>
          <div v-if="!clients">
           <div class="creative-heading">Creative Team</div>
           <div v-for="skill in sweepSkills" :key="skill.id">

           <v-container class="creativesDetails">
              <v-row class="type-data">
               <v-col lg="10" class="creativeName">
                 {{`${skill.text}(s)`}}
               </v-col>
               <v-col v-if="matchedTeam(skill.id).length && (canAccess && !isSweepCanceled)"
                class="cursor" lg="1" @click="()=>openCreativeForm(skill.text)">
                <img src="@/assets/svg/edit2.svg" class="ml-2 mr-2" width=20 height=20/>
               </v-col>
              </v-row>
              <v-row>
               <TeamList
                :creatives="sweepTeam(skill.id)"
                :maxCreatives="sweep[`max${skill.text}`]"
                :creativeType="skill.id"
                :sweepId="sweep.id"
                @onAddClick="openCreativeForm(skill.text)"
                @reload-sweeps="fetchSweep"
                :canAccess="canAccess && !isSweepCanceled"
              />
              </v-row>
           </v-container>
           </div>
          </div>
          <div v-if="(sweep.pointOfContact.firstName || creativeSweepTeam.length) && clients" class="mt-5 timeline-div creatives-team">
        <v-col v-if="sweep.pointOfContact.firstName || creativeSweepTeam.length" class="timelinehd-div py-0 pl-0">
          <span class="timeline-heading">Team</span>
        </v-col>
        <div v-if="sweep.pointOfContact.firstName" class="mt-5 producer-contact-img">
        <div class="creatives-heading">CONTENT PRODUCER</div>
                      <AvatarRound
                        :size="72"
                        :avatar="sweep.pointOfContact.profilePic"
                        :name="memberInitials(sweep.pointOfContact)"
                        @openPopup="showProducerPopup(sweep.pointOfContact)"
                      />
                      <div style="position: relative;margin-top: -20px;">
                        <img :src="getProfileIcon('Content Producer').icon" class="profile-icon" />
                      </div>
                    </div>
        <v-col class="mml-0 pl-0">
          <div v-if="creativeSweepTeam.length" class="creatives-heading">Creatives</div>
          <div v-if="creativeSweepTeam" class="d-flex flex-wrap creative-data" :key="sweep.sweepTeam.length">
                  <div
                    class="producer-contact-img2 mr-2 producer-img"
                    v-for="(member, i) in creativeSweepTeam"
                    :key="i"
                  >
                    <v-tooltip
                    v-if="member.accepted"
                    left bottom :disabled="!$vuetify.breakpoint.mdAndUp">
                      <template v-slot:activator="{ on, attrs }" class="ma-0">
                        <div v-bind="attrs" v-on="on">
                          <AvatarRound
                            :size="72"
                            :avatar="getMemberProfile(member)"
                            :name="memberInitials(member.creatives)"
                            @openPopup="showPopup(i, member.skill)"
                          />
                          <div style="position: relative;margin-top: -20px;">
                            <img :src="getProfileIcon(member.skill).icon" class="profile-icon2" />
                          </div>
                        </div>
                      </template>
                      <span class="notranslate"> {{ memberName(member) }}</span>
                    </v-tooltip>
                  </div>
                </div>
          </v-col>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-if="creative && !loading">
      <span class="a-link cursor-pointer" @click="$router.go(-1)">All Sweeps ></span>
      <v-col class="creative-details pl-5 mt-3">
        <v-row class="title mb-3">
          <v-col class="ml-3 pb-0 sweep-title">
            {{sweep.title}}
          </v-col>
          <div class="justify-end mr-8">
            <v-menu offset-y left content-class="admin-menu2">
              <template v-slot:activator="{ on, attrs }">
            <div
              color="info"
              dark
              v-bind="attrs"
              v-on="on"
            >
            <v-icon
            color="black">mdi-dots-horizontal</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              :key="'decline'"
              class="decline"
              @click="updateInvitation('0', true)"
            >
              <v-list-item-title
              style="color: red !important;">
              Decline Sweep</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
          </div>
        </v-row>
        <v-row class="pb-0 pl-2">
          <v-col style="max-width: 18px;" class="mr-5 pb-0">
          <Calender width="18" height="22"/>
          </v-col>
          <v-col class="font-body ml-3 cancel-div d-flex pb-0" v-if="isSweepCanceled">
                <span class="cancel-text">
                  {{date3(sweep.date)}}, {{time(sweep.fromTime)}} - {{time(sweep.toTime)}}
                </span>
                <span class="d-flex align-center text pl-4">
                  <span class="pr-2" style="color:#ff371b;"> Canceled </span>
                  <v-tooltip
                    bottom
                    content-class="affiliatedOrg-tooltip"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on"
                      style="width: 16px; height: 16px; cursor: pointer;">
                        <Info color="#FF371B" />
                      </div>
                    </template>
                    <span>{{cancelledReason}}</span>
                  </v-tooltip>
                </span>
          </v-col>
            <v-col class="font-body ml-3" v-else>
              <span>
                <span style="color: #ff371b;" v-if="isSweepLive && !isSweepCanceled">LIVE </span>
                {{date3(sweep.date)}}, {{time(sweep.fromTime)}} - {{time(sweep.toTime)}}
              </span>
            </v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col style="max-width: 50px;" class="pt-0 pb-0">
            <MapMarker width="19" height="20" class="ml-2 pa-0 pr-0" />
          </v-col>
              <v-col class="ml-4 pr-0 pt-0 pb-0">
              <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                -
              </div>
              <div v-else-if="sweep.sweepLocations">
                <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                  <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                    Location #{{ i + 1 }}
                  </div>
                  <div class="font-body">
                    <a class="mb-0 a-link" style="color: black;"
                    :href="`https://www.google.com/maps/place/${location.location}`"
                    target='_blank'
                  >
                  {{
                    `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
                  }}
                </a>
                  </div>
                </div>
              </div>
              </v-col>
        </v-row>
        <v-row class="pl-2 mt-1 d-flex no-wrap" v-if="sweep.sweepBusiness.length > 1">
          <Business width="18" height="18" class="ml-3"/>
          <v-col class="pa-0 ml-5 pr-0" style="max-width: fit-content;" v-for="(business, index) in sweep.sweepBusiness" :key="business.id">
              <p v-if="business.business.id !== sweep.business.id" style="width: fit-content;">
                {{`${index != 1 ? ', ': ''}${business.business.name}`}}
              </p>
          </v-col>
        </v-row>
        <v-row class="pt-1 pl-5 pb-2 no-wrap"
        v-if="sweep.documentUrl || sweep.videoUrl">
          <span>
          <Link width="18" height="18" />
          </span>
          <v-col class="mt-0 ml-4 pt-0 pb-0">
          <v-col class="pt-0" v-if="sweep.documentUrl">
            <v-col class="pa-0 locationCount">MILANOTE</v-col>
            <span class="a-link pa-0">
              <a class="overflow-wrap-anywhere" :href="generateUrl(sweep.documentUrl)">{{sweep.documentUrl}}</a>
            </span>
          </v-col>
          <v-col class="pt-0 pb-2" v-if="sweep.videoUrl">
            <v-col style="width: 100px;" class="pa-0 locationCount">LOOM VIDEO</v-col>
            <span class="a-link pa-0">
              <a class="overflow-wrap-anywhere" :href="generateUrl(sweep.videoUrl)">{{sweep.videoUrl}}</a>
            </span>
          </v-col>
          </v-col>
        </v-row>
        <v-row class="pl-5 mt-0 pt-0" v-if="sweep.notes">
          <span>
          <AlignLeft />
          </span>
          <v-col style="margin-left: 25px;" class="overflow-wrap-anywhere mt-0 pt-0">
            {{sweep.notes}}
          </v-col>
        </v-row>
      </v-col>
      <v-row class="timeline-team">
      <div v-if="sortedTimeLines.length > 0" class="mt-8 ml-5 timeline-div timelines">
        <v-col v-if="sortedTimeLines.length" class="timelinehd-div py-0 pl-0">
          <span class="timeline-heading">Timeline</span>
        </v-col>
        <v-expansion-panels
              class="mt-2 creatives-heading"
                  v-for="(item, i) in sortedTimeLines"
                  :key="i">
                  <Clock color="#aaaaaa" class="mr-3" width="18" height="18"/>
                  {{`${convertTime(item.durationInMinutes)}(s)`}}
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-col class="ml-2 sweepblock-heading">
                    {{item.sweepBlock.name}}
                    </v-col>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="sub-text expansion-panel-sub-text"
                  >
                  <v-row>
                  <v-col class="pt-0 pb-0" v-if="item.timelineEquipment.length">
                    <span class="ml-6 creatives-heading">Equipment</span>
                  <v-col class="pt-0 pb-0 timeline-data ml-3" v-for="get in item.timelineEquipment" :key="get">
                    {{get.timelineEquipmentItem.name}}
                  </v-col>
                  </v-col>
                  <v-col class="ml-5 pt-0 pb-0">
                    <span class="creatives-heading">Time</span>
                    <div class="timeline-data">{{`${convertTime(item.durationInMinutes)}s`}}</div>
                  </v-col>
                  <v-col class="pt-0 ml-3 pb-0" v-if="item.timelineShotSettings.length">
                    <span class="ml-3 creatives-heading">Shot Settings</span>
                  <v-col class="pb-0 pt-0 timeline-data" v-for="get in item.timelineShotSettings" :key="get">
                    {{get.timelineShotSettingItem.name}}
                  </v-col>
                  </v-col>
                  </v-row>
                  <v-col>
                  <v-col class="pt-0 pb-0">
                    <span class="pb-0 creatives-heading">DESCRIPTION</span>
                    <v-col class="pa-0 overflow-wrap-anywhere d-flex flex-wrap timeline-data">{{item.description}}</v-col>
                  </v-col>
                  </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
      <v-col class="timeline-hidden" v-else>
      </v-col>
      <v-col xxl="4"
          xl="4"
          lg="4"
          md="12"
          sm="12"
          xs="12" v-if="sweep.pointOfContact.firstName || creativeSweepTeam.length" class="mt-5 pl-12 timeline-div creatives-team">
        <v-col v-if="sweep.pointOfContact.firstName || creativeSweepTeam.length" class="timelinehd-div py-0 pl-0">
          <span class="timeline-heading">Team</span>
        </v-col>
        <div v-if="sweep.pointOfContact.firstName" class="mt-5 producer-contact-img">
        <div class="creatives-heading">CONTENT PRODUCER</div>
                      <AvatarRound
                        :size="72"
                        :avatar="sweep.pointOfContact.profilePic"
                        :name="memberInitials(sweep.pointOfContact)"
                        @openPopup="showProducerPopup(sweep.pointOfContact)"
                      />
                      <div style="position: relative;margin-top: -20px;">
                        <img :src="getProfileIcon('Content Producer').icon" class="profile-icon" />
                      </div>
                    </div>
        <v-col class="mml-0 pl-0">
          <div v-if="creativeSweepTeam.length" class="creatives-heading">Creatives</div>
          <div v-if="creativeSweepTeam" class="d-flex flex-wrap creative-data" :key="sweep.sweepTeam.length">
                  <div
                    class="producer-contact-img2 mr-2 producer-img"
                    v-for="(member, i) in creativeSweepTeam"
                    :key="i"
                  >
                    <v-tooltip
                    v-if="member.accepted"
                    left bottom :disabled="!$vuetify.breakpoint.mdAndUp">
                      <template v-slot:activator="{ on, attrs }" class="ma-0">
                        <div v-bind="attrs" v-on="on">
                          <AvatarRound
                            :size="72"
                            :avatar="getMemberProfile(member)"
                            :name="memberInitials(member.creatives)"
                            @openPopup="showPopup(i, member.skill)"
                          />
                          <div style="position: relative;margin-top: -20px;">
                            <img :src="getProfileIcon(member.skill).icon" class="profile-icon2" />
                          </div>
                        </div>
                      </template>
                      <span class="notranslate"> {{ memberName(member) }}</span>
                    </v-tooltip>
                  </div>
                </div>
          </v-col>
      </v-col>
    </v-row>
    </template>
    <div v-if="showOrderModal">
      <OrderForm
        :open="showOrderModal"
        @close="closeCreateOrderModal"
        :orderId="orderToEdit"
      />
    </div>
    <view-profile-modal
      :profilePic="profilePic(selectedMember, 'profilePic')"
      :imageBackground="checkProfile(selectedMember.profilePic)"
      :name="`${selectedMember.firstName || ''} ${selectedMember.lastName || ''}`"
      :skillset="skillset(skill)"
      :viewProfileModal="showDetails"
      :selectedMember="selectedMember"
      @setViewProfileModal="showDetails = false"
      @modalOpen="modalOpen = true"
      parent="creativeTeam"
    />
    <template v-if="showInvitationPopup" class="pa-0">
        <Modal :modal="showInvitationPopup" width="600" persistent>
        <InvitationDialog
            :invitationStatus="invitationStatus"
            :creative="this.userDetails"
            @submit-invitation="submitInvitationForm"
            @close-invitation-dialog="showInvitationPopup=false"
            :askForReason="askReason"
        >
        </InvitationDialog>
      </Modal>
    </template>
    <v-container v-if="cancelForm">
      <Modal
        persistent
        content-class="new-user-form"
        :modal="cancelForm"
        scrollable
      >
        <CancelForm @closeModal="cancelForm = false"
        @cancelled="cancelledSweep"
        :sweep="sweep" />
      </Modal>
    </v-container>
     <v-container
          fluid
          v-if="editForm"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="editForm"
            width="726px"
            height="1148px"
          >
          <SweepForm
            id="sweepModal"
            @close-modal="closeSweepModal"
            :sweepId="sweep.id"
            :sweepData="sweep"
          />
            <!-- @reload-sweeps="fetchSweeps" -->
            <!-- :sweepObj="currentSweep"
            :fallBackProjectId="currentProject.id" -->
          </Modal>
        </v-container>
     <v-container
          fluid
          v-if="reschedule"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="reschedule"
            width="726px"
            height="1148px"
          >
          <SweepForm
            id="sweepModal"
            :getInvites="this.getInvites"
            @close-modal="closeResheduleForm"
            :sweepId="sweep.id"
            @get-sweep="fetchSweep"
            :sweepData="sweep"
          />
            <!-- @reload-sweeps="fetchSweeps" -->
            <!-- :sweepObj="currentSweep"
            :fallBackProjectId="currentProject.id" -->
          </Modal>
        </v-container>
        <v-container
          fluid
          v-if="closeOutModal"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="closeOutModal"
            width="480px"
            height="899px"
          >
          <CloseOutForm
            id="sweepModal"
            @closemodal="closeOutModal = false"
            @reload-sweeps="fetchSweep"
            :sweep="this.selected"
          />
          </Modal>
        </v-container>
        <v-container
          fluid
          v-if="creativeMatching"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="creativeMatching"
            width="1200px"
            height="1500px"
          >
          <SweepTeamMatching
            :sweepId="sweep.id"
            :skillsets="sweepSkills"
            :selectedSkillId="currentSkillId"
            :currentSkillName="currentSkillName"
            :sweepTeam="currentSweepTeam"
            :sweepUserIds="currentSweepUserIds"
            @onCancelMatching="cancelMatching"
          >
          </SweepTeamMatching>
          </Modal>
        </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Info from '@/components/common/svg/Info';
import Loader from '@/components/common/Loader';
import { getOptimizedS3ImageURL, generateUrl } from '@/helpers/';
import businessesIcon from '@/assets/svg/businesses.svg';
import Business from '@/components/common/svg/Business';
import Link from '@/components/common/svg/Link';
import AlignLeft from '@/components/common/svg/AlignLeft';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import ViewProfileModal from '@/components/common/ViewProfileModal';
// import Profile from '@/components/profile/creative/ProfileView';
import Calender from '@/components/common/svg/Calender';
import Clock from '@/components/common/svg/Clock';
import MapMarker from '@/components/common/svg/MapMarker';
import DeleteSweep from '@/components/sweeps/DeleteSweep';
import Modal from '@/components/common/Modal';
import CancelForm from '@/components/sweeps/CancelForm';
import CloseOutForm from '@/components/sweeps/CloseOutForm';
import SweepForm from '@/components/projects/sweep/SweepForm';
import OrderForm from '@/components/orders/OrderForm';
import Overview from '@/components/sweeps/Overview';
import Timeline from '@/components/sweeps/Timeline';
import Conversation from '@/components/sweeps/Conversation';
import SweepTeamMatching from '@/components/projects/sweep/SweepTeamMatching';
import TeamList from '@/components/projects/sweep/TeamList';
import AvatarRound from '@/components/common/Avatar';
import Broom from '@/components/common/svg/Broom';
import Group from '@/components/common/svg/Group';

export default {
  name: 'Sweep_Details',
  components: {
    Info,
    Loader,
    Calender,
    Clock,
    MapMarker,
    DeleteSweep,
    Modal,
    Business,
    Link,
    AlignLeft,
    InvitationDialog,
    AvatarRound,
    ViewProfileModal,
    // Profile,
    CancelForm,
    SweepForm,
    OrderForm,
    Overview,
    Timeline,
    Conversation,
    CloseOutForm,
    SweepTeamMatching,
    TeamList,
    Broom,
    Group,
  },
  data() {
    return {
      showOrderModal: false,
      orderToEdit: {},
      modalOpen: false,
      showDetails: false,
      selectedMember: {},
      Conversation: [],
      invitationStatus: '',
      askReason: true,
      showInvitationPopup: false,
      getInvites: false,
      loading: true,
      sweep: {},
      businessInfo: {},
      cancelForm: false,
      editForm: false,
      reschedule: false,
      selected: [],
      closeOutModal: false,
      creativeMatching: false,
      currentSkillId: '',
      currentSkillName: '',
      lastUpdated: Date.now(),
      sweepSkills: [
        { id: '2', text: 'Videographer', value: 'Videographer' },
        { id: '1', text: 'Photographer', value: 'Photographer' },
        { id: '3', text: 'Talent', value: 'Talent' },
        { id: '4', text: 'Field Producer', value: 'Field Producer' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business']),
    ...mapGetters('sweepBlocks', ['getTimezones']),
    ...mapGetters('sweep', ['cancelReasons', 'timeLine']),
    sweepDeliverables() {
      const sweeps = this.sweep.orderDeliverable.filter((data) => data.deliverable.creativeType.name === 'Sweeps');
      return sweeps;
    },
    sortedTimeLines() {
      const { timeline } = this.sweep;
      const data = _.sortBy(timeline, 'priority');
      return data;
    },
    creativeSweepTeam() {
      // eslint-disable-next-line max-len
      const data = this.sweep.sweepTeam.filter((x) => x.accepted);
      return data;
    },
    memberName() {
      return (member) => {
        const firstName = _.get(member, 'firstName', '') || _.get(member.creatives, 'firstName', '');
        const lastName = _.get(member, 'lastName', '') || _.get(member.creatives, 'lastName', '');
        return `${firstName} ${lastName}`;
      };
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepTeam() {
      return (type = '1') => _.filter(this.sweep.sweepTeam, (sweep) => sweep.skill === type);
    },
    sweepSkillsets() {
      const skills = _.filter(this.skillsets, (skill) => _.map(this.sweepSkills, 'text').includes(skill.name));
      return skills;
    },
    keyInfo() {
      const { sweep } = this;
      const subset = _.pick(sweep, ['id', 'videoUrl', 'sweepResourceLinks', 'notes', 'documentUrl']);
      return subset;
    },
    currentSweepUserIds() {
      return _.map(_.filter(this.currentSweepTeam, ['deletedAt', null]), 'userId');
    },
    currentSweepTeam() {
      return _.get(this.sweep, 'sweepTeam', []);
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab || 'overview';
      },
    },
    getTimeLine() {
      return this.timeLine.timeline;
    },
    cancelledReason() {
      const { sweep, cancelReasons } = this;
      const { cancelReasonId } = sweep;
      const item = _.find(cancelReasons, (reason) => reason.id === cancelReasonId);
      let reason = _.get(item, 'reason', '');
      if (item.reason.toLowerCase() === 6) {
        reason = sweep.cancelDescription;
      }
      return reason;
    },
    getBusinessProfile() {
      return this.sweep.business.bannerImage || businessesIcon;
    },
    timeZone() {
      const { sweep } = this;
      const timeZone = _.find(this.getTimezones, (zone) => zone.id === sweep.timezone);
      return timeZone.timezone || '';
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    creative() {
      return this.role === 'creative';
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    editor() {
      return ['editor'].includes(this.role);
    },
    members() {
      return ['client', 'agency_owner'].includes(this.role);
    },
    clientAccess() {
      if (this.sweep.closedAt) {
        return false;
      }
      if (this.members) {
        if (this.sweep.businessId === _.get(this.userDetails, 'currentBusinessId')) {
          if (this.sweep.isClientCreated) {
            return true;
          }
        }
      }
      return false;
    },
    canAccess() {
      if (['editor'].includes(this.role)) {
        return false;
      }
      if (this.sweep.closedAt) {
        return false;
      }
      if (['admin', 'executive'].includes(this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    canAccess2() {
      if (this.sweep.closedAt) {
        return false;
      }
      if (['admin', 'executive', 'client', 'agency_owner', 'member', 'agency_member'].includes(this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    canAccess3() {
      if (['editor'].includes(this.role)) {
        return false;
      }
      if (this.sweep.closedAt) {
        return false;
      }
      if (['admin', 'executive', 'client', 'agency_owner', 'member', 'agency_member'].includes(this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    // canShow() {
    //   if (this.sweep.closedAt) {
    //     return false;
    //   }
    //   return true;
    // },
    isSweepCanceled() {
      return this.sweep.cancelledAt;
    },
    enableCloseout() {
      const { sweep } = this;
      const sweepDate = new Date(sweep.date);
      const currentDate = new Date();
      const endDate = new Date(currentDate.getTime());
      endDate.setHours(sweep.toTime.split(':')[0]);
      endDate.setMinutes(sweep.toTime.split(':')[1]);
      const valid = currentDate > endDate;
      if ((currentDate.getTime() >= sweepDate.getTime() && valid)
      && !sweep.closedAt && !sweep.cancelledAt) {
        return true;
      }
      if (this.isSweepLive && !sweep.cancelledAt) {
        return true;
      }
      return false;
    },
    isSweepLive() {
      const { sweep } = this;
      const date = moment().format('YYYY-MM-DD');
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime());
      startDate.setHours(sweep.fromTime.split(':')[0]);
      startDate.setMinutes(sweep.fromTime.split(':')[1]);
      const endDate = new Date(currentDate.getTime());
      endDate.setHours(sweep.toTime.split(':')[0]);
      endDate.setMinutes(sweep.toTime.split(':')[1]);
      const valid = startDate < currentDate && endDate > currentDate;
      if (date === sweep.date && valid) {
        return true;
      }
      return false;
    },
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `(${rminutes} minutes)` : ''}`;
        const checkForHours = `${rhours > 0 ? `(${rhours} hours)` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `(${rhours} hours and ${rminutes} minutes)` : checkForHours;
        return text;
      };
    },
    duration() {
      const { sweep } = this;
      const startTime = moment(sweep.fromTime, 'hh:mm a');
      const endTime = moment(sweep.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
  },
  methods: {
    ...mapActions('sweep', ['getSweep', 'removeSweep', 'updateInvitationById', 'getCancelReasons', 'getSweepTimeLine', 'getConversation']),
    ...mapActions('business', ['getBusiness']),
    ...mapActions('orders', ['getOrderInfo']),
    ...mapActions('sweepBlocks', ['setTimeZone']),
    moment,
    getOptimizedS3ImageURL,
    generateUrl,
    gotoBusiness(val) {
      if (!this.clients) {
        this.$router.push(`/project_overview/${val.projectId}?businessId=${val.businessId}`);
      }
    },
    async gotoOrder(val) {
      await this.getOrderInfo({
        orderId: val,
      });
      // find order from orders
      this.orderToEdit = val;
      this.showOrderModal = true;
    },
    gotoOrder2(val) {
      this.$router.push(`/orders/${val}?orderId=${val}`);
    },
    closeCreateOrderModal() {
      this.orderToEdit = {};
      this.showOrderModal = false;
    },
    convertTime(val) {
      const minutes = val;
      const hours = minutes / 60;
      return `${parseFloat(hours).toFixed(1)} hour`;
    },
    skillset(val) {
      switch (val) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        case '4':
          return 'Field Producer';
        default:
          return 'Content Producer';
      }
    },
    profilePic(member, version) {
      const profilePic = _.get(member, version);
      if (profilePic) {
        return profilePic;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/users.svg');
    },
    checkProfile(pic) {
      if (pic) {
        return false;
      }
      return true;
    },
    showPopup(index, val) {
      const member = this.creativeSweepTeam[index];
      this.selectedMember = member.creatives;
      this.skill = val;
      this.showDetails = true;
    },
    showProducerPopup(producer) {
      this.selectedMember = producer;
      this.showDetails = true;
    },
    getMemberProfile(member) {
      const pic = _.get(member.creatives || member, 'profilePic', null);
      return pic;
    },
    getSkillIcon(skill) {
      if (skill === '1' || skill === '2') {
        return {
          classType: 'photographer',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/camera-circle.svg'),
        };
      }
      if (skill === '3') {
        return {
          classType: 'talent',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/profile-circle.svg'),
        };
      }
      if (skill === '4') {
        return {
          classType: 'producer',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/video-circle.svg'),
        };
      }
      // if (skill.indexOf('Talent') >= 0) {
      //   return {
      //     classType: 'talent',
      //     // eslint-disable-next-line global-require
      //     icon: require('@/assets/svg/profile-circle.svg'),
      //   };
      // }
      return {
        classType: 'photographer',
        // eslint-disable-next-line global-require
        icon: require('@/assets/svg/camera-circle.svg'),
      };
    },
    getProfileIcon(role) {
      switch (role) {
        case 'Content Producer':
        case 'Production Manager':
          return {
            classType: 'producer',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/video-circle.svg'),
          };
        case 'editor':
          return {
            classType: 'editor',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/edit-circle.svg'),
          };
        default:
          return this.getSkillIcon(role);
      }
    },
    updateInvitation(status, needReason) {
      this.invitationStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    async submitInvitationForm(reasonId) {
      this.loading = true;
      const result = await this.updateInvitationById({
        reasonId,
        status: this.invitationStatus,
        sweepId: this.sweep.id,
      });
      if (result.success) {
        this.showInvitationPopup = false;
        this.$router.go(-1);
      }
    },
    date3() {
      return moment(this.sweep.date).format('MMMM Do, YYYY');
    },
    matchedTeam(type) {
      return _.filter(this.sweep.sweepTeam, (sweep) => (
        (sweep.accepted || sweep.invitationSentAt) && sweep.skill === type && !sweep.deletedAt));
    },
    cancelMatching() {
      this.creativeMatching = false;
      this.fetchSweep();
      this.lastUpdated = Date.now();
    },
    async closeSweepModal() {
      this.editForm = false;
      this.lastUpdated = Date.now();
      await this.fetchSweep();
    },
    openCreativeForm(skill) {
      this.currentSkillId = _.find(this.sweepSkills, ['text', skill]).id;
      this.currentSkillName = skill;
      this.creativeMatching = true;
    },
    cancelledSweep() {
      this.fetchSweep();
    },
    openCancelForm() {
      this.cancelForm = true;
    },
    openCloseout(val) {
      this.selected = val;
      this.closeOutModal = true;
    },
    openEditForm() {
      this.getInvites = false;
      this.editForm = true;
    },
    async closeResheduleForm() {
      this.reschedule = false;
    },
    // getData() {
    //   this.sweep.businessIds = [];
    //   this.sweep.creatives = [{
    //     creative: 'Photographer',
    //     quantity: '1',
    //     payout: '',
    //   }];
    // },
    // openResheduleForm() {
    //   this.reschedule = true;
    //   this.getInvites = true;
    //   // this.getData();
    // },
    async fetchSweep() {
      if (!this.creative) {
        this.Conversation = await this.getConversation(this.$route.params.sweepId);
      }
      this.sweep = await this.getSweep(this.$route.params.sweepId);
      this.sweep.sweepLocations = _.orderBy(this.sweep.sweepLocations, 'id', 'asc');
      this.businessInfo = _.get(this.sweep, 'business', {});
      this.loading = false;
    },
    async openDeleteForm() {
      const confirmModal = this.$refs.DeleteSweepModal;
      const { confirm } = await confirmModal.open();
      if (!confirm) {
        return false;
      }
      const result = await this.removeSweep(this.sweep.id);
      if (result.success) {
        this.$router.go(-1);
      }
      return false;
    },
  },
  async mounted() {
    this.fetchSweep();
    this.setTimeZone();
    this.getCancelReasons();
    if (!this.creative) {
      this.getSweepTimeLine(this.$route.params.sweepId);
      await this.getBusiness(this.$route.params.businessId);
    }
  },
};
</script>

<!-- eslint-disable max-len -->
<style lang="scss" scoped>
.order-button {
  cursor: pointer;
  &:hover {
    color: $primary1;
  }
}
.actions {
  margin-top: 40px;
  .v-list {
    padding: 0;
    .v-list-item {
      min-height: auto;
      padding-top: 6px;
      padding-bottom: 6px;
      &:first-child {
        padding-top: 14px;
      }
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
  .delete,
  .closeOut {
    padding-top: 12px !important;
    border-top: 1px solid $border2;
  }
  .delete .v-list-item__title {
    color: $colorRed1;
  }
  .closeOut .v-list-item__title {
    color: $secondary1;
  }
  .v-list-item__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.sweepDetails-container {
  ::v-deep .v-tabs .v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev{
    display: none !important;
  }
  .locationCount {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #929292;
    }
  .sweepDetails {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 13px 16px;
    .duration {
      font-weight: 400;
      color: #adadad;
      text-transform: lowercase;
    }
    .add-location {
      span {
        padding-right: 34px;
      }
    }
    .cancel-div {
      .cancel-text {
        text-decoration-line: line-through;
      }
      .text {
        color: $colorRed1;
      }
    }
    .text-live {
      color: $colorRed1;
    }
  }
  .businessDetails {
    background: #f9f9f9;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 18px 15px;
    .businessName {
      padding-bottom: 27px;
    }
    .label {
      color: $graySuite2;
    }
  }
  .creative-heading {
font-family: $fontFamily1;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
color: #929292;
padding-bottom: 6px;
margin: 20px 0px;
border-bottom: 1px solid #DEDEDE;
  }
  .creativesDetails {
    background: #f9f9f9;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 5px 12px;
    margin-bottom: 12px;
    min-height: 150px;
    .creativeName {
      font-family: $fontFamily1;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #262626;
padding-left: 0px;
    padding-right: 0px;
    }
    .label {
      color: $graySuite2;
    }
  }
  .type-data {
    border-bottom: 1px solid #DEDEDE;
    margin-left: 0px;
    margin-right: 0px;
  }
  .add-creative {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $primary1;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .cursor {
    cursor: pointer;
  }
  .sweep-container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .creative-details {
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    .admin-menu2 {
      height: 80px !important;
    }
    .cancel-text {
      text-decoration-line: line-through;
    }
    .title {
      font-weight: 400;
      font-size: 30px !important;
      line-height: 45px;
    }
    .timeline-hidden {
    display: block;
    }
  }
  .timelines {
    width: 60% !important;
  }
  @media (max-width: 600px) and (min-width: 0px) {
    .sweep-title {
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .timelines {
      width: 100% !important;
      margin-left: 0px !important;
    }
    .timeline-team {
      width: 100%;
      .timeline-div {
        width: 100%;
        padding-left: 15px !important;
      }
      .timeline-hidden {
        display: none;
      }
    }
  }
  .timeline-div {
    .sweepblock-heading {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: #262626;
    }
    .v-expansion-panels {
      justify-content: left;
    }
    .v-expansion-panel {
        background: none;
        justify-content: left !important;
        border: 1px solid #D8D8D8;
        border-radius: 10px;
        margin-bottom: 10px;
        &::after {
          margin-top: 0px !important;
          border-top: none !important;
        }
        &::before {
          box-shadow: none !important;
        }
      }
      .v-expansion-panel-header {
        padding: 0px !important;
        min-height: auto;
      }
      ::v-deep .v-expansion-panel--active:not(:first-child) {
        margin-top: 0px !important;
      }
      ::v-deep .v-icon.v-icon {
        color: black !important;
        font-size: 26px !important;
      }
      ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0 15px 0 !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
    .timeline-data {
      text-transform: capitalize;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .timelinehd-div {
      border-bottom: 1px solid #dedede;
    }
    .creatives-heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: #aaaaaa;
    }
   .timeline-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #929292;
   }
  }
}
</style>
