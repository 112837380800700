<!-- eslint-disable max-len -->
<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9085 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.400371 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64497 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.2258C19.6096 4.65929 19.9272 3.8916 19.9272 3.09121C19.9272 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00939 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13705C17.9893 2.2629 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'EditSvg',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '20',
    },
    height: {
      type: String,
      default: '20',
    },
  },
};
</script>
