<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3828 8.13395C16.0495 8.51885 16.0495 9.4811 15.3828 9.866L1.70634 17.7621C1.03967 18.147 0.20634 17.6659 0.20634 16.8961L0.206341 1.10385C0.206341 0.334054 1.03967 -0.147069 1.70634 0.237831L15.3828 8.13395Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
