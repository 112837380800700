<template>
  <div>
    <v-row class="music-control pa-2" @click.stop="toggleAudio">
      <div
        class="music-control-btn-wrapper"
        :class="{'pause' : isPlaying}"
      >
        <playbutton v-if="!isPlaying" color="#ababab" class="cursor-pointer ml-5 mt-4"/>
        <pausebutton color="#ababab" v-if="isPlaying" class="cursor-pointer ml-3 mt-3"/>
      </div>
      <input
        class="rangeinput mt-6"
        type="range"
        :min="0"
        :max="Math.round(myduration)"
        v-model="mycurrentTime"
        @input="updateTime"
      >
      <span class="mt-4 ml-1">
        {{ secondsToHms(mycurrentTime) }} / {{ secondsToHms(myduration) }}
      </span>
    </v-row>
      <audio :key="reply" :src="reply" hidden controls></audio>
  </div>
</template>

<script>
import playbutton from '@/components/common/svg/playbutton';
import pausebutton from '@/components/common/svg/pausebutton';

export default {
  components: {
    playbutton,
    pausebutton,
  },
  props: {
    messageid: {
      type: Number,
    },
    reply: {
      type: String,
    },
    activeMusicId: {
      type: Number,
    },
  },
  data() {
    return {
      myduration: 0,
      mycurrentTime: 0,
      duration: '0:00',
      isPlaying: false,
      player: null,
    };
  },
  watch: {
    activeMusicId(newId) {
      if (newId !== this.messageid) {
        this.pauseAudio();
      }
    },
  },
  async mounted() {
    this.player = new Audio(this.reply);
    this.player.addEventListener('timeupdate', this.timeupdate, false);
    this.player.addEventListener('ended', () => {
      this.pauseAudio();
    });
  },
  methods: {
    timeupdate() {
      if (this.player.duration !== Infinity) {
        this.myduration = this.player.duration;
      }
      this.mycurrentTime = this.player.currentTime;
      const hr = Math.floor(this.mycurrentTime / 3600);
      const min = Math.floor((this.mycurrentTime - (hr * 3600)) / 60);
      const sec = Math.floor(this.mycurrentTime - (hr * 3600) - (min * 60));
      this.timeLabel = `${hr.toString()
        .padStart(2, '0')}:${min.toString()
        .padStart(2, '0')}:${sec.toString()
        .padStart(2, '0')}`;
    },
    updateTime() {
      this.player.currentTime = this.mycurrentTime;
    },
    playAudio() {
      this.isPlaying = true;
      this.player.play();
      this.$emit('play', this.messageid);
    },
    pauseAudio() {
      this.isPlaying = false;
      this.player.pause();
    },
    secondsToHms(seconds) {
      if (seconds === '') return '';
      if (Number.isNaN(seconds)) return '';
      const d = Number(seconds);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const formattedDuration = `${h > 0 ? h : ''}${h > 0 ? ':' : ''}`
        + `${m}:`
        // eslint-disable-next-line prefer-template
        + `${s > 9 ? s : '0' + s}`;
      return formattedDuration;
    },
    toggleAudio() {
      if (this.player.paused) {
        this.playAudio();
        this.player.play();
      } else {
        this.pauseAudio();
        this.player.pause();
      }
      // console.log(this.secondsToHms(this.myduration), this.mycurrentTime);
      // eslint-disable-next-line
    },
  },
};
</script>

<style lang="scss" scoped>
.music-control {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  .rangeinput {
    width: 67%;
    outline: none;
  }
  input[type="range"] {
    accent-color: #8066ff;
    height: 7px;
  }
  .music-control-btn-wrapper {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    background-color: #ededed;
  }
}
</style>
