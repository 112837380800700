import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sweep-cancel-form"},[_c(VContainer,{staticClass:"pa-0"},[_c(VRow,{staticClass:"ma-auto title-header"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text modal-header-title"},[_vm._v("Cancel Sweep")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"scollable-div"},[_c(VRow,{staticClass:"ma-0"},[_c('p',{staticClass:"font-body"},[_vm._v(" You will be able to update the status of this sweep in the future. ")]),_c(VCol,{staticClass:"pa-0 mt-2",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-left font-label"},[_vm._v("Reason for canceling")]),_c(VSelect,{staticClass:"type-selection mb-n3 notranslate",attrs:{"solo":"","flat":"","name":"reason","items":_vm.cancelReasons,"item-text":"reason","item-value":"id","menu-props":{ 'content-class': 'notranslate' },"placeholder":"Select...","append-icon":"keyboard_arrow_down","status":_vm.errors('reason').length ? 'error': 'normal',"error-messages":_vm.errors('reason')},on:{"change":_vm.setReason,"input":function($event){return _vm.$v.reason.$touch()},"blur":function($event){return _vm.$v.reason.$touch()}}}),(_vm.otherField)?_c('BaseTextAreaField',{staticClass:"otherReason",class:{
              'errorState v-input--has-state error--text': _vm.otherErr,
            },attrs:{"hide-details":"","placeholder":"Why was this sweep canceled?","rows":1,"name":"otherReason","dense":"","solo":""},on:{"blur":_vm.checkForOther,"input":_vm.checkForOther},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"otherReason"}}):_vm._e(),(_vm.otherErr)?_c('p',{staticClass:"mb-0",class:{
              'errorState v-input--has-state error--text': _vm.otherErr,
            }},[_vm._v("Please provide reason")]):_vm._e()],1),_c('div',{staticClass:"d-flex mt-2"},[_c(VCheckbox,{staticClass:"notify",model:{value:(_vm.notifyUsers),callback:function ($$v) {_vm.notifyUsers=$$v},expression:"notifyUsers"}}),_c('span',{staticClass:"font-body-small pt-1"},[_vm._v(" Notify all participants of the cancelled sweep. ")])],1)],1),_c('span',[(_vm.getAlert(_vm.sweep.date, _vm.sweep.fromTime))?_c(VCol,{staticStyle:{"color":"red","border":"1px solid black","border-radius":"3px"}},[_vm._v(" Note: Are your sure, you want to cancel the sweep, if cancelled 6 credits will be debited within 48 hours of sweep. ")]):_vm._e()],1)],1),_c(VCardActions,{staticClass:"justify-end pa-5"},[_c(VBtn,{staticClass:"py-0 cancel",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"py-0 submit btn-purple",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.$v.$dirty && _vm.$v.$invalid},on:{"click":_vm.submitForm}},[_vm._v(" Continue ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }