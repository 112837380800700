<template>
  <v-list-item
    :class="{ draggable: showDragIcon }"
    class="member-content"
  >
    <v-icon v-if="showDragIcon">
      mdi-drag
    </v-icon>
    <div v-bind:class="showInvite ? 'item-avatar-invite' : 'item-avatar'">
      <img
        v-if="creative.profilePic"
        v-bind:class="showInvite ? 'item-avatar-invite' : 'item-avatar'"
        :src="creative.profilePic"
      />
      <div
        v-if="!creative.profilePic"
        class="name-initial d-flex align-center justify-center"
        style="width: inherit; height: inherit; font-size: 16px;"
      >
        {{ memberInitials(name) }}
      </div>
    </div>
    <v-list-item-content class="d-flex ml-1 justify-space-between">
      <div class="name notranslate">{{ name }}</div>
      <div style="color:#09BA51;" v-if="showInvite && user.accepted">
        Invite Accepted
      </div>
      <div
        v-if="showInvite && !user.accepted"
        class="inviteStauts"
        style="color:#CFA936;"
      >
        <div
        color="info"
        dark
        v-bind="attrs"
        v-on="on"
        >
          Invite sent {{ user.invitationSentAt | getDate }}
        </div>
      </div>
    </v-list-item-content>

    <v-list-item-action v-if="canAccess && showInvite">
      <span v-if="showInvite" class="warning--text font-weight-bold float-left">
        <v-menu offset-y left content-class="admin-menu">
          <template v-if="user.accepted" v-slot:activator="{ on, attrs }">
            <div
              color="info"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <span>
              <img :src="clockIconCheck" width="16" class="event-icon" />
            </span>
              <v-icon color="#09BA51" style="padding-bottom: 10px; padding-left: 2px;" size="20">
                mdi-menu-down
              </v-icon>
            </div>
          </template>
          <template v-else v-slot:activator="{ on, attrs }">
            <div
              color="info"
              dark
              v-bind="attrs"
              v-on="on"
            >
            <span>
              <img :src="clockIcon" width="16" class="event-icon" />
            </span>
              <v-icon style="padding-bottom: 10px; padding-left: 2px;" color="#8066FF" size="20">
                mdi-menu-down
              </v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-if="!user.accepted"
              :key="'accept'"
              @click="$emit('acceptItem', creative.id)"
            >
              <v-list-item-title>
                Accept
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="'decline'"
              @click="$emit('removeItem', creative.id)"
            >
              <v-list-item-title>Decline</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-list-item-action>
    <v-list-item-action v-if="declined">
      <span class="warning--text font-weight-bold float-left resendoption">
        <v-menu offset-y left content-class="admin-menu">
          <template v-slot:activator="{ on, attrs }">
            <div
              color="info"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <span>
              <img :src="clockIcon" width="16" class="event-icon" />
            </span>
              <v-icon style="padding-bottom: 10px; padding-left: 2px;" color="#8066FF" size="20">
                mdi-menu-down
              </v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              :key="'resend'"
              @click="$emit('resendItem')"
            >
              <v-list-item-title>Resend</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-list-item-action>
    <v-list-item-action v-if="declined">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div class="pt-1" v-bind="attrs" v-on="on">
            <Info />
          </div>
        </template>
        <span>{{ user.sweepReasons && user.sweepReasons.name }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Info from '@/components/common/svg/Info';

export default {
  components: {
    Info,
  },
  props: {
    creative: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
    },
    canAccess: {
      type: Boolean,
      default: false,
    },
    declined: {
      type: Boolean,
      default: false,
    },
    showInvite: {
      type: Boolean,
      default: false,
    },
    showDragIcon: {
      type: Boolean,
      default: false,
    },
    tierName: {
      type: String,
      default: null,
    },
  },
  filters: {
    getDate(datetime) {
      const yesterday = moment().subtract(1, 'days');
      if (moment(datetime).isSame(yesterday, 'd')) {
        moment.locale('yourlang', {
          calendar: {
            lastDay() {
              return '[Yesterday]';
            },
          },
        });
        return moment(datetime).calendar();
      }
      return moment(datetime).short();
    },
  },
  computed: {
    clockIcon() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/invite_clock.svg');
    },
    clockIconCheck() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/calendar-check.svg');
    },
    memberInitials() {
      return (memberName) => {
        let name = memberName;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    getcolor(type) {
      if (type) {
        return '#09BA51';
      }
      return '#8066FF';
    },
    availabilityCheck() {
      const indication = this.creative.availability;
      if (indication === '1') {
        return 'green !important';
      }
      return 'red !important';
    },
    profilePic() {
      const profilePic = _.get(
        this.creative,
        'thumbnail',
        'smallImage',
        'profilePic',
      );
      if (profilePic) {
        return profilePic;
      }
      // eslint-disable-next-line global-require
      return null;
    },
    name() {
      return `${this.creative.firstName} ${this.creative.lastName}`;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-badge--dot .v-badge__badge::after {
  border-width: 3px;
}
.profile {
  width: 40px;
  height: 40px;
}
::v-deep .v-badge--dot .v-badge__badge {
  height: 13px;
  width: 13px;
  inset: calc(100% - 16px) auto auto calc(100% - 15px) !important;
  border-radius: 11px;
}
.resendoption {
  margin-top: 6px;
  margin-left: 10px;
}
.member-content {
  &.draggable {
    cursor: move;
  }
  .name {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    white-space: nowrap;
    overflow: hidden;
    width: 190px;
    text-overflow: ellipsis;
  }
}
.tooltip {
  border: 1px solid #262626;
  background-color: transparent;
}
.inviteStauts {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.item-avatar {
  margin-right: 10px;
  background-color: #c0c0c0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
.item-avatar-invite {
  margin-right: 10px;
  background-color: #c0c0c0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
