<template>
    <div>
      <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title"> Credits </span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="pa-5">
            <label>How Many Credits do you need?</label>
            <!-- <label
              v-if="!updateCredits.length"
              style="color: red;"
              >Please add required credits</label> -->
            <v-col class="pa-0 pt-1" cols="4">
            <v-text-field
                class="type-selection"
                outlined
                dense
                name="payout"
                @change="changeCredits"
                @input="changeCredits"
                :value="credits.credits"
                min="1"
                oninput=
                    "validity.valid || (value = value.slice(0, value.length-2))"
                type="number"
            ></v-text-field></v-col>
        </div>
        <v-col style="border-top: 1px solid #e0e0e0;" class="buttons">
                <div class="d-flex justify-end">
                <v-btn
                class="closeout ml-2 btn-purple"
                type="submit"
                :disabled="!updateCredits.length"
                @click="submitForm"
                >Submit</v-btn>
                </div>
            </v-col>
    </div>
</template>

<script>
export default {
  props: {
    credits: {
      type: Number,
    },
  },
  data() {
    return {
      updateCredits: '',
    };
  },
  methods: {
    changeCredits(val) {
      this.updateCredits = val;
    },
    async submitForm() {
      this.credits.credits = Number(this.updateCredits);
      this.credits.price = this.updateCredits * this.credits.perCredit;
      this.$emit('closemodal');
    },
  },
};
</script>
