<!-- eslint-disable max-len -->
<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.875 18H4.125C3.03135 17.9988 1.98283 17.5638 1.2095 16.7905C0.436171 16.0172 0.00119113 14.9687 0 13.875L0 4.125C0.00119113 3.03135 0.436171 1.98283 1.2095 1.2095C1.98283 0.436171 3.03135 0.00119113 4.125 0L13.875 0C14.9687 0.00119113 16.0172 0.436171 16.7905 1.2095C17.5638 1.98283 17.9988 3.03135 18 4.125V13.875C17.9988 14.9687 17.5638 16.0172 16.7905 16.7905C16.0172 17.5638 14.9687 17.9988 13.875 18ZM4.125 2.25C3.62772 2.25 3.15081 2.44754 2.79917 2.79917C2.44754 3.15081 2.25 3.62772 2.25 4.125V13.875C2.25 14.3723 2.44754 14.8492 2.79917 15.2008C3.15081 15.5525 3.62772 15.75 4.125 15.75H13.875C14.3723 15.75 14.8492 15.5525 15.2008 15.2008C15.5525 14.8492 15.75 14.3723 15.75 13.875V4.125C15.75 3.62772 15.5525 3.15081 15.2008 2.79917C14.8492 2.44754 14.3723 2.25 13.875 2.25H4.125ZM5.994 7.00425V10.9958C5.994 11.1714 6.04013 11.3439 6.12776 11.4961C6.21539 11.6483 6.34145 11.7748 6.49333 11.8629C6.64521 11.9511 6.81758 11.9978 6.99319 11.9985C7.1688 11.9991 7.3415 11.9536 7.494 11.8665L11.4893 9.87075C11.6428 9.78306 11.7704 9.65633 11.8592 9.50341C11.948 9.35049 11.9947 9.17682 11.9947 9C11.9947 8.82318 11.948 8.64951 11.8592 8.49659C11.7704 8.34367 11.6428 8.21694 11.4893 8.12925L7.494 6.1335C7.3415 6.04642 7.1688 6.00091 6.99319 6.00153C6.81758 6.00216 6.64521 6.04889 6.49333 6.13705C6.34145 6.22522 6.21539 6.35173 6.12776 6.50391C6.04013 6.6561 5.994 6.82864 5.994 7.00425Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'Info',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
  },
};
</script>
