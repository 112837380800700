<!-- eslint-disable -->
<template>
    <div class="reorder-container">
      <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title"> Reorder Sweep Blocks </span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div>
        <v-col class="d-flex no-wrap">
        <draggable
          v-model="timeline"
          class="d-flex flex-wrap"
          group="people"
          @start="drag=true"
          @end="drag=false">
          <v-col class="d-flex flex-wrap mt-2"
          >
          <span class="col text-right" v-for="(item, index) in timeline" :key="index">
          {{eventTime(index)}}
          </span>
          </v-col>
        </draggable>
        <draggable
          v-model="timeline"
          class="mt-5 mb-5 d-flex flex-wrap"
          group="people"
          @start="drag=true"
          @end="drag=false">
          <v-list-item
            v-for="creative in timeline"
            :key="creative"
            class="mr-5 my-1 drop-data">
            <Grid width="18" height="15" color="#929292"></Grid>
                <!-- eslint-disable-next-line max-len -->
                <v-list-item-content>
                <v-col class="d-flex no-wrap data-items ml-1">
                    <span class="pt-0 pb-0 pl-0 ml-0 col duration-time">
                    {{`${creative.sweepBlock.name} (${creative.durationInMinutes} mins)`}}
                    </span>
                </v-col>
                </v-list-item-content>
          </v-list-item>
        </draggable>
        </v-col>
        <v-col class="buttons">
            <div class="d-flex justify-end">
              <button
                class="cancel cursor-pointer mr-5"
                @click="$emit('closemodal')">Cancel
              </button>
                <v-btn
                class="reorder ml-2 btn-purple"
                type="submit"
                :loading="loading"
                @click="submitForm"
                >save</v-btn>
                </div>
            </v-col>
        </div>
    </div>
</template>

<script>
// import _ from 'lodash';
import { mapActions } from 'vuex';
import moment from 'moment';
import draggable from 'vuedraggable';
import Grid from '@/components/common/svg/Grid';

export default {
  components: {
    draggable,
    Grid,
  },
  props: {
    sweep: {
      type: Object,
      requried: true,
    },
    timeline: {
      type: Array,
      requried: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('sweep', ['reOrderSweepTimeline']),
    eventTime(index) {
      if (index === 0) {
        return moment(this.sweep.fromTime, ['HH:mm']).format('h:mma');
      }
      const timeLine = this.timeline.slice(0, index);
      const mins = timeLine.reduce((a, b) => a + b.durationInMinutes, 0);
      return moment((this.sweep.fromTime), ['HH:mm']).add(mins, 'minutes').format('h:mma');
    },
    async submitForm() {
      this.loading = true;
      const { id } = this.sweep;
      const data = {
        sweepId: id,
        timelines: this.timeline.map((x, index) => ({
          id: x.id,
          priority: index + 1,
        })),
      };
      const result = await this.reOrderSweepTimeline(data);
      if (result) {
        this.$emit('closemodal');
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.reorder-container {
  .drop-data {
    min-height: 40px;
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    ::v-deep.v-list-item__content {
      padding: 0px !important;
      min-height: 10px !important;
    }
    .duration-time {
      height: 19px;
      flex-basis: auto;
    }
  }
  .data-items {
    color: #262626;
    min-width: fit-content;
    font-weight: 500;
    font-size: 16px;
    padding: 0px;
  }
  .model-title {
    color: #929292;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    font-family: 'Poppins';
  }
}
</style>
