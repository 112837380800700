<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4167 9.16665H5.41667C4.72667 9.16665 4.16667 9.72581 4.16667 10.4166V14.5833C4.16667 15.2741 4.72667 15.8333 5.41667 15.8333H10.4167C11.1067 15.8333 11.6667 15.2741 11.6667 14.5833V10.4166C11.6667 9.72581 11.1067 9.16665 10.4167 9.16665ZM9.16667 13.3333H6.66667V11.6666H9.16667V13.3333ZM18.835 7.30748C18.1167 6.94748 17.2717 7.02415 16.6283 7.50581L15.6342 8.25081C15.0775 6.42165 13.4042 5.07831 11.4125 5.00748L8.46583 1.68748C7.915 1.13665 7.1825 0.833313 6.40333 0.833313H1.25C0.56 0.833313 0 1.39248 0 2.08331C0 2.77415 0.56 3.33331 1.25 3.33331H6.40417C6.51417 3.33331 6.62083 3.37748 6.6475 3.40081L8.06583 4.99998H4.58333C2.05667 4.99998 0 7.05581 0 9.58331V15.4166C0 17.9441 2.05667 20 4.58333 20H11.25C13.3133 20 15.0625 18.6283 15.6342 16.7483L16.6283 17.4941C17.0025 17.7741 17.4442 17.9175 17.8908 17.9175C18.2125 17.9175 18.5342 17.8433 18.8358 17.6925C19.5542 17.3333 20 16.6116 20 15.8083V9.19248C20 8.38915 19.5542 7.66665 18.8358 7.30831L18.835 7.30748ZM13.3333 15.4166C13.3333 16.565 12.3992 17.5 11.25 17.5H4.58333C3.43417 17.5 2.5 16.565 2.5 15.4166V9.58331C2.5 8.43498 3.43417 7.49998 4.58333 7.49998H11.25C12.3992 7.49998 13.3333 8.43498 13.3333 9.58331V15.4166Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'VideoFilled',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '20',
    },
    height: {
      type: String,
      default: '20',
    },
  },
};
</script>
