<!-- eslint-disable max-len -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="5" width="3" height="14" rx="1.5" :fill="color"/>
    <rect x="14" y="5" width="3" height="14" rx="1.5" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
