<template>
  <v-card-text class="team pa-0">
    <v-list subheader class="sweepTeam px-0">
      <div v-if="matchedTeam.length">
        <div
          v-for="creative in matchedTeam"
          :key="creative.id"
          class="d-flex align-center"
        >
          <div>
            <SweepMemberCard
              :creative="creative.creatives"
              :tierName="tierDetails(creative.creativePayRate)"
              @removeItem="updateInvitation(creative.id, '2', creative)"
              @acceptItem="updateInvitation(creative.id, '1', creative, false)"
              :canAccess="canAccess"
              :user="creative"
              :showInvite="isInvited(creative)"
            />
          </div>
        </div>
      </div>
      <div else>
      <v-card-actions v-if="!matchedTeam.length && canAccess">
        <v-btn text color="info"
          @click="$emit('onAddClick')"
        > + Add {{skillType}} </v-btn>
      </v-card-actions>
      </div>
      <div class="expand" @click="showExpand= true"
      v-if="(queue.length || removed.length) && !showExpand" >
       <v-icon color="#8066FF">
       mdi-chevron-down
       </v-icon>
      </div>
      <div v-if="showExpand">

      <template v-if="queue.length">
      <span
                class="d-flex no-wrap add-padding">
                  <span class="creative-heading">{{hasEnoughTeam ? 'Substitutes' : 'Queued'}}</span>
                <p class="line mb-0 mb-3 pt-0 ml-2"></p>
                </span>
        <div
          v-for="creative in queue"
          :key="creative.id"
          class="d-flex align-center"
        >
          <div>
            <SweepMemberCard
              :creative="creative.creatives"
              :tierName="tierDetails(creative.creativePayRate)"
              @removeItem="updateInvitation(creative.id, '2')"
              :user="creative"
              :canAccess="canAccess"
            />
          </div>
        </div>
      </template>
        <template v-if="removed.length">
        <span
                class="d-flex no-wrap add-padding">
                  <span class="creative-heading">Declined</span>
                <p class="line mb-0 mb-3 pt-0 ml-2"></p>
                </span>
        <div
          v-for="creative in removed"
          :key="creative.id"
          class="d-flex align-center"
        >
          <div>
            <SweepMemberCard
              :creative="creative.creatives"
              :tierName="tierDetails(creative.creativePayRate)"
              declined
              @removeItem="updateInvitation(creative.id, '2')"
              @resendItem="resendInvitation(creative.userId, creative.skill)"
              :user="creative"
              :canAccess="canAccess"
            />
          </div>
        </div>
      </template>
      <div class="expand" @click="showExpand= false">
       <v-icon color="#8066FF">
       mdi-chevron-up
       </v-icon>
      </div>
      </div>
    </v-list>
    <template
      v-if="showInvitationPopup"
      class="pa-0"
    >
      <Modal
        :modal="showInvitationPopup"
        width="600"
        persistent
      >
        <InvitationDialog
          :invitationStatus="invitationStatus"
          @submit-invitation="submitForm"
          @close-invitation-dialog="showInvitationPopup=false"
          :askForReason="this.askReason"
          :loading="loading"
          :creative="creative"
        >
        </InvitationDialog>
      </Modal>
    </template>
    <template v-if="showConfirm">
      <v-dialog
        v-model="showConfirm"
        max-width="680"
      ><v-card class='overflow-hidden' flat>
        <div class="d-flex justify-end">
            <v-icon class="pa-4 pb-0 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
        </div>
        <v-card-text  class="pb-1 black--text font-family-2 card-text">
        Creative selected is in Inactive status. Accepting this invite will update the status
        of creative to Active and accept the sweep invite. Please confirm.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text color="primaryGray1"
            @click="showConfirm = false"
            class="py-0"
          >
            Cancel
          </v-btn>
            <v-btn text color="info"
            @click="updateUserStatusApi()"
            class="py-0"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-shortformat';
import SweepMemberCard from '@/components/common/SweepMemberCard';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import Modal from '@/components/common/Modal';
import EventBus from '@/helpers/event-bus';


export default {
  props: {
    creatives: {
      type: Array,
      default: () => [],
    },
    maxCreatives: {
      type: Number,
      required: true,
    },
    creativeType: {
      type: String,
      required: true,
    },
    canAccess: {
      type: Boolean,
      default: false,
    },
    sweepId: {
      type: Number,
      required: true,
    },
  },

  components: {
    InvitationDialog,
    Modal,
    SweepMemberCard,
  },

  computed: {
    ...mapGetters(['sweepInvitationReasons']),
    skillType() {
      switch (this.creativeType) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        default:
          return 'Field Producer';
      }
    },
    active() {
      return _.filter(this.creatives, ['deletedAt', null]);
    },
    accepted() {
      // eslint-disable-next-line
      return _.filter(this.active, (sweep) => (sweep.accepted == true && sweep.deletedAt == null && sweep.declinedAt == null ));
    },
    matchedTeam() {
      // eslint-disable-next-line
      return _.filter(this.active, (sweep) => ((sweep.accepted == true || sweep.invitationSentAt) && (sweep.deletedAt == null && sweep.declinedAt == null)));
    },
    queue() {
      return _.filter(this.active, ['invitationSentAt', null]);
    },
    declined() {
      return _.filter(this.active, ['accepted', false]);
    },
    removed() {
      // eslint-disable-next-line
      return _.filter(this.creatives, (sweep) => ((sweep.deletedAt || sweep.declinedAt)));
    },
    hasEnoughTeam() {
      // eslint-disable-next-line
      return this.accepted.length >= this.maxCreatives;
    },
    inviteStatusTitle() {
      switch (this.invitationStatus) {
        case '0':
          return 'Decline';
        case '1':
          return 'Accept';
        default:
          return 'Remove';
      }
    },
    tierDetails() {
      return (creativePayRate) => {
        if (creativePayRate) {
          return `${_.capitalize(creativePayRate.tier)}`;
        }
        return null;
      };
    },
  },
  filters: {
    getDate(datetime) {
      const yesterday = moment().subtract(1, 'days');
      if (moment(datetime).isSame(yesterday, 'd')) {
        moment.locale('yourlang', {
          calendar: {
            lastDay() {
              return '[Yesterday]';
            },
          },
        });
        return moment(datetime).calendar();
      }
      return moment(datetime).short();
    },
  },
  data() {
    return {
      showDeclined: false,
      showRemoved: false,
      showInvitationPopup: false,
      invitationStatus: '0',
      invitationId: null,
      askReason: true,
      showConfirm: false,
      userId: '',
      showExpand: false,
      loading: false,
      creative: {},
    };
  },

  methods: {
    ...mapActions('sweep', ['removeSweepMember', 'acceptInvitation', 'assignCreatives']),
    ...mapActions('user', ['creativeStatusUpdate']),
    isInvited(creative) {
      if (creative.invitationSentAt) {
        return true;
      }
      return false;
    },
    availabilityCheck(id) {
      let check = '';
      _.map(this.creatives, (creative) => {
        if (creative.id === id) {
          check = creative.creatives.availability;
        }
      });
      return check;
    },
    getDate(datetime) {
      const yesterday = moment().subtract(1, 'days');
      if (moment(datetime).isSame(yesterday, 'd')) {
        moment.locale('yourlang', {
          calendar: {
            lastDay() {
              return '[Yesterday]';
            },
          },
        });
        return moment(datetime).calendar();
      }
      return moment(datetime).short();
    },
    acceptPopup(invitationId, userId, status) {
      this.userId = userId;
      this.invitationId = invitationId;
      this.invitationStatus = status;
      this.showConfirm = true;
    },
    async updateUserStatusApi() {
      const response = await this.acceptInvitation({
        sweepTeamId: this.invitationId,
        status: this.invitationStatus,
      });
      if (response.success) {
        this.showConfirm = false;
        EventBus.$emit('reload-current-sweep');
      }
    },
    async resendInvitation(creativeId, type) {
      const payload = {
        sweepId: this.sweepId,
        isResend: true,
        creatives: [{
          type, id: creativeId,
        }],
      };
      const result = await this.assignCreatives(payload);
      if (result.success) {
        this.$emit('reload-sweeps');
        EventBus.$emit('reload-current-sweep');
      }
    },

    updateInvitation(invitationId, status, creative, needReason = true) {
      this.creative = creative.creatives;
      this.invitationId = invitationId;
      this.invitationStatus = status;
      this.showInvitationPopup = true;
      this.askReason = needReason;
    },
    async submitForm(reasonId) {
      let result;
      this.loading = true;
      if (this.invitationStatus === '2') {
        const team = _.find(this.creatives, ['id', this.invitationId]);
        const creativeId = _.get(team, 'creatives.id', '');
        result = await this.removeSweepMember({
          sweepId: this.sweepId,
          creativeId,
          reasonId,
        });
      } else {
        result = await this.acceptInvitation({
          sweepTeamId: this.invitationId,
          status: this.invitationStatus,
          reasonId,
        });
      }
      if (result.success) {
        this.$emit('reload-sweeps');
        this.showInvitationPopup = false;
        EventBus.$emit('reload-current-sweep');
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
  .sweepTeam {
    background: #f9f9f9;
    margin-bottom: 2px;
  }
  .declined-link {
    color: #828282 !important;
    font-size: 14px;
  }
  .font-family-2 {
    font-family: $fontFamily1;
  }
  .line {
    width: 350px;
    border-bottom: 2px solid #EBEBEB;
  }
  .creative-heading {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #B7B7B7;
  }
  .team {
    padding: 0px 12px;
  }
  .add-padding {
    padding: 12px;
  }
  .expand {
    width: 90%;
    margin: 0px 12px;
    margin-top: 10px;
    background-color: #FFFFFF;
border: 1px solid #DEDEDE;
border-radius: 20px;
display: flex;
justify-content: center;
cursor: pointer;
  }
</style>
