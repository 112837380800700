<!-- eslint-disable max-len -->
<template>
  <svg :width="width" :height="height" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00012 0.0350037C6.79507 0.0374296 4.68101 0.9144 3.12173 2.47353C1.56245 4.03266 0.685264 6.14662 0.682617 8.35167C0.682617 10.4933 2.34095 13.845 5.61178 18.3133C6.00117 18.8468 6.511 19.2807 7.09979 19.5799C7.68857 19.8791 8.33968 20.035 9.00012 20.035C9.66055 20.035 10.3117 19.8791 10.9004 19.5799C11.4892 19.2807 11.9991 18.8468 12.3885 18.3133C15.6593 13.845 17.3176 10.4933 17.3176 8.35167C17.315 6.14662 16.4378 4.03266 14.8785 2.47353C13.3192 0.9144 11.2052 0.0374296 9.00012 0.0350037ZM9.00012 11.6667C8.34085 11.6667 7.69638 11.4712 7.14822 11.1049C6.60005 10.7386 6.17281 10.218 5.92052 9.60895C5.66823 8.99986 5.60222 8.32964 5.73083 7.68304C5.85945 7.03643 6.17692 6.44249 6.64309 5.97631C7.10927 5.51014 7.70321 5.19267 8.34982 5.06405C8.99642 4.93544 9.66664 5.00145 10.2757 5.25374C10.8848 5.50603 11.4054 5.93327 11.7717 6.48144C12.138 7.0296 12.3335 7.67407 12.3335 8.33334C12.3335 9.21739 11.9823 10.0652 11.3571 10.6904C10.732 11.3155 9.88417 11.6667 9.00012 11.6667Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'MapMarkerFilled',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '20',
    },
  },
};
</script>
